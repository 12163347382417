import { Heading, type SystemStyleObject } from '@chakra-ui/react';
import type { ReactNode } from 'react';

import { VStack } from 'components/atoms';

export const haveAssessLabelSx: SystemStyleObject = {
  paddingX: 2,
  paddingY: 0.5,
  rounded: 'sm',
  bgColor: 'gray.500',
  color: 'white',
  fontSize: 'xs',
};

export const headingSx: SystemStyleObject = {
  fontSize: 'md',
  fontWeight: 'normal',
};

export const gridItemSx: SystemStyleObject = {
  position: 'relative',
  paddingX: 6,
  paddingY: 4,
  bgColor: 'white',
  border: '1px',
  borderColor: 'primary',
};

export const closeButtonSx: SystemStyleObject = {
  marginLeft: 'auto',
};

export const buttonSx: SystemStyleObject = {
  maxWidth: 'fit-content',
  paddingX: 6,
};

export const contentBlockSx: SystemStyleObject = {
  '.chakra-text': {
    fontSize: 'xs',
  },
  '.chakra-stack': {
    gap: 0,
  },
};

export const ContentBlock = ({
  heading,
  children,
}: {
  heading: ReactNode;
  children: ReactNode;
}) => (
  <VStack gap={2} sx={contentBlockSx}>
    <Heading as="h5" fontSize="xs">
      {heading}
    </Heading>

    {children}
  </VStack>
);
