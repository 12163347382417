import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const roundedSquare = definePartsStyle({
  badge: {
    bg: 'gray.500',
    border: '2px solid',
  },
  container: {
    color: 'white',
    borderRadius: 'xl',
    border: '2px solid',

    _dark: {
      bg: 'gray.400',
      color: 'gray.900',
    },

    img: {
      borderRadius: 'xl',
    },
  },
  excessLabel: {
    bg: 'gray.800',
    color: 'white',
    borderRadius: 'l',
    border: '2px solid',

    _dark: {
      bg: 'gray.400',
      color: 'gray.900',
    },
  },
});

export const avatarTheme = defineMultiStyleConfig({
  variants: { roundedSquare },
});
