import { type List } from 'immutable';

import { VStack } from 'components';
import { type ApplicableRuleEntityRecord } from 'entities/api/ApplicableRule';

import { GlobalRule } from '../Rule/Rule';

type GlobalControlRulesProps = {
  value: List<ApplicableRuleEntityRecord>;
};

export function GlobalControlRules(props: GlobalControlRulesProps) {
  const { value } = props;
  return (
    <VStack>
      {value?.map((val, idx) => (
        <GlobalRule key={val.get('uuid')} rule={val} headingSuffix={idx + 1} />
      ))}
    </VStack>
  );
}
