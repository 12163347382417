import { type SystemStyleObject } from '@chakra-ui/react';

export const wrapperSx: SystemStyleObject = {};

export const buttonWrapperSx: SystemStyleObject = {
  paddingRight: '12',
};

export const buttonSx: SystemStyleObject = {
  minWidth: '26px',
  height: '26px',
  margin: '0',
  padding: '4px',
  color: 'link.500',

  border: '1px',
  borderColor: 'border',

  // avoid doubling neighbouring borders
  borderRight: 'none',

  borderRadius: '0',

  '&:first-of-type': {
    borderTopLeftRadius: 'base',
    borderBottomLeftRadius: 'base',
  },

  '&:last-of-type': {
    borderTopRightRadius: 'base',
    borderBottomRightRadius: 'base',

    borderRight: '1px',
    borderColor: 'border', // ?? why is this required?! without, shows blue :(
  },

  fontSize: 'sm',
  fontWeight: 'normal',
  transition: 'all 0.15s ease-out',

  _active: {
    color: 'bodyText',
  },

  _disabled: {
    cursor: 'not-allowed',
    color: 'border',
  },

  svg: {
    fontSize: 'sm',
  },
};

export const selectWrapperSx: SystemStyleObject = {
  width: 'auto',
};

export const selectSx: SystemStyleObject = {};
