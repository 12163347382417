import { GridItem, Heading } from '@chakra-ui/react';
import { List } from 'immutable';
import { FormattedMessage, useIntl } from 'react-intl';

import { Loading } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { FlatListItems } from 'components/FlatListItems/FlatListItems';
import { type RulePredicateEntityRecord } from 'entities/api/RulePredicate';
import type { SiteApplicableRuleEntityRecord } from 'entities/api/SiteApplicableRule';
import { Fieldset, FieldsetItem } from 'forms/fields';
import { useLocale } from 'locales/useLocale';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

import type { ControlDetailContextType } from '../ControlFrameworkControlDetail';

const log = createLogger('ControlRules');

type ControlDetailsProps =
  WithQueryReturnProps<SiteApplicableRuleEntityRecord> &
    ControlDetailContextType;

export const ControlRules = (props: ControlDetailsProps) => {
  const { value: rule } = props;
  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  const emptyFieldset = formatMessage({
    id: 'control-framework.control.tab.rules.fieldset.empty',
    defaultMessage: 'None',
  });

  log('%o', { rule, props });

  const predicateByModel = rule
    ?.get('predicates')
    ?.groupBy((x) => x.get('model_name'));

  if (!predicateByModel) return <Loading />;

  return (
    <VStack>
      <Heading>
        <FormattedMessage
          id="control-framework.control.tab.rules.title"
          defaultMessage="Rules"
        />
      </Heading>

      <Fieldset
        heading={
          <FormattedMessage
            id="control-framework.control.tab.rules.fieldset.operating-contexts"
            defaultMessage="Operating contexts"
          />
        }
      >
        <GridItem colSpan={{ base: 12 }}>
          <FlatListItems<RulePredicateEntityRecord>
            records={predicateByModel?.get('operatingcontext') || List([])}
            emptyText={emptyFieldset}
            locale={locale}
          />
        </GridItem>
      </Fieldset>

      <Fieldset
        heading={
          <FormattedMessage
            id="control-framework.control.tab.rules.equipment"
            defaultMessage="Equipment"
          />
        }
      >
        <FieldsetItem>
          <FlatListItems<RulePredicateEntityRecord>
            records={predicateByModel?.get('equipment') || List([])}
            emptyText={emptyFieldset}
            locale={locale}
          />
        </FieldsetItem>
      </Fieldset>

      <Fieldset
        heading={
          <FormattedMessage
            id="control-framework.control.tab.rules.scenarios"
            defaultMessage="Scenarios"
          />
        }
      >
        <FieldsetItem colSpan={{ base: 12 }}>
          <FlatListItems<RulePredicateEntityRecord>
            records={predicateByModel?.get('scenario') || List([])}
            emptyText={emptyFieldset}
            locale={locale}
          />
        </FieldsetItem>
      </Fieldset>

      <Fieldset
        heading={
          <FormattedMessage
            id="control-framework.control.tab.rules.work-location"
            defaultMessage="Work locations"
          />
        }
      >
        <FieldsetItem colSpan={{ base: 12 }}>
          <FlatListItems<RulePredicateEntityRecord>
            records={predicateByModel?.get('worklocation') || List([])}
            emptyText={emptyFieldset}
            locale={locale}
          />
        </FieldsetItem>
      </Fieldset>

      <Fieldset
        heading={
          <FormattedMessage
            id="control-framework.control.tab.rules.scenario-modifiers"
            defaultMessage="Scenarios modifiers"
          />
        }
      >
        <FieldsetItem colSpan={{ base: 12 }}>
          <FlatListItems<RulePredicateEntityRecord>
            records={predicateByModel?.get('scenariomodifier') || List([])}
            emptyText={emptyFieldset}
            locale={locale}
          />
        </FieldsetItem>
      </Fieldset>
    </VStack>
  );
};
