import production from './production';
import qa from './qa';
import scratch from './scratch.ts';
import test_live from './test_live.ts';

export default {
  production,
  qa,
  test_live,
  scratch,
};
