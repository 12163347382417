import { Grid } from '@chakra-ui/react';
import { type List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { PerformanceCard } from 'components';
import { performanceCardGridSx } from 'components/PerformanceCard/PerformanceCard.style';
import { type SiteControlReportEntityRecord } from 'entities/reporting/SiteControlReport';
import { type SiteFrameworkReportEntityRecord } from 'entities/reporting/SiteFrameworkReport';

type StatsProps = {
  stats: List<SiteFrameworkReportEntityRecord | SiteControlReportEntityRecord>;
};

export const Stats = ({ stats }: StatsProps) => {
  const report = stats?.get(0);
  return (
    <Grid sx={performanceCardGridSx}>
      <PerformanceCard
        heading={
          <FormattedMessage
            id="dashboard.stats.ccc"
            defaultMessage="Critical control check"
          />
        }
        stats={[
          {
            metric: report?.get('ccc_total') ?? 0,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.ccc"
                defaultMessage="Critical control check"
              />
            ),
            change:
              (report?.get('ccc_total') ?? 0) -
              (report?.get('ccc_total_prev') ?? 0),
          },
        ]}
      />
      <PerformanceCard
        heading={
          <FormattedMessage
            id="dashboard.stats.fcc"
            defaultMessage="Field control confirmation"
          />
        }
        stats={[
          {
            metric: report?.get('cc_total') ?? 0,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.fcc"
                defaultMessage="Field control confirmation"
              />
            ),
            change:
              (report?.get('cc_total') ?? 0) -
              (report?.get('cc_total_prev') ?? 0),
          },
        ]}
      />
      <PerformanceCard
        heading={
          <FormattedMessage
            id="dashboard.stats.interruptions"
            defaultMessage="Interruptions"
          />
        }
        stats={[
          {
            metric: `${report?.get('ccc_interrupt_total') ?? 0} / ${report?.get('ccc_fix_total') ?? 0}`,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.interrupts-fix"
                defaultMessage="Interrupts/Fix"
              />
            ),
          },
          {
            metric: report?.get('cc_with_actions_total') ?? 0,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.action"
                defaultMessage="Actions"
              />
            ),
          },
        ]}
      />
      <PerformanceCard
        heading={
          <FormattedMessage
            id="dashboard.stats.implementation"
            defaultMessage="Implementation"
          />
        }
        stats={[
          {
            metric: `${report?.get('site_framework_active_total') ?? 0} / ${report?.get('site_framework_total') ?? 0}`,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.site-frameworks"
                defaultMessage="Site Frameworks"
              />
            ),
          },
          {
            metric: `${report?.get('control_implementations_active_total') ?? 0} / ${report?.get('control_implementations_total') ?? 0}`,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.controls"
                defaultMessage="Controls"
              />
            ),
          },
        ]}
      />
    </Grid>
  );
};
