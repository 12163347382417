import { useEffect, useState } from 'react';

import { type EntityDuckAction, type FormChangeEvent } from 'types';

type CheckBoxParams = {
  name: string;
  value: boolean | null | undefined;
  reverse: boolean;
  onChange: (event: FormChangeEvent) => EntityDuckAction;
};
export default function useThreeStateCheckboxControls(props: CheckBoxParams) {
  const { onChange, name, value, reverse } = props;
  const [lhs, setLhs] = useState([null, undefined, true].includes(value));
  const [rhs, setRhs] = useState([null, undefined, false].includes(value));
  useEffect(() => {
    onChange({
      target: {
        name: name,
        value: lhs && rhs ? null : reverse ? rhs : lhs,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lhs, rhs, reverse, name]); // Can't include onChange as its reference keeps changing
  return {
    lhs,
    setLhs,
    rhs,
    setRhs,
  };
}
