import { GridItem } from '@chakra-ui/react';

import { emptyGridCellSx } from './ControlGridCell.styles';

type ControlGridCellProps = {
  colStart: number;
  colEnd: number;
};

export function EmptyControlGridCell({
  colStart,
  colEnd,
}: ControlGridCellProps) {
  return (
    <GridItem
      className="EmptyControlGridCell"
      role="presentation"
      colStart={colStart}
      colEnd={colEnd}
      sx={emptyGridCellSx}
    />
  );
}
