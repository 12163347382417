import { type SystemStyleObject } from '@chakra-ui/react';

export const damageEnergyHeader: SystemStyleObject = {
  paddingX: 5,
  paddingY: 2.5,
  borderRadius: '4px',
  backgroundColor: 'gray.200',
  color: 'black',
  fontSize: 'sm',

  _expanded: {
    // hack: empty obj prevents paddingBottom being removed
  },

  _hover: {
    backgroundColor: 'gray.300',
  },
};

export const accordionItemSx: SystemStyleObject = {
  border: 0,

  '.chakra-accordion__panel': {
    paddingTop: 0.5,
    paddingX: 0,
    paddingBottom: 0,
  },
};

export const accordionPanelListSx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: 0.5,
};
