import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import { type DamageEnergyMechanismEntityRecord } from 'entities/api/DamageEnergyMechanism';
import { useLocale } from 'locales/useLocale';

import {
  accordionItemSx,
  accordionPanelListSx,
  damageEnergyHeader,
} from './DamageEnergyCard.styles';
import { ScenarioCard } from '../ScenarioCard';
import { listItemSx } from '../ScenarioCard/ScenarioCard.styles';

type DamageEnergyCardProps = {
  record: DamageEnergyMechanismEntityRecord;
};

export const DamageEnergyCard = (props: DamageEnergyCardProps) => {
  const { record } = props;
  const { toString } = useLocale();

  const scenarios = record?.get('scenarios');

  return (
    <AccordionItem as={ListItem} sx={accordionItemSx}>
      <Heading as="h3">
        <AccordionButton sx={damageEnergyHeader}>
          {toString(record)}
          <AccordionIcon />
        </AccordionButton>
      </Heading>

      <AccordionPanel
        as={UnorderedList}
        layerStyle="nakedList"
        sx={accordionPanelListSx}
      >
        {scenarios && scenarios.size > 0 ? (
          scenarios.map((scenario) => (
            <ScenarioCard key={scenario.get('uuid')} record={scenario} />
          ))
        ) : (
          <ListItem sx={listItemSx}>
            <Text color="gray">(empty)</Text>
          </ListItem>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};
