import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import workerUrl from 'assets/sentry-compression-worker.min.js?url';
import { type Settings } from 'settings';
import { initMatomoTracking } from 'util/initMatomoTracking';

export default function (settings: Settings) {
  if (import.meta.env.LIVE_ENVIRONMENTS.includes(import.meta.env.MODE)) {
    Sentry.init({
      dsn: settings.SENTRY_DSN,
      environment: import.meta.env.MODE,
      /**
       * > Set `tracePropagationTargets` to control for which URLs distributed
       * > tracing should be enabled
       *
       * Note, requires that the broker has CORS_ALLOW_HEADERS configured:
       *
       * ```py
       * # settings.py
       * CORS_ALLOW_HEADERS = ["sentry-trace", "baggage"]
       * ```
       *
       * https://docs.sentry.io/platforms/javascript/distributed-tracing/#how-to-use-distributed-tracing
       *
       * // TODO configure broker CORS headers https://burnsred.atlassian.net/browse/BC-61
       * Until then, disable distributed tracing to prevent:
       *
       * > Request header field baggage is not allowed by Access-Control-Allow-Headers in preflight response.
       *
       * > If you don't want to attach headers anywhere (i.e. turn off
       * > distributed tracing), you can just specify an empty array:
       * > tracePropagationTargets: [].
       * > https://github.com/getsentry/sentry-javascript/issues/6077#issuecomment-1322030967
       */
      tracePropagationTargets: [],
      integrations: [
        /**
         * > captures [selected] Console API calls and redirects them to Sentry using
         * > the SDK's captureMessage or captureException call, depending on the
         * > log level. It then re-triggers to preserve default native behavior
         * > https://docs.sentry.io/platforms/javascript/configuration/integrations/captureconsole/
         */
        captureConsoleIntegration({ levels: ['error', 'assert'] }),
        /**
         * > With performance monitoring, Sentry tracks your software
         * > performance, measuring metrics like throughput and latency, and
         * > displaying the impact of errors across multiple systems. Sentry
         * > captures distributed traces consisting of transactions and spans,
         * > which measure individual services and individual operations within
         * > those services.
         *
         * https://docs.sentry.io/platforms/javascript/performance/#enable-tracing
         */
        Sentry.browserTracingIntegration(),
        /**
         * > The React Router instrumentation uses the React Router library to
         * > create pageload/navigation transactions to ensure you collect
         * > meaningful performance data about the health of your page loads and
         * > associated requests.
         *
         * https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v6
         */
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        /**
         * > Session Replay helps you get to the root cause of an error or
         * > latency issue faster by providing you with a video-like
         * > reproduction of what was happening in the user's browser before,
         * > during, and after the issue.
         *
         * https://docs.sentry.io/platforms/javascript/session-replay/
         */
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: true,
          blockAllMedia: true,
          // add these attributes to elements you want to _unmask_ / _unblock_
          // https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
          unblock: ['.sentry-unblock, [data-sentry-unblock]'],
          unmask: ['.sentry-unmask, [data-sentry-unmask]'],
          workerUrl: workerUrl,
        }),
      ],
      // Performance Monitoring
      // Capture fewer transactions in prod, 100% in other envs
      tracesSampleRate: import.meta.env.MODE == 'production' ? 0.001 : 1.0,

      // Capture fewer replays in prod, 10% in other envs
      replaysSessionSampleRate:
        import.meta.env.MODE == 'production' ? 0.001 : 0.01,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });

    initMatomoTracking();
  }
}
