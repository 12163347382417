import { Flex, FormControl, FormLabel, GridItem } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router';

import { Form, Input } from '@burnsred/entity-form';
import { VStack } from 'components';
import {
  Autocomplete,
  EquipmentPicker,
  WidgetCheckbox,
} from 'components/widgets';
import { Fieldset, FieldsetItem } from 'forms/fields';
import { useLocale } from 'locales/useLocale';
import type { BasicEntityRecord } from 'types';
import { createLogger } from 'util/createLogger';

import type { GlobalFrameworkContext } from '../GlobalFramework/GlobalFramework';

const log = createLogger('GlobalFrameworkDetailsTab');

export function GlobalFrameworkDetailsTab() {
  const { toString } = useLocale();
  const { formControls } = useOutletContext<GlobalFrameworkContext>();
  const { name, value, valueInitial, errors, onChange, field, onSubmit } =
    formControls;

  log('%o', formControls);

  /** workaround: cache initial value, as we don't have an endpoint for options */
  const workLocationOptions = useMemo(() => {
    return value.get('work_locations');
    // don't update when value changes!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      name={name}
      value={value}
      valueInitial={valueInitial}
      errors={errors}
      field={field}
      onChange={onChange}
      onSubmit={onSubmit}
      disabled
    >
      <Flex layerStyle="cardLike">
        <VStack>
          <Fieldset>
            <GridItem colSpan={{ base: 8 }}>
              <FormControl>
                <FormLabel>
                  <FormattedMessage
                    id="global-framework.details.risk"
                    defaultMessage="Level 3 Risk"
                  />
                  <Input name="risk" component={Autocomplete} />
                </FormLabel>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 4 }}>
              <FormControl>
                <FormLabel>
                  <FormattedMessage
                    id="global-framework.details.owner"
                    defaultMessage="Owner"
                  />
                  <Input name="owner" />
                </FormLabel>
              </FormControl>
            </GridItem>
          </Fieldset>

          <Fieldset
            heading={
              <FormattedMessage
                id="global-framework.details.operating_contexts"
                defaultMessage="Operating context"
              />
            }
          >
            <GridItem colSpan={{ base: 12 }}>
              <Input
                name="operating_contexts"
                component={WidgetCheckbox}
                loadOptionsFromAPI
              />
            </GridItem>
          </Fieldset>

          <Fieldset
            heading={
              <FormattedMessage
                id="global-framework.details.work_locations"
                defaultMessage="Work locations"
              />
            }
          >
            <FieldsetItem>
              {workLocationOptions?.size ? (
                <Input
                  name="work_locations"
                  component={WidgetCheckbox}
                  componentProps={{
                    // TODO add an endpoint for work_locations;
                    // at present we're just showing the initial value
                    options: workLocationOptions,
                    getOptionLabel: (option: BasicEntityRecord) =>
                      toString(option),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="global-framework.details.work-locations.empty"
                  defaultMessage="No work locations"
                />
              )}
            </FieldsetItem>
          </Fieldset>

          <Fieldset
            heading={
              <FormattedMessage
                id="global-framework.details.equipment"
                defaultMessage="Equipment"
              />
            }
          >
            <GridItem colSpan={{ base: 12 }}>
              <Input name="equipment" component={EquipmentPicker} />
            </GridItem>
          </Fieldset>
        </VStack>
      </Flex>
    </Form>
  );
}
