import {
  Box,
  Container,
  Flex,
  HStack,
  Heading,
  Spacer,
  Text,
  type UseDisclosureReturn,
} from '@chakra-ui/react';
import { is } from 'immutable';
import { useSelector } from 'react-redux';

import { Link } from '@burnsred/ui-chakra';
import { BhpLogo, CubeLogo } from 'components';
import PersonEntity, {
  type PersonEntityRecord,
} from 'entities/api/Person/Person';

import {
  iconSx,
  logoWrapperSx,
  navInnerContainer,
  navWrapperSx,
  pageTitleSx,
  usernameSx,
} from './DesktopHeaderNav.styles';
import { LanguageToggle } from './LanguageToggle';

import { MobileNavDrawer } from '.';

export type DesktopHeaderNavProps = {
  isMobile: boolean;
  mobileDisclosure: UseDisclosureReturn;
};

export function DesktopHeaderNav({
  isMobile,
  mobileDisclosure,
}: DesktopHeaderNavProps) {
  const person = useSelector(
    (state) => PersonEntity.duck.selectors.currentUser(state),
    is,
  );
  const name = PersonEntity.toString(person as PersonEntityRecord);
  const pageTitle = 'CUBE';

  return (
    <Box as="nav" sx={navWrapperSx}>
      <Container variant="desktopLayout">
        <Flex sx={navInnerContainer} gap={7}>
          <Link to="/" sx={logoWrapperSx}>
            <BhpLogo />
          </Link>
          <Flex direction="row">
            <CubeLogo sx={iconSx} />
            <Heading as="h1" sx={pageTitleSx}>
              {pageTitle}
            </Heading>
          </Flex>
          <Spacer />

          <LanguageToggle />

          <HStack>
            <Text sx={usernameSx}>
              <span>{name}</span>
            </Text>
          </HStack>

          {isMobile && <MobileNavDrawer disclosure={mobileDisclosure} />}
        </Flex>
      </Container>
    </Box>
  );
}
