import { FormattedMessage } from 'react-intl';

import { ErrorLayout } from 'components/layout';

export function NotFound() {
  return (
    <ErrorLayout
      heading={
        <FormattedMessage id="NotFound.heading" defaultMessage="Not Found" />
      }
      body={
        <FormattedMessage
          id="NotFound.no-such-route"
          defaultMessage="The page you are looking for does not exist."
        />
      }
    />
  );
}
