import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class ControlDomainEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/control_domain/',
  };

  static fields: EntityFields<TitleEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
  };

  static toString(record: ControlDomainEntityRecord, locale = '') {
    return I18nTextEntity.toString(record?.get('title'), locale);
  }
}

export type ControlDomainEntityRecord = EntityRecord<TitleEntityFields>;

ControlDomainEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ControlDomainEntity,
  name: 'ControlDomain',
});

export default ControlDomainEntity;
