import { Box, Flex, VisuallyHidden } from '@chakra-ui/react';
import { type List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Tooltip, TooltipTitleDescriptionLayout } from 'components';
import type { TimeZoneEntityRecord } from 'entities/api/TimeZone';
import TimeZoneEntity from 'entities/api/TimeZone';
import { useLocale } from 'locales/useLocale';

import { timezoneWrapperSx, tooltipSx, zoneSx } from './TimeZoneGraph.styles';

export type TimeZoneProps = {
  timezones: List<TimeZoneEntityRecord>;
  /** render a thinner graph (half height) */
  thin?: boolean;
};

/**
 * Control Timezones Graph
 *
 * ```tsx
 * <TimeZoneGraph timezones={record.get('timezones')} />
 *
 * // render a thinner graph (half height)
 * <TimeZoneGraph zones={record.get('timezones')} thin />
 *
 * // provide dummy data
 * <TimeZoneGraph zones={createSegmentsFixture()} />
 * ```
 */
export const TimeZoneGraph = ({
  timezones: activeTimezones,
  thin,
}: TimeZoneProps) => {
  const { toString } = useLocale();

  const timezones = useSelector<unknown, List<TimeZoneEntityRecord>>((state) =>
    TimeZoneEntity.duck.selectors.record(state),
  );

  /** Display 6 blank regions until timezones resolves */
  const loadingFallback = [...Array(6)];

  return (
    <Flex as="ul" layerStyle="nakedList" sx={timezoneWrapperSx}>
      {(timezones ?? loadingFallback).map((timezone, i) => {
        const isActive = activeTimezones.includes(timezone);
        const timeZoneDescription = toString(timezone, 'description');
        const timeZoneLabel = toString(timezone);

        return (
          <Tooltip
            key={i}
            sx={tooltipSx}
            label={
              <TooltipTitleDescriptionLayout
                title={
                  timeZoneLabel || (
                    <FormattedMessage
                      id="TimeZoneGraph.tooltip.header"
                      defaultMessage="Timezone"
                    />
                  )
                }
                description={
                  timeZoneDescription || (
                    <FormattedMessage
                      id="TimeZoneGraph.tooltip.description"
                      defaultMessage="Timezone description"
                    />
                  )
                }
              />
            }
          >
            <Box
              as="li"
              sx={{
                height: thin ? '5px' : '10px',
                backgroundColor: isActive ? timezone.get('color') : 'white',
                ...zoneSx,
              }}
            >
              {/* Hide visually, leave accessible for screen readers */}
              <VisuallyHidden>{timeZoneLabel}</VisuallyHidden>
            </Box>
          </Tooltip>
        );
      })}
    </Flex>
  );
};
