import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class DamageEnergyEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/damage_energy/',
  };

  static fields: EntityFields<TitleEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
  };

  static toString(record: DamageEnergyEntityRecord, locale = ''): string {
    return I18nTextEntity.toString(record.get('title'), locale);
  }
}

export type DamageEnergyEntityRecord = EntityRecord<TitleEntityFields>;

DamageEnergyEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: DamageEnergyEntity,
  name: 'DamageEnergy',
});

export default DamageEnergyEntity;
