import { Map } from 'immutable';
import type { Moment } from 'moment';

import { Entity, Fields } from '@burnsred/entity';
import ScreenDuck from '@burnsred/entity-duck-namespace-screen';
import ControlEntity, { type ControlEntityRecord } from 'entities/api/Control';
import DamageEnergyMechanismEntity, {
  type DamageEnergyMechanismEntityRecord,
} from 'entities/api/DamageEnergyMechanism';
import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from 'entities/api/Person/GrcOrganisationalUnit';
import RiskEntity, { type RiskEntityRecord } from 'entities/api/Risk';
import ScenarioEntity, {
  type ScenarioEntityRecord,
} from 'entities/api/Scenario';
import TimeZoneEntity, {
  type TimeZoneEntityRecord,
} from 'entities/api/TimeZone';
import {
  type EntityFields,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

import { LAST_SEVEN_DAYS } from './periodOptions';

class GlobalDashboardFilterEntity extends Entity {
  static fields: EntityFields<GlobalDashboardFilterEntityFields> = {
    risk: new Fields.EntityField({
      entity: RiskEntity,
      blank: true,
    }),
    descendants: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),
    grc_organisational_unit: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),
    scenarios__damage_energy_mechanism: new Fields.EntityField({
      entity: DamageEnergyMechanismEntity,
      blank: true,
    }),
    timezone: new Fields.EntityField({
      entity: TimeZoneEntity,
      blank: true,
    }),
    scenarios: new Fields.EntityField({
      entity: ScenarioEntity,
      blank: true,
    }),
    controls__siteframeworkcontrol__control: new Fields.EntityField({
      entity: ControlEntity,
      blank: true,
    }),
    with_statistics__gte: new Fields.DateTimeField({
      blank: true,
      default: LAST_SEVEN_DAYS.value,
    }),
    page_size: new Fields.CharField({
      default: '6',
      blank: true,
    }),
    page: new Fields.CharField({
      default: '1',
      blank: true,
    }),
  };

  static asMappedParams(record: GlobalDashboardFilterEntityRecord) {
    return Map({
      risk: record?.get('risk')?.get('uuid') || '',
      descendants: record?.get('descendants')?.get('uuid') || '',
      grc_organisational_unit:
        record?.get('grc_organisational_unit')?.get('uuid') || '',
      with_statistics__gte:
        record?.get('with_statistics__gte')?.toISOString() || '',
      scenarios__damage_energy_mechanism:
        record?.get('scenarios__damage_energy_mechanism')?.get('uuid') || '',
      controls__timezones: record?.get('timezone')?.get('uuid') || '',
      scenarios: record?.get('scenarios')?.get('uuid') || '',
      controls__siteframeworkcontrol__control:
        record?.get('controls__siteframeworkcontrol__control')?.get('uuid') ||
        '',
      page: record?.get('page') ?? '1',
      page_size: record?.get('page_size') ?? '6',
    });
  }

  static asMappedForReportsParams(record: GlobalDashboardFilterEntityRecord) {
    return Map({
      risk: record?.get('risk')?.get('uuid') || '',
      descendants: record?.get('descendants')?.get('uuid') || '',
      grc_organisational_unit:
        record?.get('grc_organisational_unit')?.get('uuid') || '',
      period_start__gte:
        record?.get('with_statistics__gte')?.toISOString() || '',
      scenarios__damage_energy_mechanism:
        record?.get('scenarios__damage_energy_mechanism')?.get('uuid') || '',
      controls__timezones: record?.get('timezone')?.get('uuid') || '',
      scenarios: record?.get('scenarios')?.get('uuid') || '',
      controls__siteframeworkcontrol__control:
        record?.get('controls__siteframeworkcontrol__control')?.get('uuid') ||
        '',
      page_size: record?.get('page_size'),
    });
  }

  static toParams(record: GlobalDashboardFilterEntityRecord) {
    const params = new URLSearchParams(this.asMappedParams(record).toJS());
    return params.toString();
  }

  static onChangeClean(
    record: GlobalDashboardFilterEntityRecord,
    evt: FormChangeEvent,
    rootName: string,
  ) {
    console.log(record, evt);
    const { name, value } = evt.target;

    if (name === rootName) return value as GlobalDashboardFilterEntityRecord;
    // when a field changes, reset to page 1 to avoid requesting out of range results from API
    const updatedRecord = name != 'page' ? record.set('page', '1') : record;

    // Warning does not work with nested updates
    switch (name) {
      case 'descendants':
        return updatedRecord.withMutations((rec) => {
          rec.set('grc_organisational_unit', null);
          rec.set(name, value);
        });
      case 'scenarios__damage_energy_mechanism':
        return updatedRecord.withMutations((rec) => {
          rec.set('scenarios', null);
          rec.set(name, value);
        });
      case 'risk':
        return updatedRecord.withMutations((rec) => {
          rec.set('scenarios__damage_energy_mechanism', null);
          rec.set('scenarios', null);
          rec.set(name, value);
        });
      default:
        return updatedRecord.set(name, value);
    }
  }
}

type GlobalDashboardFilterEntityFields = {
  risk: RiskEntityRecord;
  descendants: GrcOrganisationalUnitEntityRecord;
  grc_organisational_unit: GrcOrganisationalUnitEntityRecord;
  scenarios__damage_energy_mechanism: DamageEnergyMechanismEntityRecord;
  scenarios: ScenarioEntityRecord;
  controls__siteframeworkcontrol__control: ControlEntityRecord;
  timezone: TimeZoneEntityRecord;
  page_size: string;
  page: string;
  with_statistics__gte: Moment;
};

export type GlobalDashboardFilterEntityRecord =
  EntityRecord<GlobalDashboardFilterEntityFields>;

GlobalDashboardFilterEntity.duck = new ScreenDuck({
  app: 'Cube',
  entity: GlobalDashboardFilterEntity,
  name: 'GlobalDashboardFilter',
});

export default GlobalDashboardFilterEntity;
