import { ValueToggle } from 'components';
import { type LocaleKey, localeOptions } from 'locales';
import { useLocale } from 'locales/useLocale';

export function LanguageToggle() {
  const { locale, setLocale } = useLocale();

  const handleChangeLocale = (locale: string | number) =>
    setLocale(locale as LocaleKey);

  return (
    <ValueToggle
      name="locale"
      options={localeOptions}
      value={locale}
      onChange={handleChangeLocale}
      size="sm"
    />
  );
}
