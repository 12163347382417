import type { SystemStyleObject } from '@chakra-ui/react';

export const performanceCardGridSx: SystemStyleObject = {
  // '--columns': { base: '1', sm: '2', lg: '4' },
  // gridTemplateColumns: 'repeat(var(--columns), 1fr)',
  // REVIEW preserves card min-width, but wraps to 3 per row at mid breakpoints
  gridTemplateColumns: 'repeat(auto-fill, minmax(285px, 1fr))',
  gap: 6,
};

export const cardSx: SystemStyleObject = {
  '&.chakra-card': {
    minHeight: 28,
    boxShadow: 'lg',
  },

  '.chakra-card__header': {
    display: 'flex',
    paddingTop: 3,
    paddingX: 4,
    paddingBottom: 0,
  },

  '.chakra-card__body': {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    paddingY: 3,
    paddingX: 4,
  },
};

export const headingSx: SystemStyleObject = {
  zIndex: 1, // render above trendIcon
  marginRight: 'auto',
  paddingX: 0,
  // FIXME BC-64 typography styles
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'gray.500',
};

export const statRowFlexSx: SystemStyleObject = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
};

export const statRowMetricSx: SystemStyleObject = {
  fontSize: '3xl',
  lineHeight: 'none',
};

export const statRowLabelSx: SystemStyleObject = {
  fontSize: 'xs',
  color: 'gray.500',
};

export const trendStatSx: SystemStyleObject = {
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 4,
  height: 'full',
};

export const trendStatMetricSx: SystemStyleObject = {
  fontSize: '4xl',
  lineHeight: 1,
};

export const trendStatChangeSx: SystemStyleObject = {
  fontSize: 'xl',
  color: 'muted',
};

export const trendStatIconSx: SystemStyleObject = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 'base',

  boxSize: 28,
  color: 'gray.200',
};
