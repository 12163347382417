import type { SystemStyleObject } from '@chakra-ui/react';

/**
 * Styles refine the theme/components/Accordion, adjusting for nested checklists
 */
export const checklistAccordionSx: SystemStyleObject = {
  '.chakra-accordion__button': {
    '.chakra-checkbox__control': {
      // workaround: parent checkbox loses alignment in this context
      justifyContent: 'flex-start',
    },

    '.chakra-heading': {
      fontSize: 'sm',
      color: 'bodyText',
    },
  },

  '.chakra-accordion__panel': {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 8,
  },
};
