import { type Map } from 'immutable';
import { type ChangeEvent, useCallback } from 'react';

import { type FormChangeEvent } from 'types';

export function useWidgetOnChange<
  T extends HTMLInputElement | HTMLSelectElement,
>(
  name: string,
  onChange: (event: FormChangeEvent) => void,
  index?: number,
  transform?: (value: unknown) => Map<string, string | unknown> | undefined,
) {
  return useCallback(
    (evt: ChangeEvent<T>) => {
      if (evt?.target?.value) {
        onChange({
          target: {
            index,
            name,
            value: transform?.(evt.target.value) ?? evt.target.value,
          },
        });
      }
    },
    [onChange, name, index, transform],
  );
}

export function useWidgetOnChangeComponent<T>(
  name: string,
  onChange: (event: FormChangeEvent) => void,
  index?: number,
  transform?: (value: T) => Map<string, string | unknown> | undefined,
) {
  return useCallback(
    (obj: T) => {
      onChange({
        target: {
          index,
          name,
          value: transform?.(obj) ?? obj,
        },
      });
    },
    [onChange, name, index, transform],
  );
}
