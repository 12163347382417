import { type List, fromJS } from 'immutable';
import { useMemo } from 'react';

import type { InputProps } from '@burnsred/entity-form';
import {
  ChecklistAccordion,
  type ChecklistAccordionItemProps,
  type ChecklistAccordionOptionsGroup,
  type ChecklistAccordionProps,
} from 'components/ChecklistAccordion';
import { useLocale } from 'locales/useLocale';
import type { BasicEntityRecord } from 'types';
import { asEvent } from 'util/asEvent';
import { createLogger } from 'util/createLogger';

const log = createLogger('widget:WidgetChecklistAccordion');

export type WidgetChecklistAccordionProps<
  U extends BasicEntityRecord = BasicEntityRecord,
> = InputProps<U, true> & {
  /** TODO filter options that are offered for user selection */
  // filterOptions: (options: U[]) => U;

  /** Especially useful in conjunction with `loadOptionsFromAPI` */
  transformOptions: (rawOptions: List<U>) => ChecklistAccordionProps['options'];

  checklistAccordionProps?: ChecklistAccordionProps;
  checklistAccordionItemProps?: ChecklistAccordionItemProps;
};

/**
 * Renders: CheckboxGroup > Stack > Checkbox(es)
 *
 * Pass StackProps via `componentProps`:
 *
 * ```tsx
 * <Input
 *   name="scenarios"
 *   component={WidgetChecklistAccordion}
 *   componentProps={{
 *     transformOptions: (options: List<ScenarioEntityRecord>) =>
 *       options
 *         ?.groupBy((rec) => rec.get('damage_energy_mechanism'))
 *         ?.map((list, keyObj) => ({
 *           id: keyObj.get('uuid'),
 *           heading: toString(keyObj),
 *           items: list.map((el) => ({
 *             label: toString(el),
 *             value: el,
 *           })),
 *         }))
 *         .toIndexedSeq().toList(), // drop the key, keep only the list
 *   }}
 *   loadOptionsFromAPI
 * />
 * ```
 *
 * @default direction "row"
 * @default gap "6"
 */
export function WidgetChecklistAccordion<
  T extends BasicEntityRecord = BasicEntityRecord,
>(props: WidgetChecklistAccordionProps<T>) {
  const { toString } = useLocale();

  const {
    name,
    onChange,
    value,
    options,
    transformOptions,
    checklistAccordionProps,
    checklistAccordionItemProps,
  } = props;

  // reformat raw options to match ChecklistAccordion options
  const formattedOptions = useMemo<ChecklistAccordionOptionsGroup[]>(() => {
    // TODO filterOptions
    // const filterOptions = props.filterOptions ?? identity;

    log('formattedOptions %o', { options });
    if (transformOptions) return transformOptions(options ?? fromJS([]));

    return [
      {
        id: name,
        heading: name,
        items:
          options
            ?.map((rec: T) => ({
              value: rec.get('uuid'),
              label: toString(rec),
            }))
            ?.toJS() ?? [],
      } satisfies ChecklistAccordionOptionsGroup,
    ];
  }, [name, options, toString, transformOptions]);

  const uuidValues = useMemo(() => {
    return value.map((rec) => rec?.get('uuid')).toJS();
  }, [value]);

  const handleChange = (newValue: string[]) => {
    const updatedValue =
      options?.filter((rec) => newValue.includes(rec.get('uuid') as string)) ??
      fromJS([]);
    log('handleChange: %o', { newValue, updatedValue });
    onChange(asEvent({ name, value: updatedValue }));
  };

  log('%o', { props, options: formattedOptions, uuidValues });

  return (
    <ChecklistAccordion
      // @ts-expect-error 'ChecklistAccordionOptionsGroup[] | CheckboxOption[]' is not assignable to type 'ChecklistAccordionOptionsGroup[]'
      options={formattedOptions}
      value={uuidValues}
      onChange={handleChange}
      {...{ ...checklistAccordionProps, ...checklistAccordionItemProps }}
    />
  );
}
