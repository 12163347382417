import { List, ListItem } from '@chakra-ui/react';

import { NavLink } from 'components';

import { useNavLinkComponentControls } from './NavLinks.hooks';
import { linkSx, listSx } from './NavLinks.styles';

export type NavLinkType = {
  to: string;
  label: string;
  isDisabled?: boolean;
};

/** Desktop NavLinks */
export function NavLinks() {
  const { navLinks } = useNavLinkComponentControls();

  return (
    <nav>
      <List sx={listSx}>
        {navLinks.map(({ to, label, isDisabled }) => (
          <ListItem key={to} cursor={isDisabled ? 'not-allowed' : 'pointer'}>
            <NavLink
              to={to}
              className={isDisabled ? 'disabled' : ''}
              sx={linkSx}
            >
              {label}
            </NavLink>
          </ListItem>
        ))}
      </List>
    </nav>
  );
}
