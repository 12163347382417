import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import type { TitleEntityFields } from 'entities/api/types';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity, {
  type I18nTextEntityRecord,
  toString,
} from './i18n/I18nText';

class ControlDesignationEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/control_designation/',
  };

  static fields: EntityFields<ControlDesignationEntityFields> = {
    uuid: new Fields.IdField(),
    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    description: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    order: new Fields.IntegerField(),
  };

  static toString = toString<ControlDesignationEntityRecord>;
}

export type ControlDesignationEntityFields = {
  uuid: string;
  title: I18nTextEntityRecord;
  description: I18nTextEntityRecord;
  order: number;
};

export type ControlDesignationEntityRecord = EntityRecord<
  TitleEntityFields & ControlDesignationEntityFields
>;

ControlDesignationEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ControlDesignationEntity,
  name: 'ControlDesignation',
});

export default ControlDesignationEntity;
