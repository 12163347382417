import { breakpoints } from './breakpoints';
import { space } from './space';

/**
 * These are mainly here for reference: prefer adding semanticTokens to
 * `semanticTokens.sizes`, where referenced theme values will be interpolated.
 *
 * https://chakra-ui.com/docs/styled-system/theme#sizes
 * https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/foundations/sizes.ts
 * https://github.com/chakra-ui/chakra-ui/tree/%40chakra-ui/react%402.8.2/packages/components/theme/src/foundations/sizes.ts
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/react/src/theme/tokens/sizes.ts
 */
export const sizes = {
  ...space,

  max: 'max-content',
  min: 'min-content',
  full: '100%',

  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
  '8xl': '90rem',
  prose: '60ch',

  container: breakpoints,
};
