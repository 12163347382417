import { Box, Flex } from '@chakra-ui/react';
import { type MouseEventHandler } from 'react';

import { containerSx, optionSx, thumbSx, trackSx } from './ValueToggle.styles';

export type ValueToggleProps = {
  name: string;
  options: { value: string | number; label: string }[];
  // we anticipate that the parent might supply undefined or null, which don't exist in options
  value: string | number | undefined | null;
  onChange: (value: string | number) => void;
  size?: string;
};

/**
 * ValueToggle component
 *
 * A custom toggle switch component that allows users to select between two options.
 * It uses radio inputs for accessibility and Chakra UI for styling.
 */
export const ValueToggle = ({
  name,
  options,
  value,
  onChange,
  size = 'md',
}: ValueToggleProps) => {
  const { value: leftValue, label: leftLabel } = options[0];
  const { value: rightValue, label: rightLabel } = options[1];

  // Determine the current selection based on the value prop
  const currentSelection: 'left' | 'right' | null =
    value == undefined || value == null
      ? null
      : value === leftValue
        ? 'left'
        : 'right';

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    let newValue = (event.target as HTMLDivElement).dataset.value;
    if (!newValue) {
      // if this click bubbled up to the parent, then there's no dataset value
      // associated, so toggle to the other value
      newValue = (value == leftValue ? rightValue : leftValue) as string;
    }
    if (newValue === value) return;
    onChange(newValue);
  };

  return (
    <Flex
      className={`ValueToggle ValueToggle--${size}`}
      sx={containerSx}
      data-selected={currentSelection}
      onClick={handleClick}
    >
      {/* Hidden radio inputs for accessibility */}
      <input
        type="radio"
        name={name}
        style={{ display: 'none' }}
        value={leftValue}
        checked={currentSelection === 'left'}
        readOnly
      />
      <input
        type="radio"
        name={name}
        style={{ display: 'none' }}
        value={rightValue}
        checked={currentSelection === 'right'}
        readOnly
      />

      {/* Render Track underneath Thumbs so that the displayed options line up visually */}
      <Flex className="ValueToggle__track" sx={trackSx}>
        <Box data-value={leftValue} sx={optionSx}>
          {leftLabel}
        </Box>
        <Box data-value={rightValue} sx={optionSx}>
          {rightLabel}
        </Box>
      </Flex>

      {/* 'Thumb' indicates the selected option */}
      {currentSelection && (
        <Flex className="ValueToggle__thumb" sx={thumbSx}>
          <Box data-value={leftValue} className="left" sx={optionSx}>
            {leftLabel}
          </Box>
          <Box data-value={rightValue} className="right" sx={optionSx}>
            {rightLabel}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
