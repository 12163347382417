import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  type AccordionItemProps,
  AccordionPanel,
  type AccordionProps,
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  type SystemStyleObject,
} from '@chakra-ui/react';
import {
  type MouseEventHandler,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { filterProps } from '@burnsred/higher-order-component';

import { checklistAccordionSx } from './ChecklistAccordion.styles';

export type CheckboxOption = { value: string; label: string };
export type ChecklistAccordionOptionsGroup = {
  id: string;
  heading: string;
  items: CheckboxOption[];
};

export type ChecklistAccordionProps = Omit<
  AccordionProps,
  'variant' | 'children' | 'onChange' | 'sx' | 'options'
> & {
  /** Supported variants are: 'cubeGrey', 'cube' */
  variant?: AccordionProps['variant'];

  /** Styles which refine the variant, adjusting for nested checklists */
  sx?: SystemStyleObject;

  options: ChecklistAccordionOptionsGroup[];
  value: string[];
  onChange: (values: string[]) => void;
  isDisabled?: boolean;
};

const ChecklistAccordionContext = createContext<Pick<
  ChecklistAccordionProps,
  'value' | 'onChange' | 'isDisabled'
> | null>(null);

export type ChecklistAccordionItemProps = Omit<
  AccordionItemProps,
  'onChange'
> & {
  heading: string;
  options: CheckboxOption[];
};

/** Renders a CheckboxGroup in an AccordionItem */
const ChecklistAccordionItem = ({
  isDisabled,
  heading,
  options = [],
  sx,
  ...rest
}: ChecklistAccordionItemProps) => {
  const localValues = useMemo(
    () => options.map(({ value }) => value),
    [options],
  );

  const context = useContext(ChecklistAccordionContext);
  if (context == null) return null;

  const { value, onChange } = context;

  const isAllChecked = options.every((option) => value.includes(option.value));
  const isIndeterminate =
    !isAllChecked && options.some((option) => value.includes(option.value));

  const handleToggleAll: MouseEventHandler<HTMLSpanElement> = (event) => {
    event.preventDefault();
    if (isDisabled) return;

    /** values controlled by a different ChecklistAccordionItem */
    const otherValues = value.filter((v) => !localValues.includes(v));
    // only update values in the current ChecklistAccordionItem
    const newValue = [...otherValues, ...(isAllChecked ? [] : localValues)];
    onChange(newValue);
  };

  // const handleChange: CheckboxGroupProps['onChange'] = (newValue) => {
  //   if (isDisabled) return;
  //   onChange?.(newValue);
  // };

  return (
    // FIXME doesn't properly support isDisabled, isReadOnly
    <AccordionItem isDisabled={isDisabled} {...rest} sx={sx}>
      <AccordionButton>
        {/* HACK: capture clicks on Checkbox & prevent bubbling up to AccordionButton */}
        <Flex onClick={handleToggleAll}>
          <Checkbox
            isChecked={isAllChecked}
            isIndeterminate={isIndeterminate}
            isDisabled={isDisabled}
          />
        </Flex>

        <Heading as="h3">{heading}</Heading>

        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel>
        <CheckboxGroup
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
        >
          {options.map(({ value, label }) => (
            <Checkbox key={value} value={value}>
              {label}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </AccordionPanel>
    </AccordionItem>
  );
};

/**
 * Renders nested lists of CheckBoxes inside an Accordion.
 *
 * ```tsx
 * const [value, setValue] = useState<string[]>([]);
 *
 * const options = [
 *   {
 *     id: 'uuid1',
 *     heading: 'heading 1',
 *     items: [
 *       { value: 'value1', label: 'label1' },
 *       { value: 'value2', label: 'label2' },
 *     ],
 *   },
 *   {
 *     id: 'uuid2',
 *     heading: 'heading 2',
 *     items: [
 *       { value: 'value1', label: 'label1' },
 *       { value: 'value2', label: 'label2' },
 *     ],
 *   },
 * ];
 *
 * <ChecklistAccordion
 *   options={options}
 *   value={value}
 *   onChange={setValue}
 *   variant="cube"
 * />
 * ```
 *
 * - https://v2.chakra-ui.com/docs/components/checkbox
 * - https://v2.chakra-ui.com/docs/components/accordion/usage
 *
 * @default allowMultiple true
 * @default variant "cubeGrey"
 */
export const ChecklistAccordion = (props: ChecklistAccordionProps) => {
  // avoid passing incompatible props to Accordion / ChecklistAccordionItem
  const {
    onChange,
    value,
    isDisabled,
    variant = 'cubeGrey',
    sx = checklistAccordionSx,
    options,
    ...accordionProps
  } = props;

  return (
    <Accordion
      variant={variant}
      allowMultiple
      sx={sx}
      {...filterProps(accordionProps)}
    >
      <ChecklistAccordionContext.Provider
        value={{ value, onChange, isDisabled }}
      >
        {options?.map(({ id, heading, items }, i) => (
          <ChecklistAccordionItem
            key={id ?? i}
            heading={heading}
            options={items}
          />
        ))}
      </ChecklistAccordionContext.Provider>
    </Accordion>
  );
};
