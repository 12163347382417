import type { SystemStyleObject } from '@chakra-ui/react';

export const timezoneWrapperSx: SystemStyleObject = {
  width: 'full',
  border: '1px',
  borderColor: 'gray.200',
};

export const zoneSx: SystemStyleObject = {
  width: 'full',
  borderRight: '1px',
  borderColor: 'gray.200',
  '&:last-of-type': { borderRight: 'none' },

  _hover: {
    cursor: 'help',
  },
};

export const tooltipSx: SystemStyleObject = {
  fontSize: 'xs',
  padding: 2,

  '& .chakra-text': {
    color: 'faint',
  },
};
