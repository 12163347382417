import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  // root: {},
  // tab: {},
  // tablist: {},
  // tabpanel: {},
  // tabpanels: {},
  // indicator: {},
});

const sizes = {
  sm: definePartsStyle({
    tab: {
      py: 1,
      px: 4,
      fontSize: 'sm',
    },
  }),
  md: definePartsStyle({
    tab: {
      fontSize: 'sm',
      fontWeight: 'bold',
      py: 2,
      px: 5,
    },
  }),
  lg: definePartsStyle({
    tab: {
      fontSize: 'lg',
      py: 3,
      px: 4,
    },
  }),
};

const enclosed = definePartsStyle({
  tablist: {
    gap: 1,
  },
  tab: {
    bgColor: 'white',
    borderColor: 'gray.200',
    textTransform: 'uppercase',
    color: 'gray.300',

    _selected: {
      color: 'primary',
      borderColor: 'gray.300',
    },
  },
  tabpanel: {
    padding: 0,
  },
});

const line = definePartsStyle({
  root: {
    '--tabs-color': 'var(--chakra-colors-gray-500)',
  },
  tablist: {
    borderBottom: '1px',
  },
  tab: {
    _selected: {
      '--tabs-color': 'var(--chakra-colors-primary)',
    },
  },
  tabpanel: {
    paddingTop: 6,
    paddingX: 0,
  },
  indicator: {
    marginTop: '-1px',
    color: 'primary',
  },
});

/**
 * Variants: line,enclosed, enclosed-colored, soft-rounded, solid-rounded, and unstyled
 *
 * @see https://chakra-ui.com/docs/components/tabs
 * @see https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/tabs.ts
 */
export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    enclosed,
    line,
  },
  sizes,

  defaultProps: {
    variant: 'enclosed',
  },
});
