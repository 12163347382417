import axios, { type AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

import { type Settings, settings } from 'settings/index';

/**
 * @see axiosInstance
 *
 * Note: this function is not exported to prevent accidental re-instantiation.
 */
function createAxiosInstance(settings: Settings): AxiosInstance {
  if (!settings?.BASE_API_URL?.startsWith('http'))
    throw new Error('settings.BASE_API_URL is not defined');

  const instance = axios.create({
    timeout: 60000,
    baseURL: settings.BASE_API_URL,

    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },

    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: settings.CSRF_COOKIE_NAME || 'csrftoken',

    /**
     * > Since automatic XSRF token sending when the `withCredentials` option is set
     * > has become considered a vulnerability, although it could be disabled, the
     * > only practical solution is probably to add a separate option to control the
     * > sending of the token.
     * >
     * > So now the user must explicitly set `withXSRFToken` to true to send XSRF
     * > token to third-party origins.
     * >
     * > By default `withXSRFToken` is undefined - the token will be sent only to the
     * > same origin.
     * > You can set it to false to disable setting the header at all. In practice,
     * > this is the same as setting `xsrfCookieName` or `xsrfHeaderName` to falsy.
     * >
     * > To migrate from the old `withCredential` behavior (<v1.6.0), you should now
     * > use `withXSRFToken` along with `withCredential`.
     *
     * https://github.com/axios/axios/pull/6046
     * https://github.com/axios/axios/releases/tag/v1.6.2
     *
     * XSRF-TOKEN value is disclosed to an unauthorised actor
     * https://github.com/axios/axios/issues/6006
     * https://nvd.nist.gov/vuln/detail/CVE-2023-45857 (CWE-359)
     *
     * > The library inserts the X-XSRF-TOKEN header using the secret XSRF-TOKEN
     * > cookie value in all requests to any server when the XSRF-TOKEN cookie is
     * > available, and the withCredentials setting is turned on. If a malicious
     * > user manages to obtain this value, it can potentially lead to the XSRF
     * > defence mechanism bypass.
     *
     * note[ptim]: we require this sending the CSRF Token, and mitigate the
     * dangers by restricting origins to which this instance may call.
     */
    withXSRFToken: true,
    // Pass cookies cross-domain, as the API is on a different origin to the FE
    withCredentials: true,
  });

  // Prevent unintended XSRF token leakage by restricting the remote origin that
  // this instance can call. To call external origins, create a new axios instance.
  instance.interceptors.request.use(function (config) {
    if (
      config.baseURL &&
      ![settings.BASE_API_URL, settings.FILE_UPLOAD_URL].includes(
        config.baseURL,
      )
    )
      throw new Error(
        'config.baseURL may not be edited in this axios instance',
      );

    const requestUrl = new URL(config?.url ?? '', config.baseURL);
    const isValidBrokerOrigin = requestUrl.origin == settings.BASE_API_ORIGIN;

    if (!isValidBrokerOrigin)
      throw new Error(
        'This axios instance may only call the broker configured at BASE_API_ORIGIN',
      );

    return config;
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // If we get any 401 response we redirect the user to the login
      if (error.response && error.response.status === 401) {
        const currentUrl = window.location.href;
        window.location.href = `${settings.LOGIN_URL}?next=${encodeURIComponent(currentUrl)}`;
      }

      if (error.response && error.response.status === 403) {
        if (!error.response.data.error) {
          window.location.hash = `/not-allowed`;
        }
      }
      return Promise.reject(error);
    },
  );
  axiosRetry(instance, {
    retries: 4,
    retryDelay: axiosRetry.exponentialDelay,
  });
  return instance;
}

/**
 * An axios instance specifically for connecting with our Broker because:
 *
 * - our broker requires XSRF configuration and it may be on a different origin
 *   than the front-end
 * - CVE-2023-45857 considers it unsafe to leak an XSRF token to external origins
 *
 * Thus, using this axios instance prevents developers from inadvertently
 * broadcasting the CSRF token to external domains.
 *
 * @returns an axios instance restricted to Requests to settings.BASE_API_ORIGIN
 */
export const axiosInstance = createAxiosInstance(settings);
export const languageHeaders = {
  es: 'en;q=0.5, es',
  en: 'en, es;q=0.5',
};
