/**
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/foundations/radius.ts
 */
export const radius = {
  none: '0',
  sm: '0.125rem', // 2px
  base: '0.25rem', // 4px
  md: '0.375rem', // 6px
  lg: '0.5rem', // 8px
  xl: '0.75rem',
  '2xl': '1rem',
  '3xl': '1.5rem',
  full: '9999px',
};

// Shim conusing singular / plural export name!
export const radii = radius;
