import { Flex, Heading } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { TreePickerListProps } from 'components/widgets/TreePicker/DefaultComponents';

import { headerSx, headingSx, listSx, sectionSx } from './List.styles';

const headings: Record<string, ReactNode> = {
  '0': (
    <FormattedMessage
      id="RAOPicker.List.heading.region"
      defaultMessage="Region"
    />
  ),
  '1': (
    <FormattedMessage
      id="RAOPicker.List.heading.asset"
      defaultMessage="Asset"
    />
  ),
  '2': (
    <FormattedMessage
      id="RAOPicker.List.heading.operation"
      defaultMessage="Operation"
    />
  ),
};

/** Displays a list of GrcOrganisationalUnits belonging to the same parent */
export const ListComponent = ({
  children,
  level,
  role,
}: TreePickerListProps) => {
  return (
    <Flex as="section" sx={sectionSx} data-test-id={'TODO level'}>
      <Flex as="header" sx={headerSx}>
        <Heading as="h4" sx={headingSx}>
          {headings[String(level)]}
        </Heading>
      </Flex>

      <Flex as="ul" sx={listSx} role={role}>
        {children}
      </Flex>
    </Flex>
  );
};
