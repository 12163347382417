import { tagAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const md = defineStyle({
  fontSize: '12',
});

const outlineVariant = defineStyle({
  container: {
    borderRadius: '2px',
    borderWidth: '1px',
    borderColor: 'faded',
    boxShadow: 0,
    fontWeight: '400',
  },
  label: {
    color: 'bodyText',
  },
});

const sizes = {
  md: definePartsStyle({ label: md }),
};

/**
 * @see https://chakra-ui.com/docs/components/tag
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/tag.ts
 */
export const tagTheme = defineMultiStyleConfig({
  sizes,
  variants: {
    outline: outlineVariant,
  },
});
