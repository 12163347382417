import { VStack as ChakraVStack, type StackProps } from '@chakra-ui/react';

/**
 * Renders VStack with our preferred defaults
 *
 * @default width "full"
 * @default align "left"
 * @default spacing 4
 */
export const VStack = ({ children, ...props }: StackProps) => (
  <ChakraVStack width="full" align="left" spacing={4} {...props}>
    {children}
  </ChakraVStack>
);
