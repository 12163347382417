import {
  FormControl,
  FormHelperText,
  FormLabel,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { type ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { toString as i18nTextToString } from 'entities/api/i18n/I18nText';
import type { BaseControlEntityFields } from 'entities/api/types';
import { useLocale } from 'locales/useLocale';
import { createLogger } from 'util/createLogger';

import {
  simpleGridSx,
  textAreaSx,
} from './GlobalFrameworkControlDocumentation.styles';
import type { GlobalControlDetailContextType } from '../GlobalFrameworkControlDetail';

const log = createLogger('GlobalFrameworkControlDocumentation');

type DocumentationFieldProps = {
  value: string;
  label: ReactElement;
  helperText: ReactElement;
};

/**
 * Renders control documentation into a Fieldset.
 */
const DocumentationField = ({
  value = 'TODO',
  label,
  helperText,
}: DocumentationFieldProps) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Textarea
      variant="outline"
      defaultValue={value}
      sx={textAreaSx}
      isReadOnly
    />
    <FormHelperText color="gray.500">{helperText}</FormHelperText>
  </FormControl>
);

export const GlobalFrameworkControlDocumentation = ({
  controlFormControls,
}: GlobalControlDetailContextType) => {
  const { locale } = useLocale();
  const control = controlFormControls?.value;

  /** helper enables lookup of valid fieldNames */
  const toString = useCallback(
    (fieldName: keyof BaseControlEntityFields) =>
      control ? i18nTextToString(control, locale, fieldName) : '',
    [control, locale],
  );

  log('control: %o', control);

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} sx={simpleGridSx}>
      <DocumentationField
        value={toString('features')}
        label={
          <FormattedMessage
            id="global-framework.control.documentation.features.label"
            defaultMessage="Control features"
          />
        }
        helperText={
          <FormattedMessage
            id="global-framework.control.documentation.features.help-text"
            defaultMessage="Features that the control must have to intervene in the incident sequence."
          />
        }
      />
      <DocumentationField
        value={toString('design_standard')}
        label={
          <FormattedMessage
            id="global-framework.control.documentation.design-standards.label"
            defaultMessage="Design standards"
          />
        }
        helperText={
          <FormattedMessage
            id="global-framework.control.documentation.design-standards.help-text"
            defaultMessage="Features for control effectiveness that must be 'designed in'."
          />
        }
      />
      <DocumentationField
        value={toString('operating_standard')}
        label={
          <FormattedMessage
            id="global-framework.control.documentation.operating-standards.label"
            defaultMessage="Operating standards"
          />
        }
        helperText={
          <FormattedMessage
            id="global-framework.control.documentation.operating-standards.help-text"
            defaultMessage="Features for control effectiveness that must be delivered during operation of the control in practice."
          />
        }
      />
      <DocumentationField
        value={toString('purpose')}
        label={
          <FormattedMessage
            id="global-framework.control.documentation.purpose.label"
            defaultMessage="Control purpose"
          />
        }
        helperText={
          <FormattedMessage
            id="global-framework.control.documentation.purpose.help-text"
            defaultMessage="The intention of the control, which the control features must deliver on."
          />
        }
      />
      <DocumentationField
        value={toString('in_scope')}
        label={
          <FormattedMessage
            id="global-framework.control.documentation.in-scope.label"
            defaultMessage="In scope"
          />
        }
        helperText={
          <FormattedMessage
            id="global-framework.control.documentation.in-scope.help-text"
            defaultMessage="Context that control applies to (e.g. operations type, activity type, equipment type etc)."
          />
        }
      />
      <DocumentationField
        value={toString('out_of_scope')}
        label={
          <FormattedMessage
            id="global-framework.control.documentation.out-of-scope.label"
            defaultMessage="Out of scope"
          />
        }
        helperText={
          <FormattedMessage
            id="global-framework.control.documentation.out-of-scope.help-text"
            defaultMessage="Context that control does not apply to (where clarification necessary)."
          />
        }
      />
      <DocumentationField
        value={toString('erosion')}
        label={
          <FormattedMessage
            id="global-framework.control.documentation.control-erosion.label"
            defaultMessage="Control erosion / failure factors"
          />
        }
        helperText={
          <FormattedMessage
            id="global-framework.control.documentation.control-erosion.help-text"
            defaultMessage="How the control may not deliver when needed, given limitations of human performance and other practical issues that emerge over time (to be addressed by 1st line Verification and Assurance activities)."
          />
        }
      />
    </SimpleGrid>
  );
};
