import { type SystemStyleObject } from '@chakra-ui/react';

export const navWrapperSx: SystemStyleObject = {
  bgColor: 'white',
};

export const logoWrapperSx: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'flex-start',
  width: 'navColumnWidth',
  height: 'desktopNavHeight',
  paddingLeft: 4,
  paddingY: 4,
  bgColor: 'primary',
};

export const iconSx: SystemStyleObject = {
  display: 'flex',
};

export const pageTitleSx: SystemStyleObject = {
  paddingLeft: 2,
  paddingTop: 1,
};

export const avatar: SystemStyleObject = {
  cursor: 'pointer',
  userSelect: 'none',
};

export const avatarBadgeStyle: SystemStyleObject = {
  height: '1em',
  width: '1em',
  backgroundColor: 'error',
};

export const navInnerContainer: SystemStyleObject = {
  minHeight: 'desktopNavHeight',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingX: '0',
};

export const userMenuButtonSx: SystemStyleObject = {
  justifyContent: 'space-between',
  gap: '4',
};
export const usernameSx: SystemStyleObject = {
  fontSize: 'xs',
};

export const userMenuListSx: SystemStyleObject = {
  justifyContent: 'flex-end',
  minWidth: '64',
};
