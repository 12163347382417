import type { SystemStyleObject } from '@chakra-ui/react';

export const cardSx: SystemStyleObject = {
  bgColor: 'gray.50',
  border: '2px',
  borderStyle: 'dashed',
  borderColor: 'gray.300',

  '.chakra-linkbox__overlay': {
    display: 'flex',
    height: 'full',
  },

  '.chakra-card__body': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
};
