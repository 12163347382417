import type { List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { type EntityFields, type EntityRecord } from 'types.ts';

import QuestionAttachmentEntity, {
  type QuestionAttachmentEntityRecord,
} from './QuestionAttachment.ts';
import URLReferenceEntity, {
  type URLReferenceEntityRecord,
} from './URLReference.ts';
import I18nTextEntity, {
  type I18nTextEntityRecord,
  toString,
} from '../i18n/I18nText';

class BaseQuestionEntity extends Entity {
  static fields: EntityFields<BaseQuestionEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    instruction: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    attachments: new Fields.EntityField({
      entity: QuestionAttachmentEntity,
      many: true,
      blank: true,
    }),

    url_references: new Fields.EntityField({
      entity: URLReferenceEntity,
      many: true,
      blank: true,
    }),
  };

  static toString = toString<BaseQuestionEntityRecord>;
}

export type BaseQuestionEntityFields = {
  uuid: string;
  title: I18nTextEntityRecord;
  instruction: I18nTextEntityRecord;
  attachments: List<QuestionAttachmentEntityRecord>;
  url_references: List<URLReferenceEntityRecord>;
};

export type BaseQuestionEntityRecord = EntityRecord<BaseQuestionEntityFields>;

export default BaseQuestionEntity;
