import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

class UserEntity extends Entity {
  static fields: EntityFields<UserEntityFields> = {
    username: new Fields.CharField(),
    first_name: new Fields.CharField(),
    last_name: new Fields.CharField(),
  };

  static toString(record: UserEntityRecord) {
    return record.get('username');
  }
}

type UserEntityFields = {
  username: string;
  first_name: string;
  last_name: string;
};

export type UserEntityRecord = EntityRecord<UserEntityFields>;

UserEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: UserEntity,
  name: 'User',
});

export default UserEntity;
