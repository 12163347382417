import { Map } from 'immutable';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { createSelector } from 'reselect';

import ApplicableRuleEntity from 'entities/api/ApplicableRule';
import ControlEntity from 'entities/api/Control';
import { useLocale } from 'locales/useLocale';

const controlQuerySelector = createSelector(
  [
    (state: Map<string, unknown>, controlUuid: string) =>
      ControlEntity.duck.selectors.record(state, { id: controlUuid }),
    (state: Map<string, unknown>, controlUuid: string) =>
      ControlEntity.duck.selectors.status(state, {
        id: controlUuid,
        method: 'get',
        status: 'getting',
      }),
    (state: Map<string, unknown>, controlUuid: string) =>
      ControlEntity.duck.selectors.status(state, {
        id: controlUuid,
        method: 'get',
        status: 'gettingDidFail',
      }),
    (
      state: Map<string, unknown>,
      controlUuid: string,
      params: Map<string, unknown>,
    ) => ApplicableRuleEntity.duck.selectors.record(state, { params: params }),
    (
      state: Map<string, unknown>,
      controlUuid: string,
      params: Map<string, unknown>,
    ) =>
      ApplicableRuleEntity.duck.selectors.status(state, {
        params: params,
        method: 'get',
        status: 'getting',
      }),
    (
      state: Map<string, unknown>,
      controlUuid: string,
      params: Map<string, unknown>,
    ) =>
      ApplicableRuleEntity.duck.selectors.status(state, {
        params: params,
        method: 'get',
        status: 'gettingDidFail',
      }),
  ],
  (
    control,
    controlGetting,
    controlGetFailed,
    rules,
    rulesGetting,
    rulesGetFailed,
  ) => {
    let frameworkId = undefined;
    if (rules && !rules.isEmpty()) {
      frameworkId = rules.getIn([0, 'global_framework_id']);
    }
    return {
      control,
      controlGetting,
      controlGetFailed,
      frameworkId,
      rulesGetting,
      rulesGetFailed,
    };
  },
);

export function useDrawerControls() {
  const { toString } = useLocale();
  const navigate = useNavigate();
  const { controlUuid } = useParams();
  const dispatch = useDispatch();
  const params = useMemo(() => Map({ control: controlUuid }), [controlUuid]);
  const {
    control,
    controlGetting,
    controlGetFailed,
    frameworkId,
    rulesGetFailed,
    rulesGetting,
  } = useSelector((state: Map<string, unknown>) =>
    controlQuerySelector(state, controlUuid || '', params),
  );
  useEffect(() => {
    // Strict mode causes double fetch
    if (!control && !controlGetting && !controlGetFailed) {
      dispatch(ControlEntity.duck.actions.get({ id: controlUuid }));
      dispatch(
        ApplicableRuleEntity.duck.actions.get({
          params: Map({ control: controlUuid }),
        }),
      );
    }
  }, [controlUuid, dispatch, control, controlGetting, controlGetFailed]);

  return {
    navigate,
    toString,
    control,
    frameworkId,
    isLoading: controlGetting || !control || rulesGetting,
    didFail: controlGetFailed || rulesGetFailed,
  };
}
