import { type ChakraStylesConfig } from 'chakra-react-select';

/**
 * Display multi-select such that all options are:
 * - rendered comma-separated, as a single line of truncated text
 * - selected options are highlighted in the drop-down
 *
 * ```tsx
 * <Autocomplete
 *   options={...}
 *   isMulti
 *   hideSelectedOptions={false}
 *   chakraStyles={multiValueAutocompleteStyles}
 *
 *   // helpful debug styles
 *   className="react-select-container"
 *   classNamePrefix="react-select"
 * />
 * ```
 *
 * ## FIXME re-selection display issue
 *
 * ```
 * Given I make enough selections to cause them to be truncated
 * And I blur out of the field
 * When I focus the field again
 * Then the tailing selection is shown (truncated, but full-width)
 * And the leading selection is hidden
 * ```
 */
export const multiValueAutocompleteStyles: Partial<ChakraStylesConfig> = {
  /*
  div.react-select-container
    div.react-select__control
      div.react-select__value-container
        span.react-select__multi-value
          span.react-select__multi-value__label
          div.react-select__multi-value__remove
        .react-select__input-container
          input
    div.react-select__indicators
      div.react-select__indicator[role=button]
      hr.react-select__indicator-separator
      div.react-select__dropdown-indicator
  */

  // control: (prev, _state) => ({
  //   ...prev,
  // }),

  /**
   * Box
   * .react-select__value-container
   * .react-select__value-container--is-multi
   * .react-select__value-container--has-value
   */
  valueContainer: (prev, state) => ({
    ...prev,
    // placeholder requires flex, values require inline
    display: state.hasValue ? 'inline' : 'inline-flex',

    marginLeft: 4, // prefer margin-left to preserve spacing on 'truncated options field re-focus'
    paddingLeft: 0,

    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '& > span:last-of-type > span:after': { content: '""' },
  }),

  /**
   * chakra.span (uses theme styles for Chakra's Tag)
   * .react-select__multi-value
   */
  multiValue: (prev) => ({
    ...prev,
    display: 'inline',
    margin: 0,
    padding: 0,
    paddingRight: 1,
    border: 'none',
    bgColor: 'transparent',
    fontWeight: 'normal',
  }),

  /**
   * chakra.span (uses theme styles for Chakra's TagLabel)
   * .react-select__multi-value__label
   */
  multiValueLabel: (prev) => ({
    ...prev,

    _after: {
      display: 'inline',
      content: '";"',
    },
  }),

  /**
   * Box (uses theme styles for Chakra's TagCloseButton)
   * .react-select__multi-value__remove
   */
  multiValueRemove: () => ({
    display: 'none',
  }),

  // indicatorsContainer: (provided) => ({
  //   ...provided,
  // }),

  clearIndicator: (provided) => ({
    ...provided,
    width: 2.5,
    height: 2.5,
    margin: 0,
    padding: 3,

    svg: {
      width: 'inherit',
      height: 'inherit',
    },
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
};
