import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

class SiteFrameworkReportEntity extends Entity {
  static paths = {
    apiBase: '/cube/v1/reporting/site_framework_report/',
  };

  static fields: EntityFields<SiteFrameworkReportEntityFields> = {
    ccc_total: new Fields.NumberField({
      default: 0,
    }),
    ccc_total_prev: new Fields.NumberField({
      default: 0,
    }),
    ccc_interrupt_total: new Fields.NumberField({
      default: 0,
    }),
    ccc_fix_total: new Fields.NumberField({
      default: 0,
    }),
    cc_total: new Fields.NumberField({
      default: 0,
    }),
    cc_total_prev: new Fields.NumberField({
      default: 0,
    }),
    cc_with_actions_total: new Fields.NumberField({
      default: 0,
    }),
    site_framework_total: new Fields.NumberField({
      default: 0,
    }),
    site_framework_active_total: new Fields.NumberField({
      default: 0,
    }),
    control_implementations_total: new Fields.NumberField({
      default: 0,
    }),
    control_implementations_active_total: new Fields.NumberField({
      default: 0,
    }),
  };
}

type SiteFrameworkReportEntityFields = {
  ccc_total: number;
  ccc_total_prev: number;
  ccc_interrupt_total: number;
  ccc_fix_total: number;
  cc_total: number;
  cc_total_prev: number;
  cc_with_actions_total: number;
  site_framework_total: number;
  site_framework_active_total: number;
  control_implementations_total: number;
  control_implementations_active_total: number;
};

export type SiteFrameworkReportEntityRecord =
  EntityRecord<SiteFrameworkReportEntityFields>;

SiteFrameworkReportEntity.duck = new DjangoRestFramework({
  app: 'Reports',
  entity: SiteFrameworkReportEntity,
  name: 'SiteFrameworkReport',
});

export default SiteFrameworkReportEntity;
