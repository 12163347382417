import { defineStyleConfig } from '@chakra-ui/react';

/**
 * Theme largely inherits from Input
 *
 * @see https://v2.chakra-ui.com/docs/components/textarea/usage
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%402.4.2/packages/components/theme/src/components/textarea.ts
 */
export const textareaTheme = defineStyleConfig({
  defaultProps: {
    size: 'md',
    variant: 'filled',
  },
});
