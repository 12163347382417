import { useEffect, useMemo } from 'react';

import { useQuery } from '@burnsred/entity-duck-query';
import type { PaginatorProps, PaginatorState } from 'components';
import { SiteFrameworkReportEntity, usePagination } from 'entities';
import { useCurrentUser } from 'entities/api/Person/Person';
import SiteFrameworkEntity from 'entities/api/SiteFramework.ts';
import RiskOwnerDashboardFilterEntity, {
  type RiskOwnerDashboardFilterEntityRecord,
} from 'entities/filter/RiskOwnerFilter';
import { useEntityFilterControls } from 'forms/hooks/filters';

export function useRiskOwnerControls() {
  const { name, filterRecord, onChange, FilterField, errors } =
    useEntityFilterControls<
      typeof RiskOwnerDashboardFilterEntity,
      RiskOwnerDashboardFilterEntityRecord
    >(
      RiskOwnerDashboardFilterEntity,
      'RiskDashboardFilters',
      RiskOwnerDashboardFilterEntity.onChangeClean,
    );

  // memoise params to prevent a duplicate API call triggered by useSelector call
  const params = useMemo(
    () => RiskOwnerDashboardFilterEntity.asMappedParams(filterRecord),
    [filterRecord],
  );
  const pagination = usePagination(SiteFrameworkEntity, params);
  const onChangePagination = ({ page, page_size }: PaginatorState) => {
    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const newRecord = filterRecord.withMutations((rec) => {
      rec.set('page', String(page) || '1');
      rec.set('page_size', String(page_size) || '10');
    });
    onChange({
      target: { name, value: newRecord },
    });
  };
  const paginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('page') ?? '1'),
    page_size: parseInt(filterRecord?.get('page_size') ?? '6'),
    totalItems: pagination?.get('count') ?? 0,
    onChange: onChangePagination,
    pageSizeOptions: [6, 12],
  };

  const { value: stats } = useQuery({
    action: SiteFrameworkReportEntity.duck.actions.get({
      params:
        RiskOwnerDashboardFilterEntity.asMappedForReportsParams(filterRecord),
    }),
  });
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (
      currentUser &&
      filterRecord &&
      !currentUser?.equals(filterRecord.get('site_association__risk_owner'))
    ) {
      onChange({
        target: {
          name: name,
          value: filterRecord?.set('site_association__risk_owner', currentUser),
        },
      });
    }
  }, [currentUser, filterRecord, name, onChange]);
  return {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    stats,
    paginatorProps,
    params,
  };
}
