import { useIntl } from 'react-intl';

import CCFVQuestionEntity from 'entities/api/Question/CCFVQuestion';
import { createLogger } from 'util/createLogger';

import { QuestionsLayout } from './QuestionsLayout';
import type { GlobalControlDetailContextType } from '../GlobalFrameworkControlDetail';

const log = createLogger('GlobalFrameworkControlFCC');

export const GlobalFrameworkControlFCC = ({
  controlFormControls,
}: GlobalControlDetailContextType) => {
  const { formatMessage } = useIntl();

  return (
    <QuestionsLayout
      heading={formatMessage({
        id: 'GlobalFrameworkControlFCC.heading.critical-control-check',
        defaultMessage: 'Field control confirmation',
      })}
      fieldName="ccfv_questions"
      controlFormControls={controlFormControls}
      QuestionEntity={CCFVQuestionEntity}
      log={log}
      observationType="ControlFCC"
    />
  );
};
