import { List } from 'immutable';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Bug in eslint: says Entity is not used??
/* eslint-disable-next-line unused-imports/no-unused-imports-ts, unused-imports/no-unused-imports */
import { type Entity, type Field, Fields } from '@burnsred/entity';
import {
  type EntityFormProps,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

export function useEntityFilterControls<
  T extends typeof Entity,
  U extends EntityRecord<unknown>,
>(
  Entity: T,
  name = 'FilterControls',
  cleanEvent: (record: U, evt: FormChangeEvent, rootName: string) => U,
) {
  const FilterField = new Fields.EntityField({
    entity: Entity,
  });

  const filterRecord = useSelector((state) =>
    Entity.duck.selectors.record(state, { id: null }),
  ) as U;

  const dispatch = useDispatch();

  const onChange = useCallback(
    (evt) => {
      const newRecord = cleanEvent(filterRecord, evt, name);
      dispatch(Entity.duck.actions.save(Entity.clean(newRecord)));
    },
    [dispatch, Entity, cleanEvent, filterRecord, name],
  ) as EntityFormProps['onChange'];

  return {
    name,
    filterRecord,
    onChange,
    FilterField,
    // Find a way to construct correctly using entity.validate
    errors: List([]),
    dispatch,
  };
}
