import {
  IconButton,
  type SystemStyleObject,
  type UseDisclosureReturn,
} from '@chakra-ui/react';

import { HamburgerClosedIcon, HamburgerOpenIcon } from '@burnsred/ui-chakra';

export const iconButton: SystemStyleObject = {
  width: 'fit-content',
  // alight the right edge of the icon with the content bounds
  marginX: '12px',
  bgColor: 'transparent',

  _hover: {
    bgColor: 'transparent',
  },
};

type MobileNavToggleProps = {
  disclosure: UseDisclosureReturn;
  sx?: SystemStyleObject;
  boxSize?: number;
  /** should be a string returned by useIntl#formatMessage */
  ariaLabel: string;
};

export const MobileNavToggle = ({
  disclosure: { isOpen, onToggle },
  sx = {},
  boxSize = 6,
  ariaLabel,
}: MobileNavToggleProps) => (
  <IconButton
    variant="ghost"
    icon={
      isOpen ? (
        <HamburgerOpenIcon boxSize={boxSize} />
      ) : (
        <HamburgerClosedIcon boxSize={boxSize} />
      )
    }
    aria-label={ariaLabel}
    onClick={onToggle}
    sx={{ ...iconButton, ...sx }}
  />
);
