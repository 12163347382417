import { Flex, type FlexProps, Text } from '@chakra-ui/react';
import type { ReactElement } from 'react';

import {
  contentWindowSx,
  frameWrapperSx,
  headingSx,
} from './PhonePreviewFrame.styles';

type PhonePreviewFrameProps = FlexProps & {
  heading: string | ReactElement;
};

/**
 * Displays a fixed size phone preview frame.
 *
 * FlexProps will be passed to the inner Flex.
 *
 * ```tsx
 * <PhonePreviewFrame heading="Control check" gap="4">
 *   <Textarea isDisabled defaultValue="Some content" />
 *
 *   <Button variant="outline" width="full">
 *     Instructions
 *   </Button>
 * </PhonePreviewFrame>
 * ```
 */
export const PhonePreviewFrame = ({
  children,
  heading = 'Critical control check',
  ...innerFlexProps
}: PhonePreviewFrameProps) => {
  return (
    <Flex className="PhonePreviewFrame" sx={frameWrapperSx}>
      <Text sx={headingSx}>{heading}</Text>

      <Flex
        className="PhonePreviewFrame__content-window"
        sx={contentWindowSx}
        {...innerFlexProps}
      >
        {children}
      </Flex>
    </Flex>
  );
};
