import { withQuery } from '@burnsred/entity-duck-query';
import { SiteApplicableRuleEntity } from 'entities';
import { createLogger } from 'util/createLogger';

import { ControlRules } from './ControlRules';
import type { ControlDetailContextType } from '../ControlFrameworkControlDetail';

const log = createLogger('ControlFrameworkControlRules');

export const ControlFrameworkControlRules = withQuery(
  ({ controlFormControls, siteFramework }: ControlDetailContextType) => {
    log('%o', { controlFormControls, siteFramework });

    return {
      action: SiteApplicableRuleEntity.duck.actions.get({
        // REVIEW: if applicable_rules == '' then all rules will be returned
        id: controlFormControls?.value?.get('applicable_rules'),
      }),
      componentProps: {
        controlFormControls,
        siteFramework,
      },
    };
  },
)(ControlRules);
