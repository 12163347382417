import {
  Input as ChakraInput,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  HStack,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Loading } from '@burnsred/ui-chakra';
import { TimeZoneSelector, VStack } from 'components';
import { Fieldset, FieldsetItem } from 'forms/fields';
import { useLocale } from 'locales/useLocale.tsx';
import { createLogger } from 'util/createLogger';

import type { GlobalControlDetailContextType } from '../GlobalFrameworkControlDetail';

const log = createLogger('ControlSettings');

export const GlobalFrameworkControlSettings = ({
  controlFormControls,
}: GlobalControlDetailContextType) => {
  const control = controlFormControls?.value;
  log('%o', { control });

  const { toString } = useLocale();

  if (!control) return <Loading />;

  return (
    <VStack>
      <Fieldset heading={null}>
        <GridItem colSpan={{ base: 12, lg: 9 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.control-title"
                defaultMessage="Control title"
              />
            </FormLabel>
            <ChakraInput isDisabled defaultValue={toString(control)} />
          </FormControl>
        </GridItem>

        <FieldsetItem colSpan={{ base: 12, lg: 3 }}>
          <HStack h={10}>
            <Checkbox isDisabled isChecked={control.get('is_critical')}>
              <FormattedMessage
                id="global-framework.control.label.is-critical"
                defaultMessage="Critical control"
              />
            </Checkbox>
          </HStack>
        </FieldsetItem>

        <GridItem colSpan={{ base: 12 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.timezones"
                defaultMessage="Control event phases"
              />
            </FormLabel>

            <TimeZoneSelector timezones={control.get('timezones')} isDisabled />

            <FormHelperText>
              <FormattedMessage
                id="global-framework.control.help-text.timezone"
                defaultMessage="Click on the boxes where the control is present."
              />
            </FormHelperText>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.causes"
                defaultMessage="Causes"
              />
            </FormLabel>
            <ChakraInput isDisabled defaultValue={toString(control, 'cause')} />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.existing-controls"
                defaultMessage="Existing controls"
              />
            </FormLabel>
            <ChakraInput isDisabled />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.control-domain"
                defaultMessage="Control domain"
              />
            </FormLabel>
            <ChakraInput
              isDisabled
              defaultValue={toString(control, 'control_domain')}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.supporting-controls"
                defaultMessage="Supporting controls"
              />
            </FormLabel>
            <ChakraInput isDisabled />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.hierarchy-of-control"
                defaultMessage="Hierarchy of control"
              />
            </FormLabel>
            <ChakraInput
              isDisabled
              defaultValue={toString(control, 'hierarchy_of_control')}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.justification"
                defaultMessage="Justification"
              />
            </FormLabel>
            <ChakraInput
              isDisabled
              defaultValue={toString(control, 'justification')}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.fel-link"
                defaultMessage="FEL link #"
              />
            </FormLabel>
            <ChakraInput
              isDisabled
              defaultValue={toString(control, 'fel_link_number')}
            />
          </FormControl>
        </GridItem>
      </Fieldset>
    </VStack>
  );
};
