import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import type { I18nTextEntityRecord } from './i18n/I18nText';
import I18nTextEntity from './i18n/I18nText';

class TimeZoneEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/timezone/',
  };

  static fields: EntityFields<TimeZoneEntityFields> = {
    uuid: new Fields.IdField(),
    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    is_active: new Fields.BooleanField(),
    order: new Fields.IntegerField(),
    description: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    color: new Fields.CharField(),
  };

  static toString(record: TimeZoneEntityRecord, locale = '') {
    return I18nTextEntity.toString(record.get('title'), locale);
  }
}

type TimeZoneEntityFields = {
  uuid: string;
  title: I18nTextEntityRecord;
  is_active: boolean;
  order: number;
  description: I18nTextEntityRecord;
  color: string;
};

export type TimeZoneEntityRecord = EntityRecord<TimeZoneEntityFields>;
TimeZoneEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: TimeZoneEntity,
  name: 'TimeZone',
});

export default TimeZoneEntity;
