import {
  Drawer,
  DrawerContent,
  type DrawerContentProps,
  DrawerOverlay,
  type DrawerProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';

export type RoutedDrawerProps = Omit<
  DrawerProps,
  'children' | 'isOpen' | 'onClose' | 'onCloseComplete'
> & {
  /**
   * By default, RoutedDrawer will navigate up one level;
   * to customise this behaviour, pass onCloseComplete.
   */
  onCloseComplete?: DrawerProps['onCloseComplete'];
} & Pick<DrawerContentProps, 'children'>;

/**
 * A Drawer component which integrates with react-router:
 *
 * - is initially open when rendered
 * - navigates to the parent path onCloseComplete
 * - renders Drawer, DrawerOverlay, DrawerContent
 * - takes DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter as children
 *
 * ```tsx
 * export const routes: RouteObject[] = [
 *   {
 *     path: 'parent',
 *     children: [
 *       { index: true, element: <ParentComponent /> },
 *       { path: ':childId', element: <ChildComponent /> },
 *     ],
 *   },
 * ];
 *
 * export const ParentComponent = () => (
 *   <div>
 *     <h1>Parent</h1>
 *
 *     <Link to="uuid-some-child-id-1234">
 *       Open the sub-route in a Drawer
 *     </Link>
 *
 *     <Outlet />
 *   </div>
 * );
 *
 * export const ChildComponent = () => {
 *   const { childId } = useParams();
 *
 *   return (
 *     <RoutedDrawer>
 *       <DrawerHeader>Child</DrawerHeader>
 *       <DrawerBody>childId: {childId}</DrawerBody>
 *     </RoutedDrawer>
 *   );
 * };
 * ```
 */
export const RoutedDrawer = ({
  children,
  onCloseComplete,
  ...drawerProps
}: RoutedDrawerProps) => {
  const navigate = useNavigate();

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  /** wait until the close animation is complete before navigating */
  const handleCloseComplete = () => {
    navigate(`..`, { relative: 'path' });
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={onCloseComplete ?? handleCloseComplete}
      {...drawerProps}
    >
      <DrawerOverlay />
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
};
