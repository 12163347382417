import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class ScenarioModifierEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/scenario_modifier/',
  };

  static fields: EntityFields<ScenarioModifierEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    order: new Fields.IntegerField({
      read_only: true,
    }),
  };
}

type ScenarioModifierEntityFields = TitleEntityFields & {
  order: number;
};

export type ScenarioModifierEntityRecord =
  EntityRecord<ScenarioModifierEntityFields>;

ScenarioModifierEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ScenarioModifierEntity,
  name: 'ScenarioModifier',
});

export default ScenarioModifierEntity;
