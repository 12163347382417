import { useIntl } from 'react-intl';

import { SiteControlCCFVQuestionEntity } from 'entities/api/Question/SiteCCFVQuestion';
import { createLogger } from 'util/createLogger';

import { QuestionsLayout } from './QuestionsLayout';
import type { ControlDetailContextType } from '../ControlFrameworkControlDetail';

const log = createLogger('ControlFCC');

export const ControlFCC = ({
  controlFormControls,
}: ControlDetailContextType) => {
  const { formatMessage } = useIntl();

  return (
    <QuestionsLayout
      heading={formatMessage({
        id: 'ControlFCC.heading.critical-control-check',
        defaultMessage: 'Field control confirmation',
      })}
      fieldName="sitecontrolccfvquestion_set"
      controlFormControls={controlFormControls}
      QuestionEntity={SiteControlCCFVQuestionEntity}
      log={log}
      observationType="ControlFCC"
    />
  );
};
