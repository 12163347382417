import {
  Link as ChakraLink,
  type LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import {
  NavLink as RouterNavLink,
  type NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';

export type NavLinkProps = RouterNavLinkProps & Omit<ChakraLinkProps, 'href'>;

/**
 * Theme-able Link which wraps react-router's NavLink.
 *
 * ```tsx
 * <NavLink to="/">anchor text</NavLink>
 * ```
 *
 * Note: Our default usage pattern is to link to a client-side route using
 * react-router; in the case you want to link to an external URL, instead:
 *
 * ```tsx
 * import { NavLink } from '@chakra-ui/react';
 * ```
 *
 * @see https://reactrouter.com/en/main/components/nav-link
 * @see https://chakra-ui.com/docs/components/link/usage#usage-with-routing-library
 */
export const NavLink = (props: NavLinkProps) => (
  <ChakraLink as={RouterNavLink} {...props} />
);
