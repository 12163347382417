import { type SystemStyleObject } from '@chakra-ui/react';

export const mainColumnSx: SystemStyleObject = {
  width: 'full',
  maxWidth: {
    lg: 'full',
    xl: 'calc(var(--chakra-sizes-container-xl) - var(--chakra-sizes-navColumnWidth))',
  },
  margin: '0 auto',
  paddingTop: '10',
  paddingX: 'gutter',
  paddingBottom: 6,
};

export const desktopAsideSx: SystemStyleObject = {
  gap: '3',
  paddingBottom: 6,
  maxWidth: '180px',
};

export const stickyFooterSx: SystemStyleObject = {
  mt: 'auto',
};
