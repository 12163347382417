import { Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityRecord } from 'types';

import I18nTextEntity, { type I18nTextEntityRecord } from './i18n/I18nText';
import MPTTEntity, { type MPTTEntityFields } from './MPTT';

class EquipmentEntity extends MPTTEntity {
  static paginated = true;

  static fields = {
    ...MPTTEntity.fields,

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
  };

  static paths = {
    apiBase: '/cube/v1/equipment/',
  };
}

export type EquipmentEntityFields = MPTTEntityFields & {
  title: I18nTextEntityRecord;
};

export type EquipmentEntityRecord = EntityRecord<EquipmentEntityFields>;

EquipmentEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: EquipmentEntity,
  name: 'Equipment',
});

export default EquipmentEntity;
