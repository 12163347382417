import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import type { RulePredicateEntityRecord } from './RulePredicate';
import RulePredicateEntity from './RulePredicate';

class ApplicableRuleEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/applicable_rule/',
  };

  static fields: EntityFields<ApplicableRuleEntityFields> = {
    uuid: new Fields.IdField(),
    predicates: new Fields.EntityField({
      entity: RulePredicateEntity,
      many: true,
    }),
    is_active: new Fields.BooleanField(),
    is_mandatory: new Fields.BooleanField(),
    risk: new Fields.CharField(),
    global_framework_id: new Fields.CharField(),
  };
}

type ApplicableRuleEntityFields = {
  uuid: string;
  predicates: List<RulePredicateEntityRecord>;
  is_active: boolean;
  is_mandatory: boolean;
  risk: string;
  global_framework_id: string;
};

export type ApplicableRuleEntityRecord =
  EntityRecord<ApplicableRuleEntityFields>;

ApplicableRuleEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ApplicableRuleEntity,
  name: 'ApplicableRule',
});

export default ApplicableRuleEntity;
