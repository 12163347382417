import { type ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';

/**
 * @see https://chakra-ui.com/docs/components/container
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/layout
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/container.ts
 */
export const containerTheme: ComponentStyleConfig = defineStyleConfig({
  defaultProps: {
    size: 'xl',
    // variant: 'custom',
    // colorScheme: 'brand',
  },

  baseStyle: {},

  variants: {
    desktopLayout: {
      // see theme/semanticTokens
      maxWidth: 'container.xl',
      paddingX: 'none',
    },
  },
});
