import type { MouseEventHandler } from 'react';

import { MPTT } from 'entities';
import type { MPTTEntityRecord } from 'entities/api/MPTT';

import { DefaultRecord } from '../DefaultComponents';
import type { UseTreePickerReturn } from '../TreePicker.hooks';

type TreePickerNodeProps<T extends MPTTEntityRecord> = Pick<
  UseTreePickerReturn<T>,
  | 'value'
  | 'onChange'
  | 'parents'
  | 'recordComponent'
  | 'getLabel'
  | 'isSelectedCallback'
  | 'isIndeterminate'
  | 'isDisabled'
> & {
  item: T;
  level: number;
  isExpanded: boolean;
  count: number;
  handleToggleExpanded: MouseEventHandler;
};

export const TreePickerNode = <T extends MPTTEntityRecord>({
  item,
  level,
  isExpanded,
  handleToggleExpanded,
  recordComponent,
  getLabel,
  value,
  onChange,
  // parents,
  isSelectedCallback,
  isIndeterminate,
  count,
  isDisabled,
}: TreePickerNodeProps<T>) => {
  const Component = recordComponent ?? DefaultRecord;

  const uuid = item.get('uuid') as string;

  /**
   * if the entity has no field 'has_children',
   * fail open so user can attempt to browse children,
   * displaying 'No results' if empty
   *
   * Note: has_children may return unexpected results, eg:
   *
   * - Carrapateena.has_children = true
   *   http://localhost:8000/api/bhp_people/v2/grc_organisational_unit/?is_active=true&is_location=false&parent=4ad29811-ba89-49bd-a2b5-23e1a6ed4ee9
   * - but direct query returns no children:
   *   http://localhost:8000/api/bhp_people/v2/grc_organisational_unit/?is_active=true&is_location=false&parent=50c381bc-871b-4a19-aefb-ab8290524abd
   *
   * TODO provide an override here?
   */
  const hasChildren = MPTT.hasChildren(item) ?? true;

  const isSelected =
    typeof isSelectedCallback == 'function'
      ? isSelectedCallback(item, value)
      : value?.includes(item) ?? false;

  /**
   * Differentiates between click intentions:
   * - clicking the checkbox should trigger onChange
   * - clicking anywhere else should trigger handleToggleExpanded
   *
   * Note: ensure styles cause the Checkbox to completely fill its parent so it
   * catches all clicks
   */
  const handleClick: MouseEventHandler = (event) => {
    // if we preventDefault, this function gets called twice!
    event.preventDefault();

    const classList = [
      ...((event.target as HTMLLabelElement)?.classList ?? []),
    ];
    /** whether event.target is the label, otherwise it's the chakra-checkbox__control */
    const isLabel =
      // because the checkbox target/currentTarget might be a child of svg
      classList.includes('chakra-checkbox') ||
      classList.includes('chakra-checkbox__label') ||
      classList.includes('TreePicker__Node__label') ||
      classList.includes('TreePicker__Node__count') ||
      classList.includes('TreePicker__Node__expanded-icon');

    console.log('🌕 TreePickerNode.handleClick', {
      isLabel,
      isSelected,
      isExpanded,
      hasChildren,
      classList,
      // level,
      // item,
    });

    if (isLabel) {
      if (hasChildren && !isExpanded) handleToggleExpanded(event);
    } else {
      if (!isDisabled) onChange(item);
    }
  };

  return (
    <Component
      className={[
        'TreePicker__Node',
        hasChildren ? 'has-children' : '',
        isSelected ? 'is-selected' : '',
      ].join(' ')}
      item={item}
      level={level}
      data-uuid={uuid}
      data-test-id="TreePicker__Node"
      onClick={handleClick}
      role="listitem"
      hasChildren={hasChildren}
      isOpen={isExpanded}
      isSelected={isSelected}
      isIndeterminate={isIndeterminate(item)}
      count={count}
      data-expanded={isExpanded || undefined}
      aria-expanded={isExpanded || undefined}
      isDisabled={isDisabled}
    >
      {getLabel(item) || 'unknown label'}
    </Component>
  );
};
