import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export function EntriesFound({ count }: { count?: number }) {
  return (
    <Text>
      <FormattedMessage id="ui.entries-found" defaultMessage="Entries found" />
      {`: ${count ?? '…'}`}
    </Text>
  );
}
