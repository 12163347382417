import {
  Button,
  Input as ChakraInput,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { fromJS } from 'immutable';
import { FormattedMessage } from 'react-intl';

import {
  Loading,
  RouterLink,
  handleClickPlaceholder,
} from '@burnsred/ui-chakra';
import { TimeZoneSelector, VStack } from 'components';
import { RoutedDrawer } from 'components/layout';
import { Fieldset } from 'forms/fields';
import { createLogger } from 'util/createLogger';

import { useDrawerControls } from './ControlFrameworkControlsDrawer.hooks';

const log = createLogger('ControlFrameworkControlsDrawer');

export const ControlFrameworkControlDrawer = () => {
  const { navigate, toString, isLoading, didFail, control, frameworkId } =
    useDrawerControls();
  log('%o', { control });
  const handleCloseComplete = () => {
    navigate(`../..`, { relative: 'path' });
  };

  // @Fixme string builder is brittle if the routing changes, centralize construction of urls and use it in both
  // routes.tsx and components that need to create links to objects.
  const controlUrl = `/global-frameworks/${frameworkId}/controls/${control?.get('uuid')}/settings`;

  if (isLoading) return <Loading />;
  if (didFail)
    return 'ERROR: processingDidFailControl || processingDidFailGlobalFrameworks';

  return (
    <RoutedDrawer
      placement="right"
      size="md"
      onCloseComplete={handleCloseComplete}
    >
      <DrawerBody>
        <VStack sx={{ pt: 12 }}>
          <DrawerCloseButton />

          <Heading size="sm">{toString(control)}</Heading>

          <FormControl>
            <FormLabel>{toString(control?.get('control_domain'))}</FormLabel>
            <TimeZoneSelector
              timezones={control?.get('timezones') ?? fromJS([])}
              isReadOnly
            />
          </FormControl>

          <Divider />

          <Fieldset heading={null}>
            <GridItem colSpan={{ base: 12 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="ControlFrameworkControlDrawer.label.risk"
                    defaultMessage="Risk"
                  />
                </FormLabel>
                <ChakraInput isDisabled />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="ControlFrameworkControlDrawer.label.scenario"
                    defaultMessage="Scenario"
                  />
                </FormLabel>
                <ChakraInput isDisabled />
              </FormControl>
            </GridItem>
          </Fieldset>

          <Divider />

          <Stack direction={{ base: 'column', sm: 'row' }}>
            <Button
              onClick={handleClickPlaceholder}
              variant="outline"
              isDisabled
            >
              <FormattedMessage
                id="ControlFrameworkControlDrawer.controls.subscribe"
                defaultMessage="Subscribe to this control"
              />
            </Button>

            <Button
              onClick={handleClickPlaceholder}
              variant="outline"
              isDisabled
            >
              <FormattedMessage
                id="ControlFrameworkControlDrawer.controls.copy"
                defaultMessage="Make a copy"
              />
            </Button>

            <Button
              as={RouterLink}
              to={controlUrl}
              isDisabled={!frameworkId}
              relative="path"
              variant="outline"
              marginLeft="auto"
            >
              <FormattedMessage
                id="ControlFrameworkControlDrawer.controls.view"
                defaultMessage="View control details"
              />
            </Button>
          </Stack>

          <Divider />

          <Fieldset
            heading={
              <FormattedMessage
                id="ControlFrameworkControlDrawer.fieldset.created-by"
                defaultMessage="Control created by"
              />
            }
          >
            <GridItem colSpan={{ base: 12 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="ControlFrameworkControlDrawer.label.author"
                    defaultMessage="Author"
                  />
                </FormLabel>
                <ChakraInput isDisabled />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="ControlFrameworkControlDrawer.label.control-owners"
                    defaultMessage="Control owners"
                  />
                </FormLabel>
                <ChakraInput isDisabled />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 6 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="ControlFrameworkControlDrawer.label.asset"
                    defaultMessage="Asset"
                  />
                </FormLabel>
                <ChakraInput isDisabled />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 6 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="ControlFrameworkControlDrawer.label.operation"
                    defaultMessage="Operation"
                  />
                </FormLabel>
                <ChakraInput isDisabled />
              </FormControl>
            </GridItem>
          </Fieldset>

          <Fieldset
            heading={
              <FormattedMessage
                id="controls.used-by"
                defaultMessage="Control used by"
              />
            }
          >
            <GridItem colSpan={12}>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        <FormattedMessage
                          id="ControlFrameworkControlDrawer.th.asset"
                          defaultMessage="Asset"
                        />
                      </Th>
                      <Th>
                        <FormattedMessage
                          id="ControlFrameworkControlDrawer.th.operation"
                          defaultMessage="Operation"
                        />
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {[0].map((el, i) => (
                      <Tr key={i}>
                        <Td>&ndash;</Td>
                        <Td>&ndash;</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </GridItem>
          </Fieldset>
        </VStack>
      </DrawerBody>
    </RoutedDrawer>
  );
};
