import { Checkbox, HStack } from '@chakra-ui/react';

import { type EntityDuckAction, type FormChangeEvent } from 'types';

import useThreeStateCheckboxControls from './ThreeStateCheckbox.hooks';

type ThreeStateCheckBoxProps = {
  name: string;
  lhs_label: string;
  rhs_label: string;
  spacing: number;
  onChange: (event: FormChangeEvent) => EntityDuckAction;
  value: boolean | null | undefined;
  reverse?: boolean;
};

export const ThreeStateCheckBox = ({
  lhs_label,
  rhs_label,
  name,
  onChange,
  spacing,
  value,
  reverse = false,
}: ThreeStateCheckBoxProps) => {
  const { lhs, setLhs, rhs, setRhs } = useThreeStateCheckboxControls({
    onChange,
    name,
    value,
    reverse,
  });
  return (
    <HStack spacing={spacing}>
      <Checkbox
        name="left"
        isChecked={lhs}
        onChange={(e) => {
          setLhs(e.target.checked);
        }}
        isDisabled={!rhs}
      >
        {lhs_label}
      </Checkbox>

      <Checkbox
        name="right"
        isChecked={rhs}
        onChange={(e) => {
          setRhs(e.target.checked);
        }}
        isDisabled={!lhs}
      >
        {rhs_label}
      </Checkbox>
    </HStack>
  );
};
