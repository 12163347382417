import type { SystemStyleObject } from '@chakra-ui/react';

export const treePickerSx: SystemStyleObject = {
  fontSize: 'sm',

  '&.TreePicker__Wrapper': {
    display: 'flex',
    flexDirection: 'row',
  },

  '.TreePicker__List': {
    //
  },

  '.TreePicker__Node': {
    // don't show pointer unless .has-children
    cursor: 'default',

    '.chakra-checkbox': {
      // ensure that the checkbox label fills the entire row, to capture clicks
      display: 'flex',
      width: 'full',
    },

    '.chakra-checkbox__label': {
      // have the 'expanded icon' display to the far right of the row
      display: 'flex',
      alignItems: 'center',
      width: 'full',

      cursor: 'default',

      _disabled: { opacity: 1 },
    },

    '.chakra-checkbox__control': {},

    '&.has-children': {
      cursor: 'pointer',
      '.chakra-checkbox__label': { cursor: 'pointer' },
    },

    '.TreePicker__Node__label': {
      marginRight: 'auto',
    },
    '.TreePicker__Node__count': {
      paddingLeft: 2,

      _before: { content: '"("' },
      _after: { content: '")"' },
    },
    '.TreePicker__Node__expanded-icon': {
      //
    },
  },

  '.TreePicker__DefaultEmptyRecord': {
    //
  },

  '&.debug': {
    '&.TreePicker__Wrapper': {
      gap: 2,
      padding: 2,
      outline: '1px solid lightgray',
    },
    '.TreePicker__List': {
      padding: 2,
      outline: '1px solid lightgray',
    },
    '.TreePicker__Node': {
      _hover: {
        outline: '1px solid lightgray',
      },
    },
  },
};
