import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

function getSize(value: string) {
  if (value === 'full') {
    return definePartsStyle({
      dialog: { maxW: '100vw', h: '100vh' },
    });
  }
  return definePartsStyle({
    dialog: { maxW: value },
  });
}

// const baseStyle = definePartsStyle({});

/**
 * @see https://v2.chakra-ui.com/docs/components/drawer
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%402.4.2/packages/components/theme/src/components/drawer.ts
 */
export const drawerTheme = defineMultiStyleConfig({
  // baseStyle,
  sizes: {
    // xs: getSize('xs'),
    // sm: getSize('md'),
    md: {
      ...getSize('xl'), // default: lg
      header: { paddingX: 12 },
      body: { paddingX: 12 },
      footer: { paddingX: 12 },
    },
    // lg: getSize('2xl'),
    // xl: getSize('4xl'),
    // full: getSize('full'),
  },
});
