import { Map } from 'immutable';
import { useOutletContext } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import ApplicableRuleEntity from 'entities/api/ApplicableRule';

import { GlobalControlRules } from './GlobalControlRules/GlobalControlRules';
import type { GlobalControlDetailContextType } from '../../GlobalFrameworkControlDetail';

export const GlobalFrameworkControlRules = withQuery(() => {
  const { controlFormControls } =
    useOutletContext<GlobalControlDetailContextType>();

  return {
    action: ApplicableRuleEntity.duck.actions.get({
      params: Map({
        control: controlFormControls?.value?.get('uuid'),
      }),
    }),
  };
})(GlobalControlRules);
