import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type LocaleKey } from 'locales';
import { type EntityFields, type EntityRecord } from 'types';

class UserPreferencesEntity extends Entity {
  static paginated = true;
  static idField = 'username';

  static paths = {
    apiBase: '/bhp_field_leadership/preferences/v2/user_preferences/',
  };

  static fields: EntityFields<UserPreferencesEntityFields> = {
    username: new Fields.IdField(),
    locale: new Fields.CharField(),
  };
}

type UserPreferencesEntityFields = {
  username: string;
  locale: LocaleKey;
};

export type UserPreferencesEntityRecord =
  EntityRecord<UserPreferencesEntityFields>;
UserPreferencesEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: UserPreferencesEntity,
  name: 'UserPreferences',
});

export default UserPreferencesEntity;
