import {
  Grid,
  type GridProps,
  Heading,
  type HeadingProps,
} from '@chakra-ui/react';
import { type ReactNode, useId } from 'react';

import { VStack } from 'components/atoms';

export type FieldsetProps = Omit<GridProps, 'children'> & {
  /** children are expected to be GridItems */
  children?: GridProps['children'];
  /**
   * Renders a Heading within a GridItem which spans
   *
   * Pass null if you want to omit the auto heading.
   */
  heading?: ReactNode;
  headingProps?: HeadingProps;
};

/**
 * Renders a `fieldset` containing:
 * - an accessible `Heading`.
 * - a `Grid`
 *
 * ```tsx
 * <Fieldset
 *   heading={
 *     <FormattedMessage
 *       id="risk.operating-contexts"
 *       defaultMessage="Operating contexts"
 *     />
 *   }
 * >
 *   <Field name="field-one" />
 * </Fieldset>
 * ```
 *
 * @default templateColumns 'repeat(12, 1fr)'
 */
export const Fieldset = ({
  children = null,
  templateColumns = 'repeat(12, 1fr)',
  heading,
  headingProps,
  ...props
}: FieldsetProps) => {
  const id = useId();

  return (
    // note that there's a bug in chrome which prevents fieldset from taking flex values
    <fieldset aria-labelledby={heading ? id : undefined}>
      <VStack spacing="gutter">
        {heading && (
          <Heading as="h3" id={id} size="sm" {...headingProps}>
            {heading}
          </Heading>
        )}

        <Grid templateColumns={templateColumns} gap="gutter" {...props}>
          {/* These children are all expected to be GridItems */}
          {children}
        </Grid>
      </VStack>
    </fieldset>
  );
};
