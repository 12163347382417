import type { SystemStyleObject } from '@chakra-ui/react';

export const frameWrapperSx: SystemStyleObject = {
  position: 'relative',
  display: {
    base: 'none',
    lg: 'flex',
  },
  flexShrink: 0,
  flexGrow: 0,

  // BG image dimensions
  width: '380px',
  height: '714px',

  backgroundImage: new URL('/phone-preview-frame.svg', import.meta.url).href,
  backgroundRepeat: 'no-repeat',
};

export const headingSx: SystemStyleObject = {
  position: 'absolute',
  top: '62px',

  width: 'full',
  textAlign: 'center',

  fontSize: 'sm',
  color: 'gray.400',
};

export const contentWindowSx: SystemStyleObject = {
  flexDirection: 'column',
  gap: 4,
  width: 'full',
  overflowY: 'auto',

  // matches designs pretty closely; yields a 324 x 490 content area
  marginTop: '112px',
  marginX: '28px',
  marginBottom: '112px',
};
