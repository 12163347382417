import {
  Input as ChakraInput,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  HStack,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Loading } from '@burnsred/ui-chakra';
import { TimeZoneSelector, VStack } from 'components';
import { Fieldset, FieldsetItem } from 'forms/fields';
import { useLocale } from 'locales/useLocale.tsx';
import { createLogger } from 'util/createLogger';

import type { ControlDetailContextType } from '../ControlFrameworkControlDetail';

const log = createLogger('ControlSettings');

export const ControlSettings = ({
  controlFormControls,
  siteFramework,
}: ControlDetailContextType) => {
  const control = controlFormControls?.value;
  log('%o', { control, siteFramework });

  const { toString } = useLocale();

  if (!control) return <Loading />;

  return (
    <VStack>
      <Fieldset heading={null}>
        <GridItem colSpan={{ base: 12, md: 9 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.control-title"
                defaultMessage="Control title"
              />
            </FormLabel>
            <ChakraInput isDisabled defaultValue={toString(control)} />
          </FormControl>
        </GridItem>

        <FieldsetItem colSpan={{ base: 12, md: 3 }}>
          <HStack h={10}>
            <Checkbox isDisabled isChecked={control?.get('is_critical')}>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.critical-control"
                defaultMessage="Critical control"
              />
            </Checkbox>
          </HStack>
        </FieldsetItem>

        <GridItem colSpan={{ base: 12 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.control-timezones"
                defaultMessage="Control timezones"
              />
            </FormLabel>
            <TimeZoneSelector
              timezones={control?.get('timezones')}
              isDisabled
            />
            <FormHelperText>
              <FormattedMessage
                id="controls.settings"
                defaultMessage="Click on the boxes where the control is present."
              />
            </FormHelperText>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 6 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.causes"
                defaultMessage="Causes"
              />
            </FormLabel>
            <ChakraInput isDisabled defaultValue={toString(control, 'cause')} />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.existing-controls"
                defaultMessage="Existing controls"
              />
            </FormLabel>
            <ChakraInput isDisabled />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.control-domain"
                defaultMessage="Control domain"
              />
            </FormLabel>
            <ChakraInput
              isDisabled
              defaultValue={toString(control, 'control_domain')}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.supporting-controls"
                defaultMessage="Supporting controls"
              />
            </FormLabel>
            <ChakraInput isDisabled />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.hierarchy-of-control"
                defaultMessage="Hierarchy of control"
              />
            </FormLabel>
            <ChakraInput
              isDisabled
              defaultValue={toString(control, 'hierarchy_of_control')}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.justification"
                defaultMessage="Justification"
              />
            </FormLabel>
            <ChakraInput
              isDisabled
              defaultValue={toString(control, 'justification')}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="control-framework.control.tab.settings.label.fel-link"
                defaultMessage="FEL link #"
              />
            </FormLabel>
            <ChakraInput
              isDisabled
              defaultValue={toString(control, 'fel_link_number')}
            />
          </FormControl>
        </GridItem>
      </Fieldset>
    </VStack>
  );
};
