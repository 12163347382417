export const initMatomoTracking = () => {
  const _paq = (window._paq = window._paq || []);

  // tracker methods like 'setCustomDimension' should be called before 'trackPageView'
  _paq.push(['enableLinkTracking']);
  _paq.push(['enableHeartBeatTimer']);
  _paq.push(['trackPageView']);

  (function () {
    const u = 'https://analytics.burnsred.com.au/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', import.meta.env.VITE_MATOMO_SITE_ID]);
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode?.insertBefore(g, s);
  })();
};
