import { type List } from 'immutable';

import type { MPTTEntityRecord } from 'entities/api/MPTT';

import { useTreePickerList } from './TreePickerList.hooks';
import { DefaultEmptyRecord, DefaultList } from '../DefaultComponents';
import { TreePickerNode } from '../TreePickerNode';

export type TreePickerListProps<T extends MPTTEntityRecord = MPTTEntityRecord> =
  {
    level: number;
    parent: string;
    items: List<T>;
  };

export const TreePickerList = ({
  level,
  parent,
  items,
}: TreePickerListProps) => {
  const {
    parents,
    handleToggleExpanded,
    listComponent,
    recordComponent,
    emptyRecordComponent,
    getLabel,
    value,
    onChange,
    isSelectedCallback,
    isIndeterminate,
    counts,
    getIsExpanded,
  } = useTreePickerList({
    level,
    parent,
  });

  const List = listComponent ?? DefaultList;
  const EmptyRecord = emptyRecordComponent ?? DefaultEmptyRecord;

  return (
    <List
      className="TreePicker__List"
      level={level}
      data-level={level}
      data-parent={parent}
      items={items}
      role="list"
    >
      {items?.size ? (
        items.map((item, i) => (
          <TreePickerNode
            key={i}
            item={item}
            parents={parents}
            level={level}
            isExpanded={getIsExpanded(item)}
            handleToggleExpanded={handleToggleExpanded}
            getLabel={getLabel}
            value={value}
            onChange={onChange}
            isSelectedCallback={isSelectedCallback}
            isIndeterminate={isIndeterminate}
            count={counts[item.get('uuid')]}
            recordComponent={recordComponent}
          />
        ))
      ) : (
        <EmptyRecord />
      )}
    </List>
  );
};
