import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import type { ReactElement } from 'react';

import { RouterLink } from '@burnsred/ui-chakra';
import { icons } from 'assets/icons';
import { TimeZoneGraph } from 'components';
import type {
  BaseControlEntityFields,
  TitleEntityFields,
} from 'entities/api/types';
import type { LocaleContext } from 'locales/useLocale';
import type { EntityRecord } from 'types';
import { createLogger } from 'util/createLogger';

import {
  cardHeaderSx,
  cardSx,
  controlDesignationSx,
  headingSx,
  iconSx,
} from './ControlCard.styles';

const log = createLogger('ControlCard');

const controlTypeIcon = {
  Local: icons.MdOutlinePlace,
  Global: icons.MdLanguage,
  // FIXME this list doesn't accommodate all the types defined in SiteFrameworkControl:
  // 'Invalid', 'Uninitialized Global', 'Globalized', 'Local', 'Cloned', 'Subscribed',
};

type ControlCommonFieldsEntityRecord = EntityRecord<
  TitleEntityFields & BaseControlEntityFields
>;

/**
 * ```ts
 * <ControlCard
 *   // ...
 *   linkTarget={(controlUuid) =>
 *     `/${varInCallerParentScope}/${controlUuid}/`
 *   }
 * />
 * ```
 */
export type LinkTargetCallback = (controlUuid: string) => string;

export type ControlCardProps = {
  record: ControlCommonFieldsEntityRecord;
  localeContext: LocaleContext;
  /**
   * A site control can have one or multiple configurations
   * (6 possible configurations, see SiteFrameworkControl)
   */
  controlType: string;
  /** if null is passed, disable linking of the card */
  linkTarget: string | LinkTargetCallback | null;
  /** used by ControlCardWithStats */
  stats?: ReactElement;
};

export const ControlCard = (props: ControlCardProps) => {
  const {
    record,
    localeContext: { toString },
    stats,
    controlType,
  } = props;
  log('%o', props);

  const linkTarget = props.linkTarget
    ? typeof props.linkTarget == 'function'
      ? props.linkTarget(record.get('uuid'))
      : props.linkTarget
          // ?.replace(':siteFrameworkUuid', record.get('uuid'))
          ?.replace(':controlUuid', record.get('uuid'))
    : '#';

  return (
    <Card
      size="sm"
      className={[
        record?.get('is_critical') ? 'critical' : '',
        props.linkTarget == null ? 'disabled' : '',
      ].join(' ')}
      sx={cardSx}
    >
      {/*
      LinkBox suppresses Tooltip behaviour; wrap CardHeader & CardBody separately so that
      Tooltips in TimeZoneGraph function properly.
      */}
      <CardHeader as={LinkBox} sx={cardHeaderSx}>
        <Heading as="h3" sx={headingSx}>
          <LinkOverlay as={RouterLink} to={linkTarget} relative="path">
            {toString(record) || '(Blank)'}
          </LinkOverlay>
        </Heading>

        {controlType && (
          <Icon
            as={controlTypeIcon[controlType as keyof typeof controlTypeIcon]}
            aria-label={controlType}
            sx={iconSx}
          />
        )}
      </CardHeader>

      <CardBody as={LinkBox}>
        <LinkOverlay as={RouterLink} to={linkTarget} relative="path">
          <Text sx={controlDesignationSx}>
            {toString(record?.get('control_designation'))}
          </Text>
        </LinkOverlay>
      </CardBody>

      <CardFooter>
        {stats}
        <TimeZoneGraph timezones={record?.get('timezones')} thin={true} />
      </CardFooter>
    </Card>
  );
};
