import { ListItem } from '@chakra-ui/react';

import { type ScenarioEntityNestedRecord } from 'entities/api/DamageEnergyMechanism';
import { useLocale } from 'locales/useLocale';

import { listItemSx } from './ScenarioCard.styles';
type ScenarioCardProps = {
  record: ScenarioEntityNestedRecord;
};

export const ScenarioCard = (props: ScenarioCardProps) => {
  const { record } = props;
  const { toString } = useLocale();

  return <ListItem sx={listItemSx}>{toString(record)}</ListItem>;
};
