import type { List } from 'immutable';
import { useEffect, useMemo } from 'react';

import { useQuery } from '@burnsred/entity-duck-query';
import type { PaginatorProps, PaginatorState } from 'components';
import { usePagination } from 'entities';
import { useCurrentUser } from 'entities/api/Person/Person';
import SiteControlEntity from 'entities/api/SiteControl.ts';
import SiteFrameworkEntity from 'entities/api/SiteFramework.ts';
import ControlDashboardFilterEntity, {
  type ControlDashboardFilterEntityRecord,
} from 'entities/filter/ControlDashboardFilter';
import SiteControlReportEntity, {
  type SiteControlReportEntityRecord,
} from 'entities/reporting/SiteControlReport';
import { useEntityFilterControls } from 'forms/hooks/filters';
import { useLocale } from 'locales/useLocale';

export function useDashboardControls() {
  const { name, filterRecord, onChange, FilterField, errors } =
    useEntityFilterControls<
      typeof ControlDashboardFilterEntity,
      ControlDashboardFilterEntityRecord
    >(
      ControlDashboardFilterEntity,
      'ControlDashboardFilters',
      ControlDashboardFilterEntity.onChangeClean,
    );
  const currentUser = useCurrentUser();

  // memoise params to prevent a duplicate API call triggered by useSelector call
  const siteFrameworkParams = useMemo(
    () =>
      ControlDashboardFilterEntity.asMappedParamsForSiteFramework(filterRecord),
    [filterRecord],
  );
  const controlParams = useMemo(
    () => ControlDashboardFilterEntity.asMappedParamsForControls(filterRecord),
    [filterRecord],
  );
  const siteFrameworkPagination = usePagination(
    SiteFrameworkEntity,
    siteFrameworkParams,
  );
  const controlPagination = usePagination(SiteControlEntity, controlParams);

  const onChangeSiteFrameworkPagination = ({
    page,
    page_size,
  }: PaginatorState) => {
    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const value = filterRecord.withMutations((rec) => {
      rec.set('site_framework_page', String(page) || '1');
      rec.set('site_page_size', String(page_size) || '10');
    });
    onChange({ target: { name, value } });
  };

  const onChangeControlPagination = ({ page, page_size }: PaginatorState) => {
    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const value = filterRecord.withMutations((rec) => {
      rec.set('control_page', String(page) || '1');
      rec.set('control_page_size', String(page_size) || '10');
    });
    onChange({ target: { name, value } });
  };

  const siteFrameworkPaginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('site_framework_page') ?? '1'),
    page_size: parseInt(filterRecord?.get('site_framework_page_size') ?? '6'),
    totalItems: siteFrameworkPagination?.get('count') ?? 0,
    onChange: onChangeSiteFrameworkPagination,
    pageSizeOptions: [6, 12],
  };

  const controlPaginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('control_page') ?? '1'),
    page_size: parseInt(filterRecord?.get('control_page_size') ?? '6'),
    totalItems: controlPagination?.get('count') ?? 0,
    onChange: onChangeControlPagination,
    pageSizeOptions: [6, 12],
  };

  useEffect(() => {
    if (
      currentUser &&
      filterRecord &&
      !currentUser?.equals(
        filterRecord.get(
          'siteframeworkcontrol__sitecontrolstatus__control_owner',
        ),
      )
    ) {
      onChange({
        target: {
          name: name,
          value: filterRecord?.set(
            'siteframeworkcontrol__sitecontrolstatus__control_owner',
            currentUser,
          ),
        },
      });
    }
  }, [currentUser, filterRecord, name, onChange]);

  const localeContext = useLocale();

  const { value: stats } = useQuery<List<SiteControlReportEntityRecord>>({
    action: SiteControlReportEntity.duck.actions.get({
      params:
        ControlDashboardFilterEntity.asMappedParamsForControlReport(
          filterRecord,
        ),
    }),
  });

  return {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    localeContext,
    stats,
    siteFrameworkParams,
    siteFrameworkPaginatorProps,
    controlParams,
    controlPaginatorProps,
  };
}
