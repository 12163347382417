import { useIntl } from 'react-intl';

import C3QuestionEntity from 'entities/api/Question/C3Question';
import { createLogger } from 'util/createLogger';

import { QuestionsLayout } from './QuestionsLayout';
import type { GlobalControlDetailContextType } from '../GlobalFrameworkControlDetail';

const log = createLogger('GlobalFrameworkControlCCC');

export const GlobalFrameworkControlCCC = ({
  controlFormControls,
}: GlobalControlDetailContextType) => {
  const { formatMessage } = useIntl();

  return (
    <QuestionsLayout
      heading={formatMessage({
        id: 'GlobalFrameworkControlCCC.heading.critical-control-check',
        defaultMessage: 'Critical control check',
      })}
      fieldName="c3_questions"
      controlFormControls={controlFormControls}
      QuestionEntity={C3QuestionEntity}
      log={log}
      observationType="ControlCCC"
    />
  );
};
