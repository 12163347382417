import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class WorkLocationEntity extends Entity {
  static fields: EntityFields<TitleEntityFields> = {
    uuid: new Fields.IdField(),
    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
  };
}

export type WorkLocationEntityRecord = EntityRecord<TitleEntityFields>;

WorkLocationEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: WorkLocationEntity,
  name: 'WorkLocation',
});

export default WorkLocationEntity;
