import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import type { ReactElement } from 'react';

import { icons } from 'assets/icons';
import { Tooltip } from 'components';

import {
  cardSx,
  headingSx,
  statRowFlexSx,
  statRowLabelSx,
  statRowMetricSx,
  trendStatChangeSx,
  trendStatIconSx,
  trendStatMetricSx,
  trendStatSx,
} from './PerformanceCard.style';

type Stat = {
  /**
   * pass either:
   * - a simple metric (`1234`) or
   * - a composed metric, eg: `'123 / 500'`
   */
  metric: number | string;
  /** should be FormattedMessage */
  label: ReactElement;
  /** only applicable to TrendStat */
  change?: number;
  /** only applicable to TrendStat; should be FormattedMessage */
  changeLabel?: ReactElement;
};

const StatRow = ({ metric, label }: Stat) => {
  return (
    <Flex as="figure" sx={statRowFlexSx}>
      <Box sx={statRowMetricSx}>{metric}</Box>

      <Text as="figcaption" sx={statRowLabelSx}>
        {label}
      </Text>
    </Flex>
  );
};

const TrendStat = ({ metric, label, change, changeLabel }: Stat) => {
  return (
    <Flex sx={trendStatSx}>
      <figure>
        <Tooltip label={label}>
          <Box sx={trendStatMetricSx}>{metric}</Box>
        </Tooltip>

        <VisuallyHidden>
          <Text as="figcaption">{label}</Text>
        </VisuallyHidden>
      </figure>

      {change && (
        <figure>
          <Tooltip label={changeLabel} hasArrow>
            <Box sx={trendStatChangeSx}>{change}</Box>
          </Tooltip>

          <VisuallyHidden>
            <Text as="figcaption">{changeLabel}</Text>
          </VisuallyHidden>

          <Icon
            as={change > 0 ? icons.MdNorthEast : icons.MdSouthEast}
            role="none presentation"
            sx={trendStatIconSx}
          />
        </figure>
      )}
    </Flex>
  );
};

export type PerformanceCardProps = {
  /** Should be a FormattedMessage */
  heading: ReactElement;
  stats: [Stat] | [Stat, Stat];
};

/**
 * Renders a Card displaying performance statistics in one of two styles
 * depending on the type of Stats passed:
 * - `TrendStat`: rendered if a single Stat is passed (metric, label, change, changeLabel)
 * - `StatRows`: rendered if 2 Stats are passed (metric, label)
 */
export const PerformanceCard = ({ heading, stats }: PerformanceCardProps) => {
  return (
    <Card sx={cardSx}>
      <CardHeader>
        <Heading as="h3" textStyle="h4" sx={headingSx}>
          {heading}
        </Heading>
      </CardHeader>

      <CardBody>
        {stats.map((stat, i, allStats) =>
          allStats.length == 1 ? (
            <TrendStat key={i} {...stat} />
          ) : (
            <StatRow key={i} {...stat} />
          ),
        )}
      </CardBody>
    </Card>
  );
};
