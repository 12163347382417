import { GridItem, HStack, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Loading } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { FlatListItems } from 'components/FlatListItems/FlatListItems';
import { type ApplicableRuleEntityRecord } from 'entities/api/ApplicableRule';
import { type RulePredicateEntityRecord } from 'entities/api/RulePredicate';
import { Fieldset, FieldsetItem } from 'forms/fields';
import { useLocale } from 'locales/useLocale';

type GlobalRuleProps = {
  rule: ApplicableRuleEntityRecord;
  headingSuffix: string | number;
};

export const GlobalRule = (props: GlobalRuleProps) => {
  const { rule, headingSuffix } = props;
  const { locale } = useLocale();

  const predicateByModel = rule
    ?.get('predicates')
    ?.groupBy((predicate) => predicate.get('model_name'));

  if (!predicateByModel) return <Loading />;
  return (
    <VStack>
      <HStack alignItems={'baseline'}>
        <Heading>
          <FormattedMessage id="GlobalRule.heading" defaultMessage="Rule" />
          {` ${headingSuffix} `}
        </Heading>

        <Text>
          {rule?.get('is_mandatory') ? (
            <FormattedMessage
              id="GlobalRule.mandatory"
              defaultMessage="( Mandatory )"
            />
          ) : (
            <FormattedMessage
              id="GlobalRule.consider"
              defaultMessage="( Consider )"
            />
          )}
        </Text>
      </HStack>

      <SimpleGrid columns={5} gap={2}>
        <Fieldset
          heading={
            <FormattedMessage
              id="GlobalRule.scenarios"
              defaultMessage="Scenarios"
            />
          }
        >
          <FieldsetItem colSpan={{ base: 12 }}>
            <FlatListItems<RulePredicateEntityRecord>
              records={predicateByModel?.get('scenario') || List([])}
              emptyText="None"
              locale={locale}
            />
          </FieldsetItem>
        </Fieldset>

        <Fieldset
          heading={
            <FormattedMessage
              id="GlobalRule.operating-contexts"
              defaultMessage="Operating contexts"
            />
          }
        >
          <GridItem colSpan={{ base: 12 }}>
            <FlatListItems<RulePredicateEntityRecord>
              records={predicateByModel?.get('operatingcontext') || List([])}
              emptyText="None"
              locale={locale}
            />
          </GridItem>
        </Fieldset>

        <Fieldset
          heading={
            <FormattedMessage
              id="GlobalRule.equipment"
              defaultMessage="Equipment"
            />
          }
        >
          <FieldsetItem>
            <FlatListItems<RulePredicateEntityRecord>
              records={predicateByModel?.get('equipment') || List([])}
              emptyText="None"
              locale={locale}
            />
          </FieldsetItem>
        </Fieldset>

        <Fieldset
          heading={
            <FormattedMessage
              id="GlobalRule.worklocation"
              defaultMessage="Work locations"
            />
          }
        >
          <FieldsetItem colSpan={{ base: 12 }}>
            <FlatListItems<RulePredicateEntityRecord>
              records={predicateByModel?.get('worklocation') || List([])}
              emptyText="None"
              locale={locale}
            />
          </FieldsetItem>
        </Fieldset>

        <Fieldset
          heading={
            <FormattedMessage
              id="GlobalRule.scenariomodifier"
              defaultMessage="Scenarios modifiers"
            />
          }
        >
          <FieldsetItem colSpan={{ base: 12 }}>
            <FlatListItems<RulePredicateEntityRecord>
              records={predicateByModel?.get('scenariomodifier') || List([])}
              emptyText="None"
              locale={locale}
            />
          </FieldsetItem>
        </Fieldset>
      </SimpleGrid>
    </VStack>
  );
};
