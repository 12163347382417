import {
  type ChakraTheme,
  type ThemeConfig,
  extendTheme,
} from '@chakra-ui/react';
import { theme as chakraBaseTheme } from '@chakra-ui/theme';

import { components } from './components';
import {
  blur,
  borders,
  breakpoints,
  radii,
  shadows,
  sizes,
  space,
  transition,
  typography,
  zIndices,
} from './foundations';
import { globalStyles } from './globalStyles';
import { layerStyles } from './layerStyles';
import { colors } from './semanticColors';
import { semanticTokens } from './semanticTokens';
import { textStyles } from './textStyles';

/**
 * Importing the baseTheme like this allows us to override, rather than extend
 * select properties.
 *
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/index.ts
 */
const overriddenBaseTheme = {
  ...chakraBaseTheme,
  colors,
};

/**
 * https://chakra-ui.com/docs/styled-system/theme#config
 * https://chakra-ui.com/docs/styled-system/color-mode#updating-the-theme-config
 */
const config: ThemeConfig = {
  /** if `useSystemColorMode=true` we will always try to match the users
   * system-color and fallback to initialColorMode. With this behavior, the
   * colorMode toggle won't have any effect. */
  useSystemColorMode: false,
  initialColorMode: 'light',

  /** The prefix to use for the generated CSS custom properties. Default: 'chakra' */
  // cssVarPrefix: 'br',
};

/**
 * Any customisations we make in here will _extend_ the baseTheme.
 * As required,
 *
 * @see https://chakra-ui.com/docs/styled-system/customize-theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src
 *
 * FIXME I've set this to Partial<ChakraTheme> because it seems some keys are not
 * being inherited from the base - the types complain, but also evident in headings:
 * > chakra-lineHeights-base is not defined
 */
export const theme: Partial<ChakraTheme> = extendTheme(
  {
    config,

    styles: {
      global: globalStyles,
    },

    components,

    // # Foundations
    breakpoints,
    zIndices,
    radii,
    blur,
    // colors, // (already overridden, see ./foundations/semanticColors)
    sizes,
    shadows,
    space,
    borders,
    transition,
    ...typography,

    /**
     * The keys of semanticTokens mirrors the keys of the theme itself
     *
     * Theme tokens inside semanticToken definitions will be resolved:
     *
     * ```ts
     * space: { gutter: 6 } // '6' resolves to '1.5rem'
     * ```
     *
     * @see https://chakra-ui.com/docs/styled-system/semantic-tokens
     * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/theme.types.ts#L123
     */
    semanticTokens,

    /**
     * > The text styles functionality in Chakra makes it easy to repeatably
     * > apply a collection of text properties (like line height and size) to
     * > any component.
     *
     * @see https://chakra-ui.com/docs/styled-system/text-and-layer-styles#text-styles
     */
    textStyles,

    /**
     * > Layer Styles allow you to save a combination of styling attributes to
     * > re-use in other components. Once created, pass the layerStyle prop to
     * > any component and chakra will resolve the styles accordingly.
     * > ...
     * > Layer Styles will not override variant styling.
     *
     * @see https://chakra-ui.com/docs/styled-system/text-and-layer-styles#layer-styles
     */
    layerStyles,
  },

  /*
  Warning: `withDefaultX` methods allow us to make general changes to multiple
  components, but they make it harder to debug styles because they're not
  displayed in the props of components in React devtools.
  */
  // withDefaultColorScheme(...), // Prefer explicit definition elsewhere
  // withDefaultSize(...), // Prefer defining this in the component theme
  // withDefaultVariant(...), // Prefer defining this in the component theme

  // ...pass a base theme as last parameter. If no base theme is provided, we use
  // the Chakra UI default theme
  // https://chakra-ui.com/docs/styled-system/customize-theme#using-theme-extensions
  overriddenBaseTheme,
);
