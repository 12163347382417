import { SimpleGrid, type SystemStyleObject } from '@chakra-ui/react';

import { EmptyControlGridCell } from './components/EmptyControlGridCell';
import { timezonesLoadingFallback } from './ControlGrid';

export const controlGridSectionSx: SystemStyleObject = {
  position: 'relative',
};
export const controlGridHeaderSx: SystemStyleObject = {
  position: 'sticky',
  top: 0,
  marginY: -1,
  paddingY: 1,
  bgColor: 'bodyBg',
};

export const headerSx: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  color: 'white',
  paddingX: 3,
  paddingY: 5,
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',

  _hover: {
    cursor: 'help',
  },
};

export const emptyRowSx: SystemStyleObject = {
  paddingX: 4,
  paddingY: 2,
  rounded: 'base',
  bgColor: 'brandBlueGrey',
  fontSize: 'sm',
};

export const loadingWrapperSx: SystemStyleObject = {
  position: 'relative',
  minHeight: 32,
};

export const RowOfEmptyCells = () => (
  <SimpleGrid className="ControlGrid__row" columns={6} columnGap={6} rowGap={1}>
    {timezonesLoadingFallback.map((_, j) => (
      <EmptyControlGridCell key={j} colStart={j + 1} colEnd={j + 2} />
    ))}
  </SimpleGrid>
);
