import { type SystemStyleObject } from '@chakra-ui/react';

// Styles for the container of the ValueToggle component
export const containerSx: SystemStyleObject = {
  '--container-bg': 'colors.gray.200',
  '--container-shadow': 'shadows.md',
  '--container-radius': 'var(--chakra-radii-xl)',
  '--thumb-radius': 'var(--chakra-radii-lg)',
  '--thumb-shadow': 'shadows.base',

  position: 'relative',
  width: 'fit-content',
  height: '10', // match --input-height, defined locally on inputs
  fontSize: 'sm',

  background: 'var(--container-bg)',
  rounded: 'var(--container-radius)',
  cursor: 'pointer',
  userSelect: 'none',

  '&.ValueToggle--sm': {
    height: '8', // match --input-height, defined locally on inputs
    fontSize: 'xs',
  },
};

export const trackSx: SystemStyleObject = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

export const thumbSx: SystemStyleObject = {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  background: 'var(--thumb-bg)',
  borderRadius: 'var(--thumb-radius)',
  transition: '500ms all ease',
};

// options inside __track & __thumb share styles to match visually
export const optionSx: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  flexGrow: '1',
  flexBasis: '0',
  margin: 1,
  paddingX: 2,

  rounded: 'var(--thumb-radius)',
  color: 'gray.500',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  userSelect: 'none',
  opacity: 1,
  transition: 'opacity 500ms ease',

  '.ValueToggle__thumb &': {
    position: 'relative',
    left: 0,

    bgColor: 'white',
    color: 'bodyText',
    boxShadow: 'md',
  },

  '[data-selected=right] &.left': {
    opacity: 0,
  },
  '[data-selected=left] &.right': {
    opacity: 0,
  },
};
