import { type List, fromJS } from 'immutable';

import { TreePicker, type TreePickerProps } from 'components/widgets';
import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from 'entities/api/Person/GrcOrganisationalUnit';
import type { Optional } from 'types';
import { createLogger } from 'util/createLogger';

import { ListComponent } from './components/List';
import { Record } from './components/Record';
import { Wrapper } from './components/Wrapper';
import { raoPickerSx } from './RAOPicker.styles';

const log = createLogger('RAOPicker');

const grcBaseParams = fromJS({
  is_active: 'true',
  is_location: 'false',
});

const getLabel = (s: GrcOrganisationalUnitEntityRecord) => s?.get('name');

/** types are not strictly accurate, so we can't use `value.includes(item)` */
const isSelectedCallback = (
  item: GrcOrganisationalUnitEntityRecord,
  value: List<GrcOrganisationalUnitEntityRecord>,
) => value.map((rec) => rec.get('uuid')).includes(item.get('uuid'));

type RAOPickerProps = Optional<
  TreePickerProps<GrcOrganisationalUnitEntityRecord>,
  'Entity' | 'baseParams'
>;

/**
 * Renders GRCOrganisationalUnits; composes TreePicker.
 */
// TODO render initial selection
export const RAOPicker = ({
  Entity = GrcOrganisationalUnitEntity,
  baseParams = grcBaseParams,
  ...rest
}: RAOPickerProps) => {
  log('%o', { Entity, baseParams, ...rest });

  return (
    <TreePicker<GrcOrganisationalUnitEntityRecord>
      wrapperComponent={Wrapper}
      listComponent={ListComponent}
      recordComponent={Record}
      getLabel={getLabel}
      isSelectedCallback={isSelectedCallback}
      sx={raoPickerSx}
      allowToggle
      {...{ Entity, baseParams, ...rest }}
    />
  );
};
