import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types.ts';

import EquipmentEntity, { type EquipmentEntityRecord } from './Equipment';
import I18nTextEntity, { toString } from './i18n/I18nText';
import OperatingContextEntity, {
  type OperatingContextEntityRecord,
} from './OperatingContext';
import RiskEntity, { type RiskEntityRecord } from './Risk';
import ScenarioEntity, { type ScenarioEntityRecord } from './Scenario';
import ScenarioModifierEntity, {
  type ScenarioModifierEntityRecord,
} from './ScenarioModifier.ts';
import SiteControlEntity, {
  type SiteControlEntityRecord,
} from './SiteControl.ts';
import { type TitleEntityFields } from './types.ts';
import WorkLocationEntity, {
  type WorkLocationEntityRecord,
} from './WorkLocation.ts';

class SiteFrameworkEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_framework/',
  };

  static fields: EntityFields<SiteFrameworkEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    existing_identifier: new Fields.CharField({ blank: true }),
    operating_contexts: new Fields.EntityField({
      entity: OperatingContextEntity,
      many: true,
    }),
    controls: new Fields.EntityField({
      entity: SiteControlEntity,
      many: true,
    }),
    equipment: new Fields.EntityField({
      entity: EquipmentEntity,
      many: true,
    }),

    scenarios: new Fields.EntityField({
      entity: ScenarioEntity,
      many: true,
    }),

    scenario_modifiers: new Fields.EntityField({
      entity: ScenarioModifierEntity,
      many: true,
    }),

    work_locations: new Fields.EntityField({
      entity: WorkLocationEntity,
      many: true,
    }),

    risk: new Fields.EntityField({
      entity: RiskEntity,
    }),
    identifier: new Fields.CharField(),
    count_cc: new Fields.IntegerField(),
    count_ccc: new Fields.IntegerField(),
    count_interruptions: new Fields.IntegerField(),
    status: new Fields.CharField(),
    status_es: new Fields.CharField(),

    // TODO https://burnsred.atlassian.net/browse/BC-303 add nested Person serializer
    administrators: new Fields.CharField({ many: true }),
  };

  // SiteFrameworkEntity.toString(record)
  static toString = toString<SiteFrameworkEntityRecord>;
}

type SiteFrameworkEntityFields = TitleEntityFields & {
  operating_contexts: List<OperatingContextEntityRecord>;
  equipment: List<EquipmentEntityRecord>;
  scenarios: List<ScenarioEntityRecord>;
  controls: List<SiteControlEntityRecord>;
  scenario_modifiers: List<ScenarioModifierEntityRecord>;
  work_locations: List<WorkLocationEntityRecord>;
  risk: RiskEntityRecord;
  count_cc: number;
  count_ccc: number;
  count_interruptions: number;
  identifier: string;
  existing_identifier: string;
  status: string;
  status_es: string;
  administrators: string[];
};
export type SiteFrameworkEntityRecord = EntityRecord<SiteFrameworkEntityFields>;

SiteFrameworkEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteFrameworkEntity,
  name: 'SiteFramework',
});

export default SiteFrameworkEntity;
