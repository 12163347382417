import { useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router';

import PersonEntity, { useCurrentUser } from 'entities/api/Person/Person';
import GlobalVisualisationFilterEntity, {
  type GlobalVisualisationFilterEntityRecord,
} from 'entities/filter/GlobalVisualisationFilter';
import { useEntityFilterControls } from 'forms/hooks/filters';
import { useLocale } from 'locales/useLocale';

import { type GlobalFrameworkContext } from '../GlobalFrameworks';

export function useControlVisualisationControls() {
  const { framework } = useOutletContext<GlobalFrameworkContext>();
  const { toString } = useLocale();
  const { name, filterRecord, onChange, FilterField, errors } =
    useEntityFilterControls<
      typeof GlobalVisualisationFilterEntity,
      GlobalVisualisationFilterEntityRecord
    >(
      GlobalVisualisationFilterEntity,
      'GlobalDashboardFilters',
      GlobalVisualisationFilterEntity.onChangeClean,
    );
  const riskId = useMemo(() => {
    return framework?.get('risk')?.get('uuid') ?? '';
  }, [framework]);
  const params = useMemo(() => {
    return GlobalVisualisationFilterEntity.asMappedParams(filterRecord).set(
      'applicable_rules__risk',
      riskId,
    );
  }, [filterRecord, riskId]);
  // // Clear the filters if the framework change
  useEffect(() => {
    if (riskId) {
      onChange({
        target: {
          name: name,
          value: GlobalVisualisationFilterEntity.dataToRecord({}),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskId]);
  const user = useCurrentUser();

  return {
    toString,
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    params,
    framework,
    riskId,
    isPublicUser: PersonEntity.isPublicUser(user),
  };
}
