import { type ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';

export const fullHeightTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    display: 'flex',
    flexDir: 'column',
    width: 'full',
    minHeight: '100vh',
    marginX: 'auto',
  },

  variants: {
    /** mimics the behaviour of a Container */
    container: ({ size }) => ({
      // Define styles for variant + size combination · Discussion #4492 · chakra-ui/chakra-ui
      // https://github.com/chakra-ui/chakra-ui/discussions/4492
      maxWidth:
        size === 'sm'
          ? 'container.sm'
          : size === 'md'
            ? 'container.md'
            : size === 'lg'
              ? 'container.lg'
              : size === 'xl'
                ? 'container.xl'
                : undefined,
    }),

    colourful: ({ colorMode }) => ({
      bg: colorMode === 'dark' ? 'green.700' : 'green.100',
      color: colorMode === 'dark' ? 'white' : 'gray.800',
    }),

    // Extending Variants: https://codesandbox.io/s/chakra-ui-extend-variant-button-group-394cnf
    // [chakra-ui/discussions](https://github.com/chakra-ui/chakra-ui/discussions/2974#discussioncomment-379564)
    fullwidthColourful: (props) => ({
      ...props.theme.components.FullHeight.variants.fullwidth, // variant is an obj!
      ...props.theme.components.FullHeight.variants.colourful(props), // variant is a fn!
    }),
  },
});
