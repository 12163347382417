import {
  Button,
  Heading,
  type SystemStyleObject,
  Text,
} from '@chakra-ui/react';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteError } from 'react-router-dom';

import { RouterLink } from '@burnsred/ui-chakra';

import { Pre, VStack } from '..';

const preSx: SystemStyleObject = {
  p: '4',
  bg: 'red.200',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
};

/**
 * Styled generic ErrorBoundary fallback.
 *
 * ```tsx
 * import { ErrorBoundary } from "react-error-boundary";
 *
 * <ErrorBoundary fallback={ErrorBoundaryFallback}>
 *   <BuggyComponent />
 * </ErrorBoundary>
 * ```
 *
 * @see https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * @see https://github.com/bvaughn/react-error-boundary
 */

/**
 * A reusable ErrorLayout component to display error messages.
 * Accepts custom heading and body as props.
 */
export function ErrorLayout({
  heading,
  body,
  error,
}: {
  heading: ReactElement;
  body: ReactElement;
  error?: Error;
}) {
  return (
    <VStack gap={6}>
      <Heading>{heading}</Heading>

      <VStack gap={4}>
        {error?.message && <Text color="error">{error.message}</Text>}
        <Text>{body}</Text>
      </VStack>

      <Button
        as={RouterLink}
        to="/"
        variant="primary"
        size="lg"
        alignSelf="flex-start"
      >
        <FormattedMessage
          id="ErrorBoundaryFallback.back-to-cube"
          defaultMessage="Back to Cube"
        />
      </Button>

      {!import.meta.env.PROD && error?.stack && (
        // don't show the stacktrace in prod
        <Pre sx={preSx}>{error?.stack}</Pre>
      )}
    </VStack>
  );
}

/**
 * ErrorBoundaryFallback now uses the ErrorLayout and can be customized
 * by passing different headings and body messages.
 */
export function ErrorBoundaryFallback({ error }: { error: Error }) {
  return (
    <ErrorLayout
      heading={
        <FormattedMessage
          id="ErrorBoundaryFallback.heading"
          defaultMessage="Oops, something went wrong."
        />
      }
      body={
        <FormattedMessage
          id="ErrorBoundaryFallback.contact-support"
          defaultMessage="We encountered an error while trying to display this page. Please try again or contact your support desk."
        />
      }
      error={error}
    />
  );
}

/**
 * Produces friendly, standard error handling for react-router routes.
 *
 * As this is handling a native Error, Sentry will log it automatically if
 * Sentry is configured with `CaptureConsole`:
 *
 * ```js
 * Sentry.init({
 *   // ...
 *   integrations: [
 *     captureConsoleIntegration({ levels: ['error', 'assert'] }),
 *   ],
 * });
 * ```
 *
 * > By default React logs all errors to the console, even if you are using a
 * > React error boundary. If you are using the CaptureConsole integration this
 * > means that Sentry will capture the error through the CaptureConsole
 * > integration and not through the error boundary.
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/captureconsole/
 *
 * Displays a stack trace in development.
 *
 * ```tsx
 * const routes = [
 *   {
 *     path: '/',
 *     element: <MainLayout />,
 *     errorElement: (
 *       // 🔵 render branded UI if the error occurs high in the routing tree
 *       <AuthLayout>
 *         <RouterErrorBoundary />
 *       </AuthLayout>
 *     ),
 *     children: [
 *       {
 *         path: 'sub-path',
 *         element: <ChildElement />,
 *         // 🔵 renders inside the Outlet of MainLayout
 *         errorElement: <RouterErrorBoundary />,
 *       }
 *     ],
 *   }
 * ]
 * ```
 *
 * @see https://reactrouter.com/en/6.15.0/route/error-element
 */
export function RouterErrorBoundary({
  fallback,
}: {
  fallback?: typeof ErrorBoundaryFallback;
}) {
  const error = useRouteError() as unknown as Error;
  const Component = fallback ?? ErrorBoundaryFallback;
  return <Component error={error} />;
}
