import { defineMessages } from '@formatjs/intl';

// https://formatjs.io/docs/react-intl/api#formatmessage
export const documentTitles = defineMessages({
  'title.fallback': {
    id: 'title.fallback',
    // workaround: can't use a variable applicationTitle here; have applicationTitle read this value instead
    // during `npm run locale:compile`:
    // > Error validating message "undefined" with ID "title.fallback" in file "src/locales/extracted/messages.json"
    defaultMessage: 'BHP Cube',
  },

  // dashboards
  'title.global-performance': {
    id: 'title.global-performance.performance',
    defaultMessage: 'Global Performance',
  },
  'title.my-control-frameworks': {
    id: 'title.my-control-frameworks',
    defaultMessage: 'My control frameworks',
  },
  'title.my-controls': {
    id: 'title.my-controls',
    defaultMessage: 'My Controls',
  },

  // global-frameworks
  'title.global-frameworks.architectures': {
    id: 'title.global-frameworks.architectures',
    defaultMessage: 'Global control frameworks',
  },
  'title.global-frameworks.architecture': {
    id: 'title.global-frameworks.architecture',
    defaultMessage: 'Global control frameworks',
  },
  'title.global-frameworks.details': {
    id: 'title.global-frameworks.details',
    defaultMessage: 'Global control framework: Details',
  },
  'title.global-frameworks.scenarios': {
    id: 'title.global-frameworks.scenarios',
    defaultMessage: 'Global control framework: Scenarios',
  },
  'title.global-frameworks.controls': {
    id: 'title.global-frameworks.controls',
    defaultMessage: 'Global control framework: Controls',
  },

  'title.global-control.settings': {
    id: 'title.global-control.settings',
    defaultMessage: 'Global control: Settings',
  },
  'title.global-control.rules': {
    id: 'title.global-control.rules',
    defaultMessage: 'Global control: Rules',
  },
  'title.global-control.ccc': {
    id: 'title.global-control.ccc',
    defaultMessage: 'Global control: CCC',
  },
  'title.global-control.fcc': {
    id: 'title.global-control.fcc',
    defaultMessage: 'Global control: FCC',
  },
  'title.global-control.cco': {
    id: 'title.global-control.cco',
    defaultMessage: 'Global control: CCO',
  },
  'title.global-control.la': {
    id: 'title.global-control.la',
    defaultMessage: 'Global control: LA',
  },
  'title.global-control.documentation': {
    id: 'title.global-control.documentation',
    defaultMessage: 'Global control: Documentation',
  },

  // control-frameworks
  'title.local-frameworks': {
    id: 'title.local-frameworks',
    defaultMessage: 'Local frameworks',
  },
  'title.local-framework': {
    id: 'title.local-framework',
    defaultMessage: 'Local framework',
  },

  'title.local-framework.risk': {
    id: 'title.local-framework.risk',
    defaultMessage: 'Local framework: Risk',
  },
  'title.local-framework.controls': {
    id: 'title.local-framework.controls',
    defaultMessage: 'Local framework: Controls',
  },
  'title.local-framework.localisation': {
    id: 'title.local-framework.localisation',
    defaultMessage: 'Local framework: Localisation',
  },

  'title.local-framework.control.detail': {
    id: 'title.local-framework.control.detail',
    defaultMessage: 'Local framework: Control',
  },
  'title.local-framework.control.settings': {
    id: 'title.local-framework.control.settings',
    defaultMessage: 'Local framework: Control Settings',
  },
  'title.local-framework.control.rules': {
    id: 'title.local-framework.control.rules',
    defaultMessage: 'Local framework: Control Rules',
  },
  'title.local-framework.control.ccc': {
    id: 'title.local-framework.control.ccc',
    defaultMessage: 'Local framework: Control CCC',
  },
  'title.local-framework.control.fcc': {
    id: 'title.local-framework.control.fcc',
    defaultMessage: 'Local framework: Control FCC',
  },
  'title.local-framework.control.cco': {
    id: 'title.local-framework.control.cco',
    defaultMessage: 'Local framework: Control CCO',
  },
  'title.local-framework.control.la': {
    id: 'title.local-framework.control.la',
    defaultMessage: 'Local framework: Control LA',
  },
  'title.local-framework.control.implementation': {
    id: 'title.local-framework.control.implementation',
    defaultMessage: 'Local framework: Control Implementation',
  },
  'title.local-framework.control.documentation': {
    id: 'title.local-framework.control.documentation',
    defaultMessage: 'Local framework: Control Documentation',
  },
});
