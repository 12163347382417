import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import {
  type ComponentStyleConfig,
  Icon,
  type SystemStyleObject,
  type UseCheckboxProps,
} from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import type { IconType } from 'react-icons';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdOutlineIndeterminateCheckBox,
} from 'react-icons/md';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

/**
 * Checkbox is a MultiPart Component
 *
 * @see https://chakra-ui.com/docs/components/checkbox/usage
 * @see https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/checkbox.ts
 * @see https://github.com/chakra-ui/chakra-ui/blob/v2/packages/components/src/checkbox/checkbox.tsx
 */
export const checkboxTheme: ComponentStyleConfig = defineMultiStyleConfig({
  /** setting size on Checkbox controls fontSize of the label */
  sizes: {
    sm: {
      control: {
        borderWidth: 1,
      },
      label: {
        fontSize: '12px', // prefer tokens
      },
    },
    md: {
      control: {
        borderWidth: 2,
      },
      label: {
        fontSize: '14px', // prefer tokens
      },
    },
    // lg: {},
  },

  variants: {
    // so we can deselect 'inverted' in storybook
    none: {},

    cube: {
      /** label */
      container: {},
      /** span wraps the icon */
      control: {
        borderColor: 'gray.500',
        _disabled: {
          borderColor: 'gray.500',
          bg: 'transparent',
          color: 'inherit',
        },

        _hover: {
          bg: 'transparent',
          color: 'primary',
          borderColor: 'primary',
          _disabled: { borderColor: 'gray.500' },
        },

        // prettier-ignore
        [
          '&:indeterminate, &[aria-checked=mixed], &[data-indeterminate], ' +
          '&[aria-checked=true], &[data-checked]'
        ]: {
          bg: 'transparent',
          color: 'primary',
          borderColor: 'primary',

          _hover: {
            bg: 'primary',
            color: 'white',
            borderColor: 'primary',
          },
        },
      },
      label: {
        color: 'bodyText',
      },
    },

    /** @see CustomIcon */
    customIcon: {
      control: {
        bg: 'transparent',
        border: 'none',

        // prettier-ignore
        [
          '&:indeterminate, &[aria-checked=mixed], &[data-indeterminate], ' +
          '&[aria-checked=true], &[data-checked]'
        ]: {
          bg: 'transparent',
          border: 'none',

          _hover: {
            bg: 'transparent',
            border: 'none',
          },
        },
      },
    },
  },

  defaultProps: {
    variant: 'cube',
  },
});

export type CustomIconProps = UseCheckboxProps & {
  iconSx?: SystemStyleObject;
  CheckedIcon: IconType;
  UncheckedIcon: IconType;
  IndeterminateIcon?: IconType;
};

/**
 * Safely render custom icons from react-icons into a Checkbox
 * (filters out non-HTML props from Checkbox).
 *
 * ```tsx
 * <Checkbox
 *   name="foo"
 *   value="bar"
 *   variant="customIcon"
 *   icon={
 *     <CustomIcon
 *       CheckedIcon={MdCheckBox}
 *       UncheckedIcon={MdCheckBoxOutlineBlank}
 *     />
 *   }
 * />
 * ```
 *
 * Note: the Checkbox props`iconColor` and `iconSize` don't work for react-icons;
 * pass `iconSx` instead.
 *
 * @see https://v2.chakra-ui.com/docs/components/checkbox/usage#icon
 */
export function CustomIcon(props: CustomIconProps) {
  const {
    isIndeterminate,
    isChecked,
    iconSx = {},
    CheckedIcon = MdCheckBox,
    UncheckedIcon = MdCheckBoxOutlineBlank,
    IndeterminateIcon = MdOutlineIndeterminateCheckBox,
  } = props;

  return (
    <Icon
      as={
        isIndeterminate
          ? IndeterminateIcon
          : isChecked
            ? CheckedIcon
            : UncheckedIcon
      }
      sx={iconSx}
    />
  );
}
