import { defineStyleConfig } from '@chakra-ui/styled-system';

/**
 * Menu is a multipart component
 *
 * Note that our Styleguide requires changes which can't be accommodated via
 * variants alone (`<MenuButton as={Button} rightIcon={...} />`),
 * so ensure that you're importing from components/atoms/Menu:
 *
 * ```tsx
 * // associated Props are exported, too
 * import { Menu, MenuButton, MenuItem, MenuList } from '@burnsred/ui-chakra';
 * ```
 *
 * @see https://chakra-ui.com/docs/components/menu/theming
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/menu.ts
 */
export const menuTheme = defineStyleConfig({
  baseStyle: {
    button: {},
    list: {
      minWidth: 'auto',
    },
    // item: {},
    // groupTitle: {},
    // command: {},
    // divider: {},
  },

  // defaultProps: {},
  // variants: {},
  // sizes: {},
});
