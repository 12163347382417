import { GridItem, type GridItemProps } from '@chakra-ui/react';

export const DEFAULT_COLSPAN = { base: 12 };

/**
 * Render children in a GridItem, aligned to the end so they fit in with the
 * surrounding fields.
 *
 * ```tsx
 * <FieldsetItem>
 *   <Button>Submit</Button>
 * </FieldsetItem>
 * ```
 *
 * @default colSpan { base: 12 }
 */
export const FieldsetItem = ({ children, ...props }: GridItemProps) => {
  return (
    <GridItem
      display="flex"
      alignItems="flex-end"
      colSpan={DEFAULT_COLSPAN}
      {...props}
    >
      {children}
    </GridItem>
  );
};
