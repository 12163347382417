import type { SystemStyleObject } from '@chakra-ui/react';

export const timezoneWrapperSx: SystemStyleObject = {
  gap: 1.5,
};

export const timeZoneLabelSx: SystemStyleObject = {
  width: 32,
  height: 8,

  '&.readonly:not(&.active)': {
    bgColor: 'timezoneEmpty',
  },
};

export const checkboxSx: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  width: 'full',
  padding: 1,
  paddingRight: 1.5,

  _disabled: {
    cursor: 'auto',
  },

  '.chakra-checkbox__control': {
    rounded: 'full', // make the :focus boxShadow round

    // design calls for the icon to be displayed only if checked
    opacity: 0,
    _checked: { opacity: 1 },

    _focus: {
      // FIXME focus ring is not visible for unchecked items
      // modified 'outline' with 4px spread
      boxShadow: '0 0 0 4px rgba(66, 153, 225, 0.6)',
    },

    '.readonly & ': {
      display: 'none',
    },

    _disabled: {
      color: 'white',
      bgColor: 'transparent',
    },
  },

  '.chakra-checkbox__label': {
    order: -1,
    height: 'full',
    margin: 0,

    // force wrapping of 'Event *' labels only
    maxWidth: '4.5rem',
    fontSize: 'xs',
    lineHeight: 'none',
    fontWeight: 'bold',
    color: 'white',

    '&[data-disabled]': {
      opacity: 1,
      color: 'white',

      '.readonly:not(.active) &': {
        color: 'gray.300',
      },
    },
  },
};

export const checkboxIconSx: SystemStyleObject = {
  width: 6,
  height: 6,
};
