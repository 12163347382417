import { FormControl, FormLabel, GridItem, SimpleGrid } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import type { Field } from '@burnsred/entity';
import { Form, Input } from '@burnsred/entity-form';
import Autocomplete from 'components/widgets/Autocomplete/Autocomplete';
import { type RiskOwnerDashboardFilterEntityRecord } from 'entities/filter/RiskOwnerFilter';
import { type EntityFormProps } from 'types';

type DashboardTopFilterProps = {
  name: string;
  filterRecord: RiskOwnerDashboardFilterEntityRecord;
  onChange: EntityFormProps['onChange'];
  errors: EntityFormProps['errors'];
  FilterField: Field;
};

export const DashboardBottomFilters = (props: DashboardTopFilterProps) => {
  const { name, filterRecord, onChange, FilterField, errors } = props;
  return (
    <Form
      name={name}
      field={FilterField}
      value={filterRecord}
      errors={errors}
      onChange={onChange}
      onSubmit={onChange}
    >
      <SimpleGrid columns={{ base: 12 }} spacing="5">
        <GridItem colSpan={4}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="dashboard.control-frameworks.filters2.damage_mechanism"
                defaultMessage="Damaging energy mechanism"
              />
            </FormLabel>
            <Input
              name="scenarios__damage_energy_mechanism"
              component={Autocomplete}
              componentProps={{
                onChange,
              }}
              filterParams={{
                damage_energy__risk:
                  filterRecord?.get('risk')?.get('uuid') || undefined,
                page_size: '200',
              }}
              loadOptionsFromAPI
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={4}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="dashboard.control-frameworks.filters2.scenario"
                defaultMessage="Scenario"
              />
            </FormLabel>
            <Input
              name="scenarios"
              component={Autocomplete}
              componentProps={{
                onChange,
              }}
              loadOptionsFromAPI
              filterParams={{
                damage_energy_mechanism__damage_energy__risk:
                  filterRecord?.get('risk')?.get('uuid') || undefined,
                damage_energy_mechanism:
                  filterRecord
                    ?.get('scenarios__damage_energy_mechanism')
                    ?.get('uuid') || undefined,
                page_size: '200',
              }}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={4}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="dashboard.control-frameworks.filters2.control"
                defaultMessage="Control"
              />
            </FormLabel>
            <Input
              name="controls__siteframeworkcontrol__control"
              component={Autocomplete}
              componentProps={{ onChange }}
              loadOptionsFromAPI
              filterParams={{ page_size: '200' }}
            />
          </FormControl>
        </GridItem>
      </SimpleGrid>
    </Form>
  );
};
