import { semanticColors } from './semanticColors';

/**
 * The keys of semanticTokens mirrors the keys of the theme itself
 *
 * Theme tokens inside semanticToken definitions will be resolved:
 *
 * ```ts
 * space: { gutter: 6 } // '6' resolves to '1.5rem'
 * ```
 *
 * @see https://chakra-ui.com/docs/styled-system/semantic-tokens
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/react/src/theme/src/theme.types.ts#L123
 */
export const semanticTokens = {
  colors: semanticColors,

  borders: {
    // photoFrameExample: "8px groove grey",
  },

  /**
   * ```
   * height: 'navColumnWidth', // wherever sizes can be used
   * height: 'calc(var(--chakra-sizes-desktopNavHeight))', // other contexts
   * ```
   */
  sizes: {
    // see also `<Container variant="desktopLayout" />`
    // this is the target element width from the design (without gutters)
    default: 'xl',
    desktopNavHeight: '60px',
    navColumnWidth: '180px',
  },

  space: {
    gutter: '5',
  },
};
