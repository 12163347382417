import { fromJS } from 'immutable';
import moment, { type Moment } from 'moment';
import { defineMessage } from 'react-intl';

export type PeriodOption = {
  label: ReturnType<typeof defineMessage>;
  value: Moment;
};
export const LAST_SEVEN_DAYS = {
  label: defineMessage({
    id: 'period-option.seven-days',
    defaultMessage: 'Last 7 days',
  }),
  value: moment().subtract({ days: 7 }).startOf('day'),
};
export const LAST_FOURTEEN_DAYS = {
  label: defineMessage({
    id: 'period-option.fourteen-days',
    defaultMessage: 'Last 14 days',
  }),
  value: moment().subtract({ days: 14 }).startOf('day'),
};
export const LAST_THIRTY_DAYS = {
  label: defineMessage({
    id: 'period-option.thirty-days',
    defaultMessage: 'Last 30 days',
  }),
  value: moment().subtract({ days: 30 }).startOf('day'),
};

export const PERIOD_OPTIONS = [
  LAST_SEVEN_DAYS,
  LAST_FOURTEEN_DAYS,
  LAST_THIRTY_DAYS,
];

export const IMMUTABLE_PERIOD_OPTIONS = fromJS(PERIOD_OPTIONS);
