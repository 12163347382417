/**
 * > Chakra UI supports responsive styles out of the box. Instead of manually
 * > adding @media queries and adding nested styles throughout your code, Chakra
 * > UI allows you to provide object and array values to add mobile-first
 * > responsive styles.
 * > @see https://chakra-ui.com/docs/styled-system/responsive-styles
 *
 * Eg, these are equivalent:
 * ```jsx
 * // array notation
 * <Box fontWeight={[300, 400, 500]} />
 *
 * // object notation
 * <Box fontWeight={{ base: 300, sm: 400, md: 500 }} />
 * ```
 *
 * You can use style tokens from `sizing` such as 'max', 'min', 'full', 'sm', etc:
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/react/src/theme/tokens/sizes.ts
 *
 * Defaults: https://github.com/chakra-ui/chakra-ui/blob/main/packages/react/src/theme/breakpoints.ts
 *
 * > Note: If you're using pixels as breakpoint values make sure to always
 * > provide a value for the 2xl breakpoint, which by its default pixels value
 * > is "1536px".
 */
export const breakpoints = {
  sm: '30em', // 480
  md: '48em', // 768
  lg: '62em', // 992
  xl: '90em', // 1440 MacBook Pro, Sacha's default design width
  '2xl': '96em', // 1536
  hd: '120em', // 1920

  // see semantic tokens for our defaults, so related values can get co-located
};

/**
 * Media Queries for use in SX props
 *
 * ## Group responsive styles
 *
 * The chakra inline array and object syntaxes are highly convenient for
 * lightweight use, but become unreadable when you have many styles to override.
 * In such cases, prefer:
 *
 * ```ts
 * const responsiveExampleSx: SystemStyleObject = {
 *   // base (mobile first) styles here
 *   fontWeight: 300,
 *
 *   // grouped overrides using the breakpoints defined in the theme:
 *   [mediaQueries.md]: {
 *     fontWeight: 500,
 *   },
 * }
 * ```
 */
export const mediaQueries = {
  sm: `@media screen and (min-width: ${breakpoints.sm})`,
  md: `@media screen and (min-width: ${breakpoints.md})`,
  lg: `@media screen and (min-width: ${breakpoints.lg})`,
  xl: `@media screen and (min-width: ${breakpoints.xl})`,
  '2xl': `@media screen and (min-width: ${breakpoints['2xl']})`,
};

/**
 * BR addition: these breakpoints are considered 'mobile',
 * in that they hide the desktop navigation and display mobile nav
 *
 * (See also useIsMobile)
 */
export const mobileBreakpoints = ['base', 'sm', 'md', 'lg'] as const;
