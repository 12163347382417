import {
  Input as ChakraInput,
  type InputProps as ChakraInputProps,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';

import type { InputProps } from '@burnsred/entity-form';
import { filterProps } from '@burnsred/higher-order-component';

export type InputWidgetProps = InputProps & ChakraInputProps;

/**
 * Renders a Chakra Input, complete with FormControl & FormErrorMessage
 *
 * @see RawInput for an Input which renders only the Input
 */
export const Input = (props: InputWidgetProps) => {
  const errors = props?.errors?.join('. ');

  return (
    <FormControl isInvalid={!!errors} isDisabled={props.disabled}>
      <ChakraInput {...filterProps<ChakraInputProps>(props)} />
      <FormErrorMessage>{errors}</FormErrorMessage>
    </FormControl>
  );
};
