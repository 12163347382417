import type { List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import { toString } from './i18n/I18nText';
import MPTTAncestorEntity, {
  type MPTTAncestorEntityRecord,
} from './MPTTAncestor';

/**
 * Use this as a base class for our MPTT-powered models (eg GRCOrgUnit, Equipment)
 *
 * > MPTT is a technique for storing hierarchical data in a database. The aim is
 * > to make retrieval operations very efficient.
 *
 * @see https://django-mptt.readthedocs.io/en/latest/
 */
class MPTTEntity extends Entity {
  static fields: EntityFields<MPTTEntityFields> = {
    uuid: new Fields.IdField(),

    //@fixme Not supposed to have two id fields I don't think
    parent: new Fields.IdField(),

    has_children: new Fields.BooleanField(),

    ancestors: new Fields.EntityField({
      entity: MPTTAncestorEntity,
      many: true,
    }),
  };

  static toString = toString<MPTTEntityRecord>;

  static hasChildren = (record: MPTTEntityRecord) => record.get('has_children');
}

export type MPTTEntityFields = {
  uuid: string;
  parent: string;
  has_children: boolean;
  ancestors: List<MPTTAncestorEntityRecord>;
};

export type MPTTEntityRecord = EntityRecord<MPTTEntityFields>;

MPTTEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: MPTTEntity,
  name: 'MPTT',
});

export default MPTTEntity;
