import { Heading } from '@chakra-ui/react';
import { type List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Loading } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { ControlGrid } from 'components/ControlGrid/ControlGrid';
import type { RiskEntityRecord } from 'entities/api/Risk';
import type { SupportFactorEntityRecord } from 'entities/api/SupportFactor';
import type {
  BaseControlEntityFields,
  TitleEntityFields,
} from 'entities/api/types';
import type { EntityRecord, RequireOnly, WithQueryReturnProps } from 'types';

import { useControlVisualisationControls } from './ControlsVisualisation.hooks';
import { cardLikeSx } from './ControlsVisualisation.styles';
import { ControlsVisualisationFilters } from './ControlVisualizationFilterForm/ControlsVisualisationFilters';
import {
  controlHrefCb,
  frameworkHrefCb,
} from '../GlobalFramework/GlobalFramework';

export type CommonFrameworkEntityRecord = EntityRecord<{
  risk: RiskEntityRecord;
}>;

/** Satisfies both ControlEntityRecord & SiteControlEntityRecord */
export type CommonControlEntityRecord = EntityRecord<
  TitleEntityFields &
    BaseControlEntityFields & {
      support_factors: List<SupportFactorEntityRecord>;
    }
>;

export type ControlsVisualisationProps = RequireOnly<
  WithQueryReturnProps<List<CommonControlEntityRecord>>,
  'value'
> & {
  framework: CommonFrameworkEntityRecord;
};

export const ControlsVisualisation = () => {
  const {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    toString,
    framework,
    params,
    riskId,
    isPublicUser,
  } = useControlVisualisationControls();
  return (
    <VStack gap={6}>
      <VStack layerStyle="cardLike" sx={cardLikeSx}>
        <Heading size="sm">{toString(framework?.get('risk'))}</Heading>

        <ControlsVisualisationFilters
          frameworkHref={frameworkHrefCb(framework.get('uuid'))}
          name={name}
          filterRecord={filterRecord}
          onChange={onChange}
          FilterField={FilterField}
          errors={errors}
          riskId={riskId}
          framework={framework}
          isPublicUser={isPublicUser}
        />
      </VStack>

      <VStack gap={4}>
        <Heading as="h3" size="sm">
          <FormattedMessage
            id="ControlsVisualisation.sub-heading"
            defaultMessage="Controls"
          />
        </Heading>

        {framework ? (
          <ControlGrid
            framework={framework}
            params={params}
            controlHrefCb={(controlUuid: string) =>
              controlHrefCb(framework?.get('uuid'), controlUuid)
            }
            isPublicUser={isPublicUser}
          />
        ) : (
          <Loading />
        )}
      </VStack>
    </VStack>
  );
};

export default ControlsVisualisation;
