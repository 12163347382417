import { FormControl, FormLabel } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import type { Field } from '@burnsred/entity';
import { Form, Input } from '@burnsred/entity-form';
import { PeriodSelect } from 'components/widgets/PeriodSelect/PeriodSelect';
import { type ControlDashboardFilterEntityRecord } from 'entities/filter/ControlDashboardFilter';
import { PERIOD_OPTIONS } from 'entities/filter/periodOptions';
import { type EntityFormProps } from 'types';

type ControlDashboardFilterProps = {
  name: string;
  filterRecord: ControlDashboardFilterEntityRecord;
  onChange: EntityFormProps['onChange'];
  errors: EntityFormProps['errors'];
  FilterField: Field;
};

export const ControlDashboardFilters = (props: ControlDashboardFilterProps) => {
  const { name, filterRecord, onChange, FilterField, errors } = props;
  return (
    <Form
      name={name}
      field={FilterField}
      value={filterRecord}
      errors={errors}
      onChange={onChange}
      onSubmit={onChange}
    >
      <FormControl>
        <FormLabel>
          <FormattedMessage
            id="dashboard.my-controls.filters.period"
            defaultMessage="Period"
          />
        </FormLabel>
        <Input
          name="with_statistics__gte"
          component={PeriodSelect}
          componentProps={{
            onChange,
            options: PERIOD_OPTIONS,
          }}
        />
      </FormControl>
    </Form>
  );
};
