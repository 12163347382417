import { Box, Checkbox, HStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import useThreeStateCheckboxControls from 'components/widgets/ThreeStateCheckbox/ThreeStateCheckbox.hooks';
import { type ControlDashboardFilterEntityFields } from 'entities/filter/ControlDashboardFilter';
import { type EntityDuckAction, type FormChangeEvent } from 'types';

import {
  checkboxSx,
  iconSx,
  legendHstackSx,
} from './CriticalControlLegend.styles';

type CriticalControlLegendProps = {
  onChange: (event: FormChangeEvent) => EntityDuckAction;
  value: ControlDashboardFilterEntityFields['is_critical'];
};

export const CriticalControlLegend = ({
  onChange,
  value,
}: CriticalControlLegendProps) => {
  const { lhs, setLhs, rhs, setRhs } = useThreeStateCheckboxControls({
    name: 'is_critical',
    onChange: onChange,
    value: value,
    reverse: false,
  });

  return (
    <HStack sx={legendHstackSx}>
      <Checkbox
        name="critical"
        isChecked={lhs}
        onChange={(e) => {
          setLhs(e.target.checked);
        }}
        isDisabled={!rhs}
        sx={checkboxSx}
      >
        <Box sx={iconSx.critical} />
        <FormattedMessage
          id="CriticalControlLegend.label.critical-control"
          defaultMessage="Critical control"
        />
      </Checkbox>

      <Checkbox
        name="nonCritical"
        isChecked={rhs}
        onChange={(e) => {
          setRhs(e.target.checked);
        }}
        isDisabled={!lhs}
        sx={checkboxSx}
      >
        <Box sx={iconSx.nonCritical} />
        <FormattedMessage
          id="CriticalControlLegend.label.control"
          defaultMessage="Control"
        />
      </Checkbox>
    </HStack>
  );
};
