import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  type UseDisclosureReturn,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { AppVersion } from 'components/atoms';

import { drawerBodySx, navToggleInnerSx } from './MobileNavDrawer.styles';
import { NavLinks } from '../NavLinks';

import { MobileNavToggle } from '.';

type MobileNavigationProps = {
  disclosure: UseDisclosureReturn;
};

export const MobileNavDrawer = ({ disclosure }: MobileNavigationProps) => {
  const { formatMessage } = useIntl();
  const ariaLabel = formatMessage({
    id: 'ui.open-navigation',
    defaultMessage: 'Open navigation',
  });

  return (
    <Flex>
      <MobileNavToggle disclosure={disclosure} ariaLabel={ariaLabel} />

      <Drawer
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        placement="right"
        size="sm"
      >
        <DrawerOverlay />

        <DrawerContent>
          <Flex sx={navToggleInnerSx}>
            <MobileNavToggle disclosure={disclosure} ariaLabel={ariaLabel} />
          </Flex>

          <DrawerBody sx={drawerBodySx}>
            <NavLinks />
          </DrawerBody>

          <DrawerFooter sx={{ justifyContent: 'flex-start' }}>
            <AppVersion />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
