import type { SystemStyleObject } from '@chakra-ui/react';

export const drawerBodySx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8',
  padding: '8',
};

/** ensure that the inner close button overlays the outer */
export const navToggleInnerSx: SystemStyleObject = {
  position: 'absolute',
  right: 0,
  top: 0,
  height: 'desktopNavHeight',
  alignItems: 'center',
};
