/**
 * > allows you to define theme-aware styles for any elements
 *
 * @see https://chakra-ui.com/docs/styled-system/global-styles
 */
export const globalStyles = {
  // ensure that the #root mounting div from CRA stretches to the height of the
  // viewport to facilitate sticky-footer layouts
  '#root': {
    minHeight: '100vh',
  },

  // '.debug': {
  //   outline: '1px solid red'
  // }
};
