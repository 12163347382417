import { type ComponentStyleConfig, defineStyle } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  color: 'gray.500',

  '.chakra-form__required-indicator': {
    marginLeft: '0.5em',
  },
});

const sizes = defineStyle({
  sm: {
    fontSize: '2xs',
  },
  md: {
    fontSize: 'sm',
  },
  lg: {
    fontSize: 'md',
  },
});

/**
 * FormLabel (related to FormControl)
 *
 * @see https://v2.chakra-ui.com/docs/components/form-control/usage
 * @see https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/form-label.ts
 */
export const formLabelTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
  },
});
