import { FormControl, FormLabel, GridItem, SimpleGrid } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import type { Field } from '@burnsred/entity';
import { Form, Input } from '@burnsred/entity-form';
import Autocomplete from 'components/widgets/Autocomplete/Autocomplete';
import { PeriodSelect } from 'components/widgets/PeriodSelect/PeriodSelect';
import { type GlobalDashboardFilterEntityRecord } from 'entities/filter/GlobalDashboardFilter';
import { PERIOD_OPTIONS } from 'entities/filter/periodOptions';
import { type EntityFormProps } from 'types';

type GlobalDashboardFilterProps = {
  name: string;
  filterRecord: GlobalDashboardFilterEntityRecord;
  onChange: EntityFormProps['onChange'];
  errors: EntityFormProps['errors'];
  FilterField: Field;
};

export const GlobalDashboardFilters = (props: GlobalDashboardFilterProps) => {
  const { name, filterRecord, onChange, FilterField, errors } = props;
  return (
    <Form
      name={name}
      field={FilterField}
      value={filterRecord}
      errors={errors}
      onChange={onChange}
      onSubmit={onChange}
    >
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="5">
        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.global-performance.filters.risk"
              defaultMessage="Level 3 Risk"
            />
          </FormLabel>
          <Input
            name="risk"
            component={Autocomplete}
            componentProps={{
              onChange: onChange,
            }}
            loadOptionsFromAPI
          />
        </FormControl>

        <GridItem colSpan={2} />

        <GridItem>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="dashboard.global-performance.filters.period"
                defaultMessage="Period"
              />
            </FormLabel>

            <Input
              name="with_statistics__gte"
              component={PeriodSelect}
              componentProps={{
                onChange,
                options: PERIOD_OPTIONS,
              }}
            />
          </FormControl>
        </GridItem>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.global-performance.filters.damage_mechanism"
              defaultMessage="Damaging energy mechanism"
            />
          </FormLabel>
          <Input
            name="scenarios__damage_energy_mechanism"
            component={Autocomplete}
            componentProps={{
              onChange,
            }}
            filterParams={{
              damage_energy__risk:
                filterRecord?.get('risk')?.get('uuid') || undefined,
              page_size: '200',
            }}
            loadOptionsFromAPI
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.global-performance.filters.timezone"
              defaultMessage="Event Phase"
            />
          </FormLabel>
          <Input name="timezone" component={Autocomplete} loadOptionsFromAPI />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.global-performance.filters.asset"
              defaultMessage="Asset"
            />
          </FormLabel>
          <Input
            name="descendants"
            component={Autocomplete}
            componentProps={{
              onChange: onChange,
            }}
            loadOptionsFromAPI
            filterParams={{
              is_location: 'false',
              is_active: 'true',
              level: '1',
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.global-performance.filters.operation"
              defaultMessage="Operation"
            />
          </FormLabel>
          <Input
            name="grc_organisational_unit"
            component={Autocomplete}
            componentProps={{
              onChange,
              disabled: !filterRecord?.get('descendants'),
            }}
            loadOptionsFromAPI
            filterParams={{
              is_location: 'false',
              is_active: 'true',
              level: '2',
              parent:
                filterRecord?.get('descendants')?.get('uuid') || undefined,
            }}
          />
        </FormControl>

        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="dashboard.global-performance.filters.scenario"
                defaultMessage="Scenario"
              />
            </FormLabel>
            <Input
              name="scenarios"
              component={Autocomplete}
              componentProps={{
                onChange,
              }}
              loadOptionsFromAPI
              filterParams={{
                damage_energy_mechanism__damage_energy__risk:
                  filterRecord?.get('risk')?.get('uuid') || undefined,
                damage_energy_mechanism:
                  filterRecord
                    ?.get('scenarios__damage_energy_mechanism')
                    ?.get('uuid') || undefined,
                page_size: '200',
              }}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="dashboard.global-performance.filters.control"
                defaultMessage="Global control"
              />
            </FormLabel>
            <Input
              name="controls__siteframeworkcontrol__control"
              component={Autocomplete}
              componentProps={{ onChange }}
              loadOptionsFromAPI
              filterParams={{ page_size: '200' }}
            />
          </FormControl>
        </GridItem>
      </SimpleGrid>
    </Form>
  );
};
