import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { type ComponentStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

/**
 * TODO create a wrapper component that allows us to default the icon to an "up/down chevron"
 * _like_: https://react-icons.github.io/react-icons/search?q=tbselector
 *
 * ```tsx
 * // https://chakra-ui.com/docs/components/select/usage#changing-the-icon
 * <Select icon={<TbSelector />} />
 * ```
 *
 * @see https://chakra-ui.com/docs/components/select
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/select.ts
 */
export const selectTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: {
    field: {
      minHeight: '36px',
    },
  },

  /**
   * `borderRadius` must be set for each size (baseStyle)
   * chakra-ui/packages/components/theme/src/components/input.ts:27
   * Discussion: https://github.com/chakra-ui/chakra-ui/discussions/4173
   */
  sizes: {
    xs: { field: { borderRadius: 'sm' } },
    sm: { field: { borderRadius: 'sm' } },
    md: { field: { borderRadius: 'sm' } },
    lg: { field: { borderRadius: 'sm' } },
  },

  variants: {
    /**
     * Input BG colour must be set at the variant level, rather than baseStyle:
     * https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%402.4.2/packages/components/theme/src/components/input.ts#L115
     * https://stackoverflow.com/a/74885378/2586761
     */
    filled: (props) => ({
      // default values commented for context
      field: {
        color: 'bodyText',
        bg: mode('white', 'whiteAlpha.800')(props), // FIXME prefer semantic colour
        borderColor: 'gray.300', // FIXME prefer semantic colour
        borderWidth: '1px', // FIXME prefer token

        _hover: {
          bg: mode('white', 'whiteAlpha.800')(props),
        },
        _readOnly: {
          // boxShadow: 'none !important',
          // userSelect: 'all',
        },
        _invalid: {
          borderColor: 'borderError',
        },
        _focusVisible: {
          borderColor: 'borderFocus',
          bg: mode('white', 'whiteAlpha.800')(props),
        },
      },
    }),
  },

  defaultProps: {
    variant: 'filled',
    size: 'sm',
  },
});
