import {
  Card,
  CardBody,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage, defineMessage } from 'react-intl';

import { RouterLink } from '@burnsred/ui-chakra';
import { icons } from 'assets/icons';
import { createLogger } from 'util/createLogger';

import { cardSx } from './ControlCardAdd.styles';

const _log = createLogger('ControlCardAdd');

type ControlAddCardProps = {
  text?: ReturnType<typeof defineMessage>;
};

export const ControlCardAdd = ({ text }: ControlAddCardProps) => {
  return (
    <Card as={LinkBox} size="sm" sx={cardSx}>
      <LinkOverlay as={RouterLink} to="new">
        <CardBody>
          <Icon
            as={icons.MdOutlineAddCircleOutline}
            boxSize="12"
            color="gray.300"
          />
          <Text fontSize="sm">
            <FormattedMessage
              {...(text ??
                defineMessage({
                  id: 'ControlCardAdd.default.create-local-control',
                  defaultMessage: 'Create a local control',
                }))}
            />
          </Text>
        </CardBody>
      </LinkOverlay>
    </Card>
  );
};
