import { Box } from '@chakra-ui/react';
import { type List } from 'immutable';

import { VStack } from 'components';
import I18nTextEntity from 'entities/api/i18n/I18nText';
import { type TitleEntityFields } from 'entities/api/types';
import { type EntityRecord } from 'types';

import { itemSx } from './FlatListItems.styles';

export type FlatListItemProps<T extends EntityRecord<TitleEntityFields>> = {
  records: List<T>;
  emptyText: string;
  locale: string;
};

export function FlatListItems<T extends EntityRecord<TitleEntityFields>>(
  props: FlatListItemProps<T>,
) {
  const { records, emptyText, locale } = props;
  return (
    <VStack>
      {records.isEmpty() && <span>{emptyText}</span>}
      {!records.isEmpty() &&
        records.map((value) => (
          <Box key={value.get('uuid')} sx={itemSx}>
            {I18nTextEntity.toString(value.get('title'), locale)}
          </Box>
        ))}
    </VStack>
  );
}
