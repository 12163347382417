import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import RulePredicateEntity, {
  type RulePredicateEntityRecord,
} from './RulePredicate';

class SiteApplicableRuleEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_applicable_rule/',
  };

  static fields: EntityFields<SiteApplicableRuleEntityFields> = {
    uuid: new Fields.IdField(),
    predicates: new Fields.EntityField({
      entity: RulePredicateEntity,
      many: true,
    }),
  };
}

type SiteApplicableRuleEntityFields = {
  uuid: string;
  predicates: List<RulePredicateEntityRecord>;
};

export type SiteApplicableRuleEntityRecord =
  EntityRecord<SiteApplicableRuleEntityFields>;

SiteApplicableRuleEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteApplicableRuleEntity,
  name: 'SiteApplicableRule',
});

export default SiteApplicableRuleEntity;
