import { createBrowserHistory } from 'history';
import { Map } from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import { combineReducers } from 'redux-immutable';

import { Entity } from '@burnsred/entity';
import {
  createDuckReducerFromRequires,
  duckMiddleware,
} from '@burnsred/entity-duck';
import { axiosInstance } from 'api';
import * as entities from 'entities';
import { settings } from 'settings';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type EntitiesModules = typeof import('entities');

const requirements = Object.values(entities as EntitiesModules).filter(
  (e) => e.prototype instanceof Entity,
);

const rootReducerMap = createDuckReducerFromRequires(
  requirements,
  combineReducers,
);

const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
        // https://github.com/reduxjs/redux-devtools/blob/main/extension/docs/API/Arguments.md
      })
    : compose;

export const history = createBrowserHistory();

const store = createStore(
  combineReducers({
    // router: connectRouter(history),
    ...rootReducerMap,
  }),
  Map(), // preloadedState
  composeEnhancers(
    applyMiddleware(
      duckMiddleware({ settings, client: axiosInstance }),
      // routerMiddleware(history),
    ),
  ),
);

export default store;
