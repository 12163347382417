import { Box, HStack, SimpleGrid, VisuallyHidden } from '@chakra-ui/react';
import { List } from 'immutable';
import { Fragment, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useQuery } from '@burnsred/entity-duck-query';
import { Loading } from '@burnsred/ui-chakra';
import { Tooltip, VStack } from 'components/atoms';
import { EntriesFound } from 'components/EntriesFound';
import ControlEntity from 'entities/api/Control';
import TimeZoneEntity, {
  type TimeZoneEntityRecord,
} from 'entities/api/TimeZone';
import { useLocale } from 'locales/useLocale';
import type {
  CommonControlEntityRecord,
  CommonFrameworkEntityRecord,
} from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation';
import type { useControlVisualisationControls } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';

import { ControlGridCell } from './components/ControlGridCell';
import { ControlGridLegend } from './components/ControlGridLegend';
import { ControlPreview } from './components/ControlPreview';
import { EmptyControlGridCell } from './components/EmptyControlGridCell';
import {
  RowOfEmptyCells,
  controlGridHeaderSx,
  controlGridSectionSx,
  headerSx,
  loadingWrapperSx,
} from './ControlGrid.styles';
import {
  type EmptyCell,
  hasControl,
  parseRows,
  toEnString,
} from './ControlGrid.util';

/** Display 6 blank regions until timezones resolves */
export const timezonesLoadingFallback = [...Array(6)];

type ControlGridProps = {
  framework: CommonFrameworkEntityRecord;
  controlHrefCb: (controlUuid: string) => string;
  isPublicUser: boolean;
  params: ReturnType<typeof useControlVisualisationControls>['params'];
};

export const ControlGrid = ({
  framework,
  params,
  controlHrefCb,
  isPublicUser,
}: ControlGridProps) => {
  const { toString } = useLocale();
  const [expandedControlUuid, setExpandedControl] = useState<string | null>(
    null,
  );
  const [hoveredControl, setHoveredControl] = useState<string | null>(null);

  // FIXME BC-42 error handling
  const { value: controls, errors: _ } = useQuery<
    List<CommonControlEntityRecord>
  >({
    action: ControlEntity.duck.actions.get({ params }),
  });
  const isLoaded = typeof controls != 'undefined';

  const timezones = useSelector<unknown, List<TimeZoneEntityRecord>>((state) =>
    TimeZoneEntity.duck.selectors.record(state),
  );

  const timezoneKeys = useMemo(
    () =>
      timezones?.size
        ? timezones.map((tz) => toEnString(tz.get('title'))).toJS()
        : [],
    [timezones],
  );

  const rows = useMemo(
    () =>
      controls?.size && timezoneKeys.length
        ? parseRows(controls, timezoneKeys)
        : (List() as List<List<EmptyCell>>),
    [controls, timezoneKeys],
  );

  const counts: Record<string, number> = useMemo(() => {
    const _counts = {
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
    };
    controls?.forEach((control) =>
      control.get('timezones').forEach((tz) => {
        const key = String(tz.get('order')) as keyof typeof _counts;
        _counts[key] = _counts[key] + 1;
      }),
    );
    return _counts;
  }, [controls]);

  return (
    <>
      <HStack justifyContent="space-between">
        <EntriesFound count={controls?.size} />

        <ControlGridLegend />
      </HStack>

      <VStack
        as="section"
        className="ControlGrid"
        gap={6}
        sx={controlGridSectionSx}
      >
        <SimpleGrid
          as="header"
          columns={6}
          columnGap={6}
          sx={controlGridHeaderSx}
        >
          {(timezones ?? timezonesLoadingFallback).map((timezone, i) => (
            <Tooltip key={i} label={toString(timezone, 'description')}>
              <Box
                sx={{
                  ...headerSx,
                  bgColor: timezone?.get('color'),
                }}
              >
                <span id={`timezone-heading-${i}`} role="term">
                  {toString(timezone)}
                </span>

                <VisuallyHidden
                  role="definition"
                  aria-labelledby={`timezone-heading-${i}`}
                >
                  {toString(timezone, 'description')}
                </VisuallyHidden>

                {timezone ? (
                  <span>
                    <VisuallyHidden>
                      <FormattedMessage
                        id="ControlGrid.a11y.counts"
                        defaultMessage="Controls active during phase: "
                      />
                    </VisuallyHidden>
                    {counts[timezone?.get('order')]}
                  </span>
                ) : null}
              </Box>
            </Tooltip>
          ))}
        </SimpleGrid>

        <VStack className="ControlGrid__grid-wrapper" gap={1} role="list">
          {isLoaded ? (
            rows.size ? (
              rows.map((row, i) => {
                const expandedControl = row.find(
                  (r) => r?.control?.get('uuid') == expandedControlUuid,
                )?.control;

                return (
                  <SimpleGrid
                    key={i}
                    className="ControlGrid__row"
                    columns={6}
                    columnGap={6}
                    rowGap={1}
                  >
                    <Fragment>
                      {row?.map((cell, i) => {
                        const uuid = cell.control?.get('uuid') as string;

                        return hasControl(cell) ? (
                          <ControlGridCell
                            key={`${i}_${uuid}`}
                            {...cell}
                            onClick={() =>
                              setExpandedControl(
                                expandedControlUuid == uuid ? null : uuid,
                              )
                            }
                            isExpanded={expandedControlUuid == uuid}
                            onMouseOver={() => setHoveredControl(uuid)}
                            onMouseOut={() => setHoveredControl(null)}
                            isHovered={hoveredControl == uuid}
                          />
                        ) : (
                          <EmptyControlGridCell
                            key={i}
                            colStart={cell.colStart}
                            colEnd={cell.colEnd}
                          />
                        );
                      })}

                      {!!expandedControl && (
                        <ControlPreview
                          framework={framework}
                          control={expandedControl}
                          setExpandedControl={setExpandedControl}
                          controlHref={controlHrefCb(
                            expandedControlUuid as string,
                          )}
                          isPublicUser={isPublicUser}
                        />
                      )}
                    </Fragment>
                  </SimpleGrid>
                );
              })
            ) : (
              <RowOfEmptyCells />
            )
          ) : (
            <VStack sx={loadingWrapperSx}>
              <Loading />
            </VStack>
          )}
        </VStack>
      </VStack>
    </>
  );
};
