import type { SystemStyleObject } from '@chakra-ui/react';

export const cardSx: SystemStyleObject = {
  padding: 0,

  '.chakra-card__header': {
    padding: 3,
    borderBottom: '1px',
    borderColor: 'gray.200',
  },
  '.chakra-card__body': {
    padding: 3,
    paddingBottom: 0,
    color: 'muted',
  },
  '.chakra-card__footer': {
    padding: 3,
  },
};

export const cardHeaderSx: SystemStyleObject = {
  display: 'flex',
  gap: 2,
  alignItems: 'center',
};

export const riskIconSx: SystemStyleObject = {
  boxSize: 5,
  marginY: -5, // prevent expanding parent
};

export const cardBodySx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export const damagingEnergyHeaderSx: SystemStyleObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'muted',
};

export const headingSx: SystemStyleObject = {
  color: 'bodyText',
  paddingBottom: 2,

  span: {
    color: 'muted',
  },
};

export const miniStatCard: SystemStyleObject = {
  padding: 1,
  bgColor: 'gray.100',
  boxShadow: 'none',
  rounded: 'base',

  dt: {
    fontSize: 'xs',
    color: 'primary',
    fontWeight: 'bold',
  },

  dd: {
    fontWeight: 'normal',
  },
  '&.has-interrupts': {
    bgColor: 'error',
    dt: { color: 'white' },
    dd: { color: 'white' },
  },
};

export const adminListItemSx: SystemStyleObject = {
  display: 'inline',
  fontSize: 'xs',
};
