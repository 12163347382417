import { PreJson } from '@burnsred/ui-chakra';
import type { BasicEntityRecord } from 'types';

import type { SimpleEntityListRecordComponentProps } from './SimpleEntityList';

/**
 * Serves as an example for
 *
 * ```tsx
 * type MyEntity = Map<'foo', string> & BasicEntityRecord;
 *
 * export const TestRecordComponent = (
 *   props: SimpleEntityListRecordComponentProps<MyEntity>,
 * ) => {
 *   return <div>{props.record.get('foo')}</div>;
 * };
 * ```
 */
export const DefaultRecordComponent = <
  T extends BasicEntityRecord = BasicEntityRecord,
>(
  props: SimpleEntityListRecordComponentProps<T>,
) => {
  return <PreJson data={props.record} />;
};
