type Enum = { [key: string]: string };

/**
 * Takes an enum and returns an array of options for a Select component
 *
 * ```tsx
 * const MyEnum = { foo: 'Foo' };
 * <Select
 *   name="mySelect"
 *   options={toOptions(MyEnum)}
 * />
 * ```
 */
export const toOptions = (e: Enum) =>
  Object.entries(e).map(([value, label]) => ({ value, label }));
