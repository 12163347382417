import {
  Box,
  type BoxProps,
  Checkbox,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import type { List } from 'immutable';
import { noop } from 'lodash';
import type { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

import { icons } from 'assets/icons';
import type { MPTTEntityRecord } from 'entities/api/MPTT';

/** purely presentational; no special props here */
export type TreePickerWrapperProps = BoxProps;
export const DefaultWrapper = (props: TreePickerWrapperProps) => (
  <Box {...props} />
);

export type TreePickerListProps<T extends MPTTEntityRecord = MPTTEntityRecord> =
  Omit<BoxProps, 'role'> & {
    items: List<T>;
    level: number;
    role: 'list';
    className?: string;
    debug?: boolean;
  };
export const DefaultList = ({
  children, // this template renders children...
  items: _, //... but a custom template will likely process items instead
  className,
  role,
  debug,
}: TreePickerListProps) => (
  <Box {...{ className, role, debug }}>{children}</Box>
);

export type TreePickerRecordProps = Omit<BoxProps, 'role'> & {
  role: 'listitem';
  item: MPTTEntityRecord;
  level: number;
  className: string;
  debug?: boolean;
  onClick: MouseEventHandler;
  'data-test-id': string;
  hasChildren: boolean;
  isOpen: boolean;
  isSelected: boolean;
  isIndeterminate: boolean;
  count: number;
  isDisabled?: boolean;
};
export const DefaultRecord = ({
  children,
  item,
  hasChildren: _,
  isOpen,
  isSelected,
  level: _l,
  onClick,
  isDisabled,
  isIndeterminate: _isIndeterminate,
  count,
  ...rest
}: TreePickerRecordProps) => {
  return (
    <Flex
      // handle the event here because the Checkbox.onChange
      // doesn't distinguish between event.target & event.currentTarget
      onClick={onClick}
      {...rest}
    >
      <Checkbox
        variant="none"
        value={item.get('uuid') as string}
        isChecked={isSelected}
        onChange={noop}
        isDisabled={isDisabled}
        // isIndeterminate={isIndeterminate}
      >
        <span className="TreePicker__Node__label">{children}</span>

        {count && <span className="TreePicker__Node__count">{count}</span>}

        {isOpen && (
          <Icon
            className="TreePicker__Node__expanded-icon"
            as={icons.MdOutlineChevronRight}
          />
        )}
      </Checkbox>
    </Flex>
  );
};

export const DefaultEmptyRecord = () => (
  <Text className="TreePicker__DefaultEmptyRecord">
    <FormattedMessage id="TreePicker.no-results" defaultMessage="No results" />
  </Text>
);
