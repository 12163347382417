import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types.ts';

import BaseQuestion, { type BaseQuestionEntityFields } from './BaseQuestion.ts';

class SiteCCFVQuestionEntity extends BaseQuestion {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_ccfv_question/',
  };

  static fields = BaseQuestion.fields;
}

type SiteCCFVQuestionEntityFields = BaseQuestionEntityFields;

export type SiteCCFVQuestionEntityRecord =
  EntityRecord<SiteCCFVQuestionEntityFields>;

SiteCCFVQuestionEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteCCFVQuestionEntity,
  name: 'SiteCCFVQuestion',
});

export class SiteControlCCFVQuestionEntity extends Entity {
  static fields: EntityFields<SiteControlCCFVQuestionFields> = {
    uuid: new Fields.IdField(),
    site_question: new Fields.EntityField({
      entity: SiteCCFVQuestionEntity,
    }),
    order: new Fields.NumberField({
      default: 0,
    }),
  };
}

type SiteControlCCFVQuestionFields = {
  uuid: string;
  site_question: SiteCCFVQuestionEntityRecord;
  order: number;
};

export type SiteControlCCFVQuestionEntityRecord =
  EntityRecord<SiteControlCCFVQuestionFields>;

export default SiteCCFVQuestionEntity;
