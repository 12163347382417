import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

class OrganisationalUnitEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/bhp_people/v1/whoami/',
  };

  static fields: EntityFields<OrganisationalUnitEntityFields> = {
    uuid: new Fields.IdField(),
    name: new Fields.CharField(),
    bhp_object_id: new Fields.CharField(),
    has_children: new Fields.BooleanField(),
    is_active: new Fields.BooleanField(),
    modified: new Fields.DateTimeField(),
  };
}

export type OrganisationalUnitEntityFields = {
  uuid: string;
  name: string;
  bhp_object_id: string;
  has_children: boolean;
  is_active: boolean;
  modified: Date;
};

export type OrganisationalUnitEntityRecord =
  EntityRecord<OrganisationalUnitEntityFields>;

OrganisationalUnitEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: OrganisationalUnitEntity,
  name: 'OrganisationalUnit',
});

export default OrganisationalUnitEntity;
