import {
  Alert,
  AlertDescription,
  AlertTitle,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { type ReactNode } from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';

/**
 * Displays the error message in a format suitable for form fields, without
 * breaking layout:
 * - truncate error.message
 * - display the complete message in a popover
 */
export const ErrorFallback = ({ error }: FallbackProps) => (
  <Alert status="error" variant="subtle">
    <AlertTitle>
      <FormattedMessage id="ErrorFallback" defaultMessage="Error" />
    </AlertTitle>

    {error?.message && (
      <Popover trigger="hover" isLazy strategy="fixed">
        <PopoverTrigger>
          <AlertDescription layerStyle="truncate" cursor="help">
            {error.message}
          </AlertDescription>
        </PopoverTrigger>

        <PopoverContent
          sx={{ whiteSpace: 'break-spaces', fontFamily: 'monospace' }}
        >
          <PopoverArrow />
          <PopoverBody>{error.toString()}</PopoverBody>
        </PopoverContent>
      </Popover>
    )}
  </Alert>
);

export type DefaultErrorBoundaryProps = {
  children: ReactNode;
};

/** @see https://github.com/bvaughn/react-error-boundary */
export function DefaultErrorBoundary({ children }: DefaultErrorBoundaryProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}
