import { FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import type { Field } from '@burnsred/entity';
import { Form, Input } from '@burnsred/entity-form';
import Autocomplete from 'components/widgets/Autocomplete/Autocomplete';
import { PeriodSelect } from 'components/widgets/PeriodSelect/PeriodSelect';
import { PERIOD_OPTIONS } from 'entities/filter/periodOptions';
import { type RiskOwnerDashboardFilterEntityRecord } from 'entities/filter/RiskOwnerFilter';
import { type EntityFormProps } from 'types';
type DashboardTopFilterProps = {
  name: string;
  filterRecord: RiskOwnerDashboardFilterEntityRecord;
  onChange: EntityFormProps['onChange'];
  errors: EntityFormProps['errors'];
  FilterField: Field;
};

export const DashboardTopFilters = (props: DashboardTopFilterProps) => {
  const { name, filterRecord, onChange, FilterField, errors } = props;
  return (
    <Form
      name={name}
      field={FilterField}
      value={filterRecord}
      errors={errors}
      onChange={onChange}
      onSubmit={onChange}
    >
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="5">
        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.control-frameworks.filters.risk"
              defaultMessage="Level 3 Risk"
            />
          </FormLabel>
          <Input
            name="risk"
            component={Autocomplete}
            componentProps={{
              onChange: onChange,
            }}
            loadOptionsFromAPI
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.control-frameworks.filters.asset"
              defaultMessage="Asset"
            />
          </FormLabel>
          <Input
            name="descendants"
            component={Autocomplete}
            componentProps={{
              onChange: onChange,
            }}
            loadOptionsFromAPI
            filterParams={{
              is_location: 'false',
              is_active: 'true',
              level: '1',
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.control-frameworks.filters.operation"
              defaultMessage="Operation"
            />
          </FormLabel>
          <Input
            name="grc_organisational_unit"
            component={Autocomplete}
            componentProps={{
              onChange,
              disabled: !filterRecord?.get('descendants'),
            }}
            loadOptionsFromAPI
            filterParams={{
              is_location: 'false',
              level: '2',
              is_active: 'true',
              parent:
                filterRecord?.get('descendants')?.get('uuid') || undefined,
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="dashboard.control-frameworks.filters.period"
              defaultMessage="Period"
            />
          </FormLabel>
          <Input
            name="with_statistics__gte"
            component={PeriodSelect}
            componentProps={{
              onChange,
              options: PERIOD_OPTIONS,
            }}
          />
        </FormControl>
      </SimpleGrid>
    </Form>
  );
};
