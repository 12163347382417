import type { SystemStyleObject } from '@chakra-ui/react';

export const cardSx: SystemStyleObject = {
  padding: '10px',
  border: '1px',
  borderColor: 'gray.300',
  fontSize: 'sm',
  shadow: 'base',

  '&.critical': {
    // design calls for a different border width here; prefer outline to preserve layout
    outline: '2px solid var(--chakra-colors-primary)',
    outlineOffset: '-2px',
  },

  '.chakra-card__header': {
    paddingX: 0,
    paddingTop: 0,
  },
  '.chakra-card__body': {
    minHeight: '8',
    paddingX: 0,
    paddingY: 1,
    lineHeight: 'short',
  },
  '.chakra-card__footer': {
    flexDirection: 'column',
    padding: 0,
  },

  '&.disabled .chakra-linkbox a': {
    pointerEvents: 'none',
    cursor: 'default',
  },
};

export const cardHeaderSx: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

export const headingSx: SystemStyleObject = {
  marginRight: 'auto',
  fontSize: 'sm',
  fontWeight: 'normal',
};

export const iconSx: SystemStyleObject = {
  boxSize: '4',
  color: 'gray.500',
};

export const controlDesignationSx: SystemStyleObject = {
  color: 'primary',
  fontSize: 'xs',
};
