import type { List } from 'immutable';

import { Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import GrcOrganisationalUnitAncestorEntity, {
  type GrcOrganisationalUnitAncestorEntityRecord,
} from './GrcOrganisationalUnitAncestor';
import MPTTEntity, {
  type MPTTEntityFields,
  type MPTTEntityRecord,
} from '../MPTT';

class GrcOrganisationalUnitEntity extends MPTTEntity {
  static paginated = true;

  static paths = {
    apiBase: '/bhp_people/v2/grc_organisational_unit/',
  };

  static fields: EntityFields<GrcOrganisationalUnitEntityFields> = {
    ...MPTTEntity.fields,

    ancestors: new Fields.EntityField({
      entity: GrcOrganisationalUnitAncestorEntity,
    }),

    name: new Fields.CharField(),
    has_active_locations: new Fields.BooleanField(),
    has_locations: new Fields.BooleanField(),
    modified: new Fields.DateTimeField(),
  };

  static toString(record?: MPTTEntityRecord) {
    return (
      // workaround: clash of record types - name missing from MPTTEntityRecord
      (record as GrcOrganisationalUnitEntityRecord)?.get('name') ?? '(unnamed)'
    );
  }
}

export type GrcOrganisationalUnitEntityFields = Omit<
  MPTTEntityFields,
  'title' | 'ancestors'
> & {
  ancestors: List<GrcOrganisationalUnitAncestorEntityRecord>;
  name: string;
  has_active_locations: boolean;
  has_locations: boolean;
  modified: Date;
};

export type GrcOrganisationalUnitEntityRecord =
  EntityRecord<GrcOrganisationalUnitEntityFields>;

GrcOrganisationalUnitEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: GrcOrganisationalUnitEntity,
  name: 'GrcOrganisationalUnit',
});

export default GrcOrganisationalUnitEntity;
