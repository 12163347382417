import { type ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = {
  // Rather than set a color in here, consider setting semanticColors['chakra-body-text']
};

/**
 * Consider whether the styling we need can be accomplished via
 * theme/foundations/typography.
 *
 * Eg, rather than setting fontSizes in variants,  create `sizes`
 *
 * Note, there is no theme for `Text` defined in the base package - it gets its
 * sizes from
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/react/src/theme/text-styles.ts
 *
 * > The text styles functionality in Chakra makes it easy to repeatably apply a
 * > collection of text properties (like line height and size) to any component
 * > https://chakra-ui.com/docs/styled-system/text-and-layer-styles#text-styles
 *
 * @see https://chakra-ui.com/docs/components/text
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/layout/src/text.tsx
 */
export const textTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,

  /**
   * Rather than creating variants for Text, consider whether a textStyle would be more useful
   *
   * ```jsx
   * <Text textStyle="shared">Shared here</Text>
   * <Box textStyle="shared">...and here</Box>
   * ```
   */
  variants: {
    //
  },

  defaultProps: {
    // size: 'md',
    // colorScheme: 'gray',
  },
});
