import { useIntl } from 'react-intl';

import PersonEntity, { useCurrentUser } from 'entities/api/Person/Person';

import { type NavLinkType } from './NavLinks';

export function useNavLinkComponentControls() {
  const { formatMessage } = useIntl();
  const currentUser = useCurrentUser();
  const adminLinks: NavLinkType[] = [
    {
      to: '/dashboards/global',
      label: formatMessage({
        id: 'ui.navigation.global-performance',
        defaultMessage: 'Global performance',
      }),
    },
    {
      to: '/dashboards/risk',
      label: formatMessage({
        id: 'ui.navigation.my-control-frameworks',
        defaultMessage: 'My control frameworks',
      }),
    },
    {
      to: '/dashboards/control',
      label: formatMessage({
        id: 'ui.navigation.my-controls',
        defaultMessage: 'My controls',
      }),
    },
    {
      to: '/global-frameworks',
      label: formatMessage({
        id: 'ui.navigation.global-frameworks',
        defaultMessage: 'Global frameworks',
      }),
    },
    {
      to: '/control-frameworks',
      label: formatMessage({
        id: 'ui.navigation.local-frameworks',
        defaultMessage: 'Local frameworks',
      }),
    },
  ];

  let navLinks: NavLinkType[] = [
    {
      to: '/global-frameworks',
      label: formatMessage({
        id: 'ui.navigation.global-frameworks',
        defaultMessage: 'Global frameworks',
      }),
    },
  ];
  if (!PersonEntity.isPublicUser(currentUser)) {
    navLinks = adminLinks;
  }

  if (import.meta.env.DEV) navLinks.push({ to: '/dev', label: 'Dev' });

  return {
    navLinks,
  };
}
