import { FormattedMessage } from 'react-intl';

import { ErrorLayout } from 'components/layout';

export function NotAllowed() {
  return (
    <ErrorLayout
      heading={
        <FormattedMessage
          id="NotAllowed.heading"
          defaultMessage="Not Allowed"
        />
      }
      body={
        <FormattedMessage
          id="NotAllowed.no-access"
          defaultMessage="You don't have permission to access this page."
        />
      }
    />
  );
}
