import type { SystemStyleObject } from '@chakra-ui/react';

/**
 * > Layer Styles allow you to save a combination of styling attributes to
 * > re-use in other components. Once created, pass the layerStyle prop to
 * > any component and chakra will resolve the styles accordingly.
 * > ...
 * > Layer Styles will not override variant styling.
 *
 * @see https://chakra-ui.com/docs/styled-system/text-and-layer-styles#layer-styles
 *
 * ```jsx
 * <Box layerStyle="shared">...and here</Box>
 * <Box layerStyle={['shared', 'anotherLayerStyle']}>multiple layerStyles</Box>
 * ```
 */
export const layerStyles: SystemStyleObject = {
  inlineFlexCentered: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  cardLike: {
    flexDirection: 'column',
    gap: 6,
    padding: 'gutter',
    borderWidth: '1px',
    borderColor: 'gray.300',
    rounded: '4px',
    roundedTopLeft: '0',
    backgroundColor: 'white',
    boxShadow: 'sm',
  },

  nakedList: {
    listStyleType: 'none',
    marginInlineStart: 0,
  },

  // useful for making a `GridItem > Button` align with `FormControl > (FormLabel + Input)`
  flexAlignEnd: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  scrollbars: {
    '&::-webkit-scrollbar': {
      width: '2.5',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.300',
    },
  },

  /**
   * REVIEW which do we prefer:
   *
   * ```tsx
   * <Stack layerStyle="stackDefault" {...props} />
   * <Stack spacing="comfortable" {...props} />
   * ```
   */
  stackDefault: {
    gap: 'comfortable',
  },

  /** commonly used for clickable inputAddons */
  hoverPointer: {
    _hover: { cursor: 'pointer' },
  },

  /**
   * Truncate the text, displaying an ellipsis where the content is hidden.
   *
   * Please consider how the truncated text will be made accessible to the user!
   *
   * @see https://css-tricks.com/almanac/properties/l/line-clamp/
   */
  truncate: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};
