import {
  Checkbox,
  CheckboxGroup,
  type CheckboxProps,
  Flex,
  type UseCheckboxGroupProps,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { icons } from 'assets/icons';
import type { TimeZoneEntityRecord } from 'entities/api/TimeZone';
import TimeZoneEntity from 'entities/api/TimeZone';
import { useLocale } from 'locales/useLocale';
import { CustomIcon } from 'theme/components/Checkbox';

import {
  checkboxIconSx,
  checkboxSx,
  timeZoneLabelSx,
  timezoneWrapperSx,
} from './TimeZoneSelector.styles';

export type TimeZoneSelectorProps = {
  timezones: List<TimeZoneEntityRecord>;
  isDisabled?: UseCheckboxGroupProps['isDisabled'];
  isReadOnly?: CheckboxProps['isReadOnly'];
};

/**
 * Control Timezones Selector, for display in two separate formats:
 * selectable and readonly.
 *
 * ```tsx
 * // always displays solid color background,
 * // displays a checked icon when the timezone is active
 * <TimeZoneSelector timezones={...} />
 *
 * // checkboxes are always hidden, and the background is only displayed
 * // in colour if the timezone is active, otherwise grey.
 * <TimeZoneSelector timezones={...} isReadOnly />
 * ```
 */
export const TimeZoneSelector = ({
  timezones: activeTimezones,
  isDisabled,
  isReadOnly,
}: TimeZoneSelectorProps) => {
  const { toString } = useLocale();

  const timezones = useSelector<unknown, List<TimeZoneEntityRecord>>((state) =>
    TimeZoneEntity.duck.selectors.record(state),
  );
  const [selectedTimezones, setSelectedTimezones] = useState<number[]>(
    activeTimezones.map((tz) => tz.get('order')).toArray() || [],
  );

  return (
    <Flex as="ul" layerStyle="nakedList" sx={timezoneWrapperSx}>
      <CheckboxGroup
        value={selectedTimezones}
        onChange={(newValue: number[]) => setSelectedTimezones(newValue)}
        isDisabled={isDisabled || isReadOnly}
      >
        {timezones.map((timezone) => {
          const isActive = selectedTimezones.includes(timezone.get('order'));
          const timeZoneLabel = toString(timezone);
          const timeZoneUuid = timezone.get('uuid');

          return (
            <Flex
              as="li"
              key={timeZoneUuid}
              className={[
                isActive ? 'active' : '',
                isReadOnly ? 'readonly' : '',
              ].join(' ')}
              sx={{
                ...timeZoneLabelSx,
                backgroundColor: timezone.get('color'),
              }}
            >
              <Checkbox
                value={timezone.get('order')}
                variant="customIcon"
                isReadOnly={isReadOnly}
                icon={
                  <CustomIcon
                    CheckedIcon={icons.MdCheckCircleOutline}
                    UncheckedIcon={icons.MdOutlineCircle}
                    iconSx={checkboxIconSx}
                  />
                }
                sx={checkboxSx}
              >
                {timeZoneLabel}
              </Checkbox>
            </Flex>
          );
        })}
      </CheckboxGroup>
    </Flex>
  );
};
