/**
 *  * These are mainly here for reference: prefer adding semanticTokens to
 * `semanticTokens.space`, where referenced theme values will be interpolated.
 *
 * https://chakra-ui.com/docs/styled-system/theme#spacing
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/react/src/theme/src/foundations/spacing.ts
 *
 * (renamed to `space` for consistency)
 *
 * Note: negative spacing is valid; "-4" is interpreted as `calc(var(--chakra-space-4) * -1)`
 */
export const space = {
  // 0: '0rem',       // redundent: '0' is implicitly interpreted as 0px
  px: '1px', // 1px
  0.5: '0.125rem', // 2px
  1: '0.25rem', // 4px
  1.5: '0.375rem', // 6px
  2: '0.5rem', // 8px
  2.5: '0.625rem', // 10px
  3: '0.75rem', // 12px
  3.5: '0.875rem', // 14px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  9: '2.25rem', // 36px
  10: '2.5rem', // 40px
  12: '3rem', // 48px
  14: '3.5rem', // 56px
  16: '4rem', // 64px
  20: '5rem', // 80px
  24: '6rem', // 96px
  28: '7rem', // 112px
  32: '8rem', // 128px
  36: '9rem', // 144px
  40: '10rem', // 160px
  44: '11rem', // 176px
  48: '12rem', // 192px
  52: '13rem', // 208px // custom
  56: '14rem', // 224px
  60: '15rem', // 240px
  64: '16rem', // 256px
  72: '18rem', // 288px
  80: '20rem', // 320px
  96: '24rem', // 384px
};
