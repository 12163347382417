import { useIntl } from 'react-intl';

import { SiteControlC3QuestionEntity } from 'entities/api/Question/SiteC3Question';
import { createLogger } from 'util/createLogger';

import { QuestionsLayout } from './QuestionsLayout';
import type { ControlDetailContextType } from '../ControlFrameworkControlDetail';

const log = createLogger('ControlCCC');

export const ControlCCC = ({
  controlFormControls,
}: ControlDetailContextType) => {
  const { formatMessage } = useIntl();

  return (
    <QuestionsLayout
      heading={formatMessage({
        id: 'ControlCCC.heading.critical-control-check',
        defaultMessage: 'Critical control check',
      })}
      fieldName="sitecontrolc3question_set"
      controlFormControls={controlFormControls}
      QuestionEntity={SiteControlC3QuestionEntity}
      log={log}
      observationType="ControlCCC"
    />
  );
};
