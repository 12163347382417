import { useMemo } from 'react';

import type { PaginatorProps, PaginatorState } from 'components';
import { SiteFrameworkFilterEntity, usePagination } from 'entities';
import SiteFrameworkEntity from 'entities/api/SiteFramework';
import type { SiteFrameworkFilterEntityRecord } from 'entities/filter/SiteFrameworkFilter';
import { useEntityFilterControls } from 'forms/hooks/filters';

export const useControlFrameworkDashboardControls = () => {
  const { name, filterRecord, onChange, FilterField, errors } =
    useEntityFilterControls<
      typeof SiteFrameworkFilterEntity,
      SiteFrameworkFilterEntityRecord
    >(
      SiteFrameworkFilterEntity,
      'SiteFrameworkFilters',
      SiteFrameworkFilterEntity.onChangeClean,
    );

  // memoise params to prevent a duplicate API call triggered by useSelector call
  const params = useMemo(
    () => SiteFrameworkFilterEntity.asMappedParams(filterRecord),
    [filterRecord],
  );

  const pagination = usePagination(SiteFrameworkEntity, params);

  const onChangePagination = ({ page, page_size }: PaginatorState) => {
    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const newRecord = filterRecord.withMutations((rec) => {
      rec.set('page', String(page) || '1');
      rec.set('page_size', String(page_size) || '10');
    });
    onChange({
      target: { name, value: newRecord },
    });
  };

  const paginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('page') ?? '1'),
    page_size: parseInt(filterRecord?.get('page_size') ?? '6'),
    totalItems: pagination?.get('count') ?? 0,
    onChange: onChangePagination,
    pageSizeOptions: [6, 12],
  };

  return {
    name,
    filterRecord,
    params,
    onChange,
    FilterField,
    errors,
    paginatorProps,
  };
};
