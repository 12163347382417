import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import { defineStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  container: {
    // bg: $bg.reference,
    // px: "4",
    // py: "3",
  },
  title: {
    // fontWeight: "bold",
    // lineHeight: "6",
    // marginEnd: "2",
  },
  description: {
    // lineHeight: "6",
  },
  icon: {
    // color: $fg.reference,
    // flexShrink: 0,
    // marginEnd: "3",
    // w: "5",
    // h: "6",
  },
  spinner: {
    // color: $fg.reference,
    // flexShrink: 0,
    // marginEnd: "3",
    // w: "5",
    // h: "5",
  },
});

// overrides the default variant ('subtle')
const variantMuted = definePartsStyle({
  container: {
    background: 'gray.300',
    color: 'gray.500',
  },
});

/**
 * https://chakra-ui.com/docs/components/alert/usage
 * https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/alert.ts
 * https://github.com/chakra-ui/chakra-ui/blob/v2/packages/components/src/alert
 */
export const alertTheme = defineStyleConfig({
  baseStyle,
  variants: {
    muted: variantMuted,
  },
  defaultProps: {
    // our local theme is not aware of the variants defined upstream...
    // @ts-expect-error Type '"solid"' is not assignable to type '"muted"'
    variant: 'solid',
  },
});
