import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';

import { Loading } from '@burnsred/ui-chakra';

import { checkForRedirect, useRequireAuthInterface } from './RequireAuth.hooks';

/**
 * Fetch initial User and Preferences data
 *
 * @see https://github.com/remix-run/react-router/blob/dev/examples/auth/README.md
 */

export const RequireAuth = () => {
  const {
    user,
    pathname,
    isUserLoading,
    isOpen,
    login,
    preferences,
    localeSynced,
  } = useRequireAuthInterface();
  // The redirect is moved to a hook, the if statement prevents the page from loading but allows the render to complete
  // If we redirect during rendering we get react set state during rendering errors.
  if (checkForRedirect(user, pathname, () => true)) {
    return null;
  }

  if (isUserLoading || !user || !preferences || !localeSynced)
    return <Loading />;

  return (
    <>
      <Modal closeOnEsc={false} isOpen={isOpen} onClose={login}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="require-auth.modal.title"
              defaultMessage={'Session Expired'}
            />
          </ModalHeader>

          <ModalBody>
            <FormattedMessage
              id="require-auth.modal.body"
              defaultMessage={'Your session has expired, please login again.'}
            />
          </ModalBody>

          <ModalFooter>
            <Button onClick={login}>
              <FormattedMessage
                id="require-auth.modal.login"
                defaultMessage={'Login'}
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Outlet />
    </>
  );
};
