import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types.ts';

import BaseQuestion, { type BaseQuestionEntityFields } from './BaseQuestion.ts';

class SiteC3QuestionEntity extends BaseQuestion {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_c3_question/',
  };

  static fields = BaseQuestion.fields;
}

type SiteC3QuestionEntityFields = BaseQuestionEntityFields;

export type SiteC3QuestionEntityRecord =
  EntityRecord<SiteC3QuestionEntityFields>;

SiteC3QuestionEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteC3QuestionEntity,
  name: 'SiteC3Question',
});

export class SiteControlC3QuestionEntity extends Entity {
  static fields: EntityFields<SiteControlC3QuestionFields> = {
    uuid: new Fields.IdField(),
    site_question: new Fields.EntityField({
      entity: SiteC3QuestionEntity,
    }),
    order: new Fields.NumberField({
      default: 0,
    }),
  };
}

type SiteControlC3QuestionFields = {
  uuid: string;
  site_question: SiteC3QuestionEntityRecord;
  order: number;
};

export type SiteControlC3QuestionEntityRecord =
  EntityRecord<SiteControlC3QuestionFields>;
export default SiteC3QuestionEntity;
