import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';

class TodoEntity extends Entity {
  static paginated = true;

  static idField = 'uuid';

  static paths = {
    apiBase: 'todos/',
  };

  static fields = {
    uuid: new Fields.IdField(),
    title: new Fields.CharField(),
    description: new Fields.CharField(),
    // done: new Fields.BooleanField(),
    // username: new Fields.IdField(),
    // created: new Fields.DateTimeField(),
    // modified: new Fields.DateTimeField(),
  };
}

TodoEntity.duck = new DjangoRestFramework({
  app: 'Skeleton',
  entity: TodoEntity,
  name: 'Todo',
});

export default TodoEntity;
