import { Entity, Fields } from '@burnsred/entity';
import { type EntityFields, type EntityRecord } from 'types.ts';

import FilerFile, { type FilerFileEntityRecord } from '../FilerFile.ts';
import I18nTextEntity, { type I18nTextEntityRecord } from '../i18n/I18nText.ts';

class QuestionAttachmentEntity extends Entity {
  static fields: EntityFields<QuestionAttachmentEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),
    attachment: new Fields.EntityField({
      entity: FilerFile,
    }),
  };

  static toString(record: QuestionAttachmentEntityRecord, locale = '') {
    return I18nTextEntity.toString(record.get('title'), locale);
  }
}

type QuestionAttachmentEntityFields = {
  uuid: string;
  title: I18nTextEntityRecord;
  attachment: FilerFileEntityRecord;
};

export type QuestionAttachmentEntityRecord =
  EntityRecord<QuestionAttachmentEntityFields>;

export default QuestionAttachmentEntity;
