import { Flex, Heading, SimpleGrid } from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import {
  Paginator,
  type PaginatorState,
  SimpleEntityList,
  VStack,
  paginate,
} from 'components';
import ControlEntity from 'entities/api/Control';
import { useLocale } from 'locales/useLocale';
import { ControlCard } from 'screens/control-frameworks/components/ControlCard';
import { ControlCardAdd } from 'screens/control-frameworks/components/ControlCardAdd';
import { ControlsFilters } from 'screens/control-frameworks/components/ControlsFilters';
import { type SiteFrameworkContext } from 'screens/control-frameworks/ControlFramework';
import { useControlListControls } from 'screens/control-frameworks/controls/ControlFrameworkControlsList.hooks.ts';
import { createLogger } from 'util/createLogger';

const log = createLogger('ControlFrameworkControlsList');

export function ControlFrameworkControlsList() {
  const localeContext = useLocale();
  const { params, paginatorProps, siteFramework, controlCount } =
    useControlListControls();
  log('%o', { siteFramework });

  const [paginatorState, setPaginatorState] = useState<PaginatorState>({
    page: 1,
    page_size: 6,
  });

  const site_controls = siteFramework?.get('controls');

  return (
    <Flex layerStyle="cardLike" gap={8}>
      <ControlsFilters />

      <VStack gap={4}>
        <Heading as="h3" size="xs">
          <FormattedMessage
            id="control-framework.controls.list.mandatory-controls"
            defaultMessage="Mandatory controls"
          />{' '}
          ({controlCount})
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={2}>
          {siteFramework?.get('uuid') && (
            <SimpleEntityList
              Entity={ControlEntity}
              params={params}
              RecordComponent={ControlCard}
              recordComponentProps={{
                localeContext,
                controlType: 'Global',
                linkTarget: '../controls/:controlUuid/preview',
              }}
            />
          )}
        </SimpleGrid>
        {paginatorProps.totalItems > 0 && <Paginator {...paginatorProps} />}
      </VStack>

      <VStack gap={4}>
        <Heading as="h3" size="xs">
          <FormattedMessage
            id="control-framework.controls.list.local-controls"
            defaultMessage="Local controls"
          />{' '}
          ({site_controls?.size ?? 0})
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={2}>
          {paginate(Array.from(site_controls), paginatorState).map(
            (control) => (
              <ControlCard
                key={control.get('uuid')}
                record={control}
                localeContext={localeContext}
                controlType={control.get('configuration')}
                linkTarget="../controls/:controlUuid/"
              />
            ),
          )}
          <ControlCardAdd />
        </SimpleGrid>

        <Paginator
          page={paginatorState.page}
          page_size={paginatorState.page_size}
          totalItems={site_controls.size}
          onChange={setPaginatorState}
          pageSizeOptions={[8, 16]}
        />
      </VStack>

      {/* renders the preview Drawer */}
      <Outlet context={{ siteFramework } satisfies SiteFrameworkContext} />
    </Flex>
  );
}
