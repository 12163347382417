import { SimpleGrid } from '@chakra-ui/react';
import { type Map } from 'immutable';

import { SimpleEntityList } from 'components';
import SiteFrameworkEntity from 'entities/api/SiteFramework';

import { FrameworkCard } from './FrameworkCard';

export type FrameworksListProps = {
  params: Map<string, string>;
  refreshList?: number;
  showStats?: boolean;
};

export const FrameworksList = (props: FrameworksListProps) => {
  const { params, refreshList = 0, showStats = false } = props;
  return (
    <SimpleGrid
      spacing="6"
      templateColumns="repeat(auto-fill, minmax(290px, 1fr))"
    >
      <SimpleEntityList
        Entity={SiteFrameworkEntity}
        RecordComponent={FrameworkCard}
        recordComponentProps={{ showStats: showStats }}
        params={params}
        refreshList={refreshList}
      />
    </SimpleGrid>
  );
};
