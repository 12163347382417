import { Icon, type IconProps, Image } from '@chakra-ui/react';

import { icons } from 'assets/icons';

export const blackFilter = 'grayscale(100%) brightness(0)';
export const greyFilter = 'grayscale(100%) brightness(0.7)';
export const orangeFilter =
  'grayscale(100%) brightness(0.5) sepia(1) saturate(30) hue-rotate(5deg)';

const filters = {
  black: blackFilter,
  grey: greyFilter,
  orange: orangeFilter,
} as const;

type RiskIconProps = Omit<IconProps, 'filter'> & {
  path: string | undefined;
  /** defaults to black */
  filter?: keyof typeof filters;
};

export const RiskIcon = ({ path, filter, sx }: RiskIconProps) => {
  return path ? (
    <Icon
      as={Image}
      src={path}
      crossOrigin="anonymous"
      sx={{
        filter: filters[filter ?? 'black'],
        boxSize: 8,
        ...sx,
      }}
    />
  ) : (
    <Icon
      as={icons.MdWarning}
      sx={{
        color: 'primary',
        boxSize: 8,
        ...sx,
      }}
    />
  );
};
