import { type ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = {
  color: 'bodyText',
  fontWeight: 'bold',
};

const defaultVariant = {
  color: 'link.500',
};

const textVariant = {
  color: 'bodyText',
  fontWeight: 'normal',
};

export const linkTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,

  variants: {
    default: defaultVariant,
    text: textVariant,
  },

  defaultProps: {
    variant: 'default',
  },
});
