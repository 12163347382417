import { type SystemStyleObject, Text } from '@chakra-ui/react';
import { defineMessages, useIntl } from 'react-intl';

const defaultSx: SystemStyleObject = {
  padding: 4,
  fontSize: 'xs',
  color: 'muted',
};

export const messages = defineMessages({
  'AppVersion.version-prefix': {
    id: 'AppVersion.version-prefix',
    defaultMessage: 'Cube build v',
  },
});

/** Displays version from package.json */
export const AppVersion = ({ sx }: { sx?: SystemStyleObject }) => {
  const { formatMessage } = useIntl();
  const versionText = formatMessage(messages['AppVersion.version-prefix']);

  return (
    <Text aria-label="App version" sx={{ ...defaultSx, ...sx }}>
      {`${versionText}${import.meta.env.PACKAGE_VERSION} #${import.meta.env.COMMIT_HASH}`}
    </Text>
  );
};
