import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMatches } from 'react-router-dom';

import { documentTitles } from 'locales/messages';

/**
 * Allows us to type the shape of a route's custom handle properties.
 *
 * ```ts
 * type TitleRoute = RouteWithHandle<{ title: string }>
 *
 * const matches = useMatches();
 * matches.map((match) => void console.log((match as TitleRoute).handle?.title))
 * ```
 */
export type RouteWithHandle<T extends Record<string, unknown>> = Omit<
  ReturnType<typeof useMatches>[number],
  'handle'
> & { handle: T };

export type TitleRoute = RouteWithHandle<{ title?: string }>;

/**
 * Returns the title for the current route defined as `route.handle.title`,
 * falling back to the deepest matching route (nearest defined ancestor),
 * or `undefined`.
 *
 * ```
 * const routes = [
 *   {
 *     path: '/',
 *     element: <div />,
 *     handle: { title: 'Parent' },
 *     children: [
 *       {
 *         // 🔵 defined `handle.title`; returns 'Child'
 *         path: 'child',
 *         handle: { title: 'Child' },
 *       },
 *       {
 *         // 🔵 no `handle.title`; returns 'Parent'
 *         path: 'nameless-child',
 *       },
 *     ]
 *   }
 * ]
 * ```
 *
 * @see https://reactrouter.com/en/main/hooks/use-matches#breadcrumbs
 */
export const useTitle = () => {
  const matches = useMatches();
  const { formatMessage } = useIntl();

  const title = useMemo(
    () =>
      (matches as TitleRoute[])
        // get all routes with a defined handle.title
        // nB: `matches` includes separate entries for trailing slashes, eg:
        // [{ path: 'foo' }, { path: 'foo/' }]
        .filter((match) => Boolean(match.handle?.title))
        .map((match) => {
          let title =
            documentTitles[match.handle.title as keyof typeof documentTitles];
          if (!title) title = documentTitles['title.fallback'];
          return formatMessage(title);
        })
        // get the deepest match / nearest ancestor route
        .pop(),
    [formatMessage, matches],
  );
  return title;
};
