import {
  Box,
  Button,
  HStack,
  Icon,
  Select,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { type IconType } from 'react-icons';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdOutlineExpandMore,
} from 'react-icons/md';
import { FormattedMessage, defineMessage } from 'react-intl';

import {
  buttonSx,
  buttonWrapperSx,
  selectSx,
  selectWrapperSx,
  wrapperSx,
} from './Paginator.styles';

/** Keys correspond params expected by DRF */
export type PaginatorState = {
  page: number;
  page_size: number;
};

/**
 * Use this function to filter the list of items that you want to paginate
 *
 * ```tsx
 * <ul>
 *   {paginate(items, paginatorState).map((i) => (
 *     <li key={i}>{i}</li>
 *   ))}
 * </ul>
 * ```
 */
export function paginate<T>(
  items: T[],
  { page, page_size }: PaginatorState,
): T[] {
  if (page_size >= items.length) return items;

  // currentPage is one-indexed!
  const start = (page - 1) * page_size;
  const end = page * page_size;

  return items.filter((_item, i) => {
    return i >= start && i < end;
  });
}

/**
 * Note: index starting at 1
 * */
function getPageNumbers(
  currentPage: number,
  maxPageLinks: number,
  maxPageNumber: number,
): number[] {
  const maxPages = Math.min(maxPageNumber, maxPageLinks);
  const halfLength = Math.floor(maxPages / 2);
  let start = Math.max(1, currentPage - halfLength);
  let end = Math.min(start + maxPages - 1, maxPageNumber);

  // Adjust the start and end values if necessary to ensure that the array has maxPages elements
  if (end - start + 1 < maxPages) {
    if (start === 1) {
      end = Math.min(maxPageNumber, start + maxPages - 1);
    } else {
      start = Math.max(1, end - maxPages + 1);
    }
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

export const defaultOptionsFormatter = (itemsPerPageOption: number) =>
  `Show ${itemsPerPageOption} per page`;
// `Results per page: ${itemsPerPageOption}`;

export const translatedOptionsFormatter = (itemsPerPageOption: number) => (
  <FormattedMessage
    {...defineMessage({
      id: 'Paginator.optionsFormatter',
      defaultMessage: `Show {itemsPerPageOption} per page`,
    })}
    values={{
      itemsPerPageOption,
    }}
  />
);

export type PaginatorProps = {
  /** Current page; corresponds to DRF filter params */
  page: number;
  /** Items to display per page; corresponds to DRF filter params */
  page_size: number;
  totalItems: number;
  /**
   * Callback fired when a Paginator Button is clicked, or itemsPerPage Selector updates.
   *
   * In the minimal configuration with `useState`, you can simply pass `setPaginatorState`:
   *
   * ```tsx
   *  const [paginatorState, setPaginatorState] = useState<PaginatorState>({
   *   currentPage: 1,
   *   itemsPerPage: 20,
   * });
   *
   * <Paginator ... onChange={setPaginatorState} />
   * ```
   */
  onChange: (obj: PaginatorState) => void;
  /** An array of options to display in a Select, controlling page_size */
  pageSizeOptions?: number[];
  /**
   * The number of pages to display before displaying hiding under "..."
   *
   * FIXME: We're not displaying an un-clickable `<Button>...</Button>` yet,
   * so it's not obvious that eg: only 6 of 20 pages are listed.
   */
  maxPageLinks?: number;
  /** whether to display first/last page links (double arrow) (default: true) */
  displayFirstLastLinks?: boolean;
  /** whether to display next/prev page links (single arrow) (default: true) */
  displayNextPrevLinks?: boolean;
  /**
   * If false, hide pagination when there are fewer than 2 pages of results.
   * Useful if the items are filtered by an external function.
   * (default: true)
   */
  displayAlways?: boolean;
  /**
   * Allows overriding the options displayed in the `itemsPerPage` Select.
   * (default: `Show ${n} per page`)
   */
  optionsFormatter?:
    | typeof defaultOptionsFormatter
    | typeof translatedOptionsFormatter;
  /** react-icons icon displayed as the Select drop-down indicator */
  selectIcon?: IconType;
  /** overrides for Paginator components */
  sxOverrides?: {
    Wrapper?: SystemStyleObject;
    ButtonWrapper?: SystemStyleObject;
    Button?: SystemStyleObject;
    SelectWrapper?: SystemStyleObject;
    Select?: SystemStyleObject;
  };
};

/**
 * Paginator displays clickable links to pages, and a Select to set items per page.
 *
 * Use in conjunction with `paginate`.
 *
 * ```tsx
 *  const [paginatorState, setPaginatorState] = useState<PaginatorState>({
 *   currentPage: 1,
 *   itemsPerPage: 20,
 * });
 *
 * // construct dummy items to paginate through
 * const itemsToPaginate = Array.from({ length: 50 }, (_, i) => i + 1);
 *
 * return (
 *   <>
 *     <ul>
 *       {paginate(itemsToPaginate, paginatorState).map((i) => (
 *         <li key={i}>{i}</li>
 *       ))}
 *     </ul>
 *
 *     <Paginator
 *       currentPage={paginatorState.currentPage}
 *       itemsPerPage={paginatorState.itemsPerPage}
 *       totalItems={items.length}
 *       onChange={setPaginatorState}
 *     />
 *   </>
 * ```
 */
export const Paginator = ({
  totalItems,
  page,
  onChange,
  page_size,
  pageSizeOptions = [30, 60, 90],
  maxPageLinks = 6,
  displayNextPrevLinks = true,
  displayFirstLastLinks = true,
  displayAlways = true,
  selectIcon = MdOutlineExpandMore,
  optionsFormatter = translatedOptionsFormatter,
  sxOverrides = {},
}: PaginatorProps) => {
  const maxPageNumber = Math.ceil(totalItems / page_size);
  const pageNumbers: number[] = getPageNumbers(
    page,
    maxPageLinks,
    maxPageNumber,
  );

  // only show first/last double-arrow links if first/last page numbers are not be visible
  const isPagesOverflow = totalItems >= maxPageLinks;

  if (!displayAlways && pageNumbers.length < 2) return null;

  return (
    <HStack as="nav" sx={{ ...wrapperSx, ...sxOverrides?.Wrapper }}>
      <Box sx={{ ...buttonWrapperSx, ...sxOverrides?.ButtonWrapper }}>
        {isPagesOverflow && displayFirstLastLinks && (
          <Button
            variant="ghost"
            isDisabled={page == (1 | 0)}
            onClick={() => onChange({ page: 1, page_size })}
            sx={{ ...buttonSx, ...sxOverrides?.Button }}
          >
            <MdKeyboardDoubleArrowLeft />
          </Button>
        )}

        {displayNextPrevLinks && (
          <Button
            variant="ghost"
            isDisabled={page == (1 | 0)}
            onClick={() => onChange({ page: --page, page_size })}
            sx={{ ...buttonSx, ...sxOverrides?.Button }}
          >
            <MdKeyboardArrowLeft />
          </Button>
        )}

        {pageNumbers.map((pageNumber) => (
          <Button
            variant="ghost"
            borderRadius={[]}
            key={pageNumber}
            onClick={() => onChange({ page: pageNumber, page_size })}
            isActive={page === pageNumber}
            isDisabled={maxPageNumber === 1}
            sx={{ ...buttonSx, ...sxOverrides?.Button }}
          >
            {pageNumber}
          </Button>
        ))}

        {displayNextPrevLinks && (
          <Button
            variant="ghost"
            fontSize={8}
            isDisabled={page === maxPageNumber}
            onClick={() => onChange({ page: ++page, page_size })}
            sx={{ ...buttonSx, ...sxOverrides?.Button }}
          >
            <MdKeyboardArrowRight />
          </Button>
        )}

        {isPagesOverflow && displayFirstLastLinks && (
          <Button
            variant="ghost"
            fontSize={8}
            isDisabled={page === maxPageNumber}
            onClick={() => onChange({ page: maxPageNumber, page_size })}
            sx={{ ...buttonSx, ...sxOverrides?.Button }}
          >
            <MdKeyboardDoubleArrowRight />
          </Button>
        )}
      </Box>

      <Box sx={{ ...selectWrapperSx, ...sxOverrides?.SelectWrapper }}>
        <Select
          value={page_size}
          variant="unstyled"
          icon={<Icon as={selectIcon} />}
          sx={{ ...selectSx, ...sxOverrides?.Select }}
          onChange={(evt) =>
            onChange({ page: 1, page_size: parseInt(evt.target.value) })
          }
        >
          {pageSizeOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {optionsFormatter(pageSize)}
            </option>
          ))}
        </Select>
      </Box>
    </HStack>
  );
};
