import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const borderedModalVariant = definePartsStyle({
  header: {
    borderBottomStyle: 'solid',
    borderBottomColor: 'border',
    borderBottomWidth: '1px',
  },
  footer: {
    borderTopStyle: 'solid',
    borderTopColor: 'border',
    borderTopWidth: '1px',
  },
});

const xl = definePartsStyle({
  closeButton: {
    margin: 4,
  },
  body: {
    paddingX: 8,
    paddingTop: 5,
    paddingBottom: 8,
  },
  header: {
    paddingX: 8,
    paddingY: 6,
  },
  footer: {
    paddingX: 8,
  },
});

const baseStyle = definePartsStyle({
  closeButton: {
    color: 'faint',
    _hover: {
      color: 'muted',
    },
  },
});

/**
 * @see https://chakra-ui.com/docs/components/modal
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/modal.ts
 */
export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { xl },
  variants: {
    bordered: borderedModalVariant,
  },
  defaultProps: {
    size: 'xl',
    variant: 'bordered',
  },
});
