import moment from 'moment';
import { type ChangeEvent } from 'react';

import { type EntityDuckAction, type FormChangeEvent } from 'types';

export function usePeriodSelectControls(
  name: string,
  onChange: (event: FormChangeEvent) => EntityDuckAction,
) {
  const selectOnChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const newValue = moment(evt.target.value);
    if (newValue.isValid()) {
      onChange({
        target: {
          name: name,
          value: newValue,
        },
      });
    }
  };

  return { selectOnChange };
}
