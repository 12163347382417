import {
  HStack,
  type HTMLChakraProps,
  type StackProps,
  chakra,
} from '@chakra-ui/react';
import { type DropzoneState } from 'react-dropzone';

function getColor(
  props: HTMLChakraProps<'div'> &
    Pick<DropzoneState, 'isDragReject' | 'isDragAccept'>,
) {
  if (props.isDragAccept) {
    return 'gray.200';
  }
  if (props.isDragReject) {
    return 'errorBg';
  }
  return undefined;
}

const containerStyles = {
  '> .dropzone': {
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'border',
    paddingY: '10',
    textAlign: 'center',
    color: 'blackAlpha.900',
    transition: `border 0.3s linear, background 0.3s linear`,
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    fontSize: 'md',
    '> span': {
      color: 'link.500',
    },
    _hover: {
      background: 'gray.100',
      borderColor: ' link.500',
      cursor: 'pointer',
    },
  },
  '> .dropzone[data-disabled=true]': {
    background: 'gray.200',
    _hover: {
      borderColor: 'border',
      cursor: 'not-allowed',
    },
    '> span': {
      color: 'unset',
    },
  },
};
export const FilePickerContainer = (
  props: HTMLChakraProps<'div'> &
    Pick<DropzoneState, 'isDragReject' | 'isDragAccept'>,
) => {
  // destructure to avoid: React does not recognize the `isDragAccept` prop on a DOM element
  const { isDragReject: _, isDragAccept: __, ...rest } = props;
  return (
    <chakra.div
      sx={{
        ...containerStyles,
        transition: `border 0.3s linear, background 0.3s linear`,
        background: getColor(props),
      }}
      {...rest}
    />
  );
};
export const FileListContainer = (props: HTMLChakraProps<'div'>) => (
  <chakra.div sx={{}} {...props} />
);

const FileRowContainerStyle = {
  width: 'full',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  height: '10',
  borderBottom: '1px',
  fontSize: 'sm',
};

const FileRowContainerStyleAccepted = {
  ...FileRowContainerStyle,
  color: 'blackAlpha.700',
  borderColor: 'gray.200',
};

const FileRowContainerStyleRejected = {
  ...FileRowContainerStyle,
  color: 'error',
  borderColor: 'error',
  '> div > div:first-of-type': {
    textDecoration: 'line-through',
  },
};

export const FileRowContainer = ({
  status,
  ...rest
}: StackProps & { status: boolean }) => (
  <HStack
    sx={status ? FileRowContainerStyleAccepted : FileRowContainerStyleRejected}
    {...rest}
  />
);

export const FileContent = (props: HTMLChakraProps<'div'>) => (
  <chakra.div sx={{}} {...props} />
);

export const FileName = (props: HTMLChakraProps<'div'>) => (
  <chakra.div sx={{}} {...props} />
);

export const FileDeleteAction = (props: HTMLChakraProps<'div'>) => (
  <chakra.div sx={{ cursor: 'pointer', fontSize: 20 }} {...props} />
);

export const FileRejectReason = (props: HTMLChakraProps<'div'>) => (
  <chakra.div sx={{}} {...props} />
);
