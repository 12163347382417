import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

class ExistingControlEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/existing_control/',
  };

  static fields: EntityFields<ExistingControlEntityFields> = {
    uuid: new Fields.IdField(),
    control_id: new Fields.CharField(),
  };
}

type ExistingControlEntityFields = {
  uuid: string;
  control_id: string;
};

export type ExistingControlEntityRecord =
  EntityRecord<ExistingControlEntityFields>;

ExistingControlEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ExistingControlEntity,
  name: 'ExistingControl',
});

export default ExistingControlEntity;
