import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';

import { Autocomplete } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { handleNotImplemented } from 'util/handleNotImplemented';

export const ControlsFilters = () => {
  const { formatMessage } = useIntl();
  const defaultMessage = defineMessage({
    id: 'ui.select',
    defaultMessage: 'Select...',
  });

  return (
    <Card variant="filled" bgColor="gray.200">
      <CardBody>
        <VStack gap={6}>
          <Heading as="h3" size="xs">
            <FormattedMessage
              id="global-framework.controls.list.add-controls"
              defaultMessage="Add controls"
            />
          </Heading>

          <Grid gridTemplateColumns="repeat(10, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 10, md: 5, lg: 3 }}>
              <FormControl>
                <FormLabel>
                  <FormattedMessage
                    id="global-framework.controls.list.filters.search-by-damage-energy-mech"
                    defaultMessage="Search by damaging energy mechanism"
                  />
                </FormLabel>
                <Autocomplete
                  size="md"
                  options={[]}
                  placeholder={formatMessage(defaultMessage)}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 10, md: 5, lg: 6 }}>
              <FormControl>
                <FormLabel>
                  <FormattedMessage
                    id="global-framework.controls.list.filters.search-by-keyword"
                    defaultMessage="Search by keyword"
                  />
                </FormLabel>
                <Autocomplete
                  size="md"
                  options={[]}
                  placeholder={formatMessage(defaultMessage)}
                />
              </FormControl>
            </GridItem>

            <GridItem
              colSpan={{ base: 10, md: 10, lg: 1 }}
              alignContent="flex-end"
            >
              <Button
                width="full"
                variant="outline"
                bgColor="white"
                onClick={handleNotImplemented('Search global controls')}
              >
                <FormattedMessage
                  id="global-framework.controls.list.filters.select-button"
                  defaultMessage="Select"
                />
              </Button>
            </GridItem>
          </Grid>
        </VStack>
      </CardBody>
    </Card>
  );
};
