import {
  Button,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  Text,
} from '@chakra-ui/react';
import { type List, fromJS } from 'immutable';
import { useMemo } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { Autocomplete } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { getNestedGrcOrgUnitName } from 'entities';
import PersonEntity from 'entities/api/Person/Person';
import SiteControlStatusEntity, {
  type SiteControlStatusEntityRecord,
  siteControlStatusEnum,
} from 'entities/api/SiteControlStatus';
import { Fieldset, FieldsetItem } from 'forms/fields';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';
import { handleNotImplemented } from 'util/handleNotImplemented';
import { toOptions } from 'util/toOptions';

import { alignEndSx } from './ControlImplementation.styles';

const log = createLogger('ControlImplementation');

function ControlImplementation({
  value: siteControlStatuses,
}: WithQueryReturnProps<List<SiteControlStatusEntityRecord>>) {
  log('%o', { siteControlStatuses });

  // TODO fetch valid control_owner options
  // HACK present all the distinct Persons we have in scope
  const ownerOptions = useMemo(
    () =>
      siteControlStatuses
        ?.map((sa) => sa?.get('control_owner'))
        ?.toSet()
        ?.filter(Boolean)
        ?.map((person) => ({
          value: person?.get('uuid'),
          label: PersonEntity.toString(person),
        }))
        .toJS(),
    [siteControlStatuses],
  );

  const statusOptions = toOptions(siteControlStatusEnum);

  return (
    <VStack>
      <Heading>
        <FormattedMessage
          id="control_framework.control.tabs.implementation.title"
          defaultMessage="Implementation"
        />
      </Heading>

      <Fieldset>
        {siteControlStatuses.map((controlStatus, i) => (
          <Fragment key={i}>
            <GridItem colSpan={{ base: 12, md: 12, lg: 6 }} sx={alignEndSx}>
              <Text lineHeight={9}>
                {getNestedGrcOrgUnitName(controlStatus?.get('site'))}
              </Text>
            </GridItem>

            <FieldsetItem colSpan={{ base: 12, md: 4, lg: 2 }}>
              <FormControl>
                <FormLabel>
                  <FormattedMessage
                    id="control_framework.control.tabs.implementation.label.control_owner"
                    defaultMessage="Control owner"
                  />
                </FormLabel>
                <Autocomplete
                  size="sm"
                  options={ownerOptions}
                  value={ownerOptions.find(
                    ({ value }) =>
                      controlStatus?.get('control_owner')?.get('uuid') == value,
                  )}
                  isDisabled
                />
              </FormControl>
            </FieldsetItem>

            <FieldsetItem colSpan={{ base: 12, md: 4, lg: 2 }}>
              <FormControl>
                <FormLabel>
                  <FormattedMessage
                    id="control_framework.control.tabs.implementation.label.status"
                    defaultMessage="Status"
                  />
                </FormLabel>
                <Autocomplete
                  size="sm"
                  options={statusOptions}
                  value={statusOptions.find(
                    ({ value }) =>
                      controlStatus?.get('implementation_status') == value,
                  )}
                  isDisabled
                />
              </FormControl>
            </FieldsetItem>

            <GridItem colSpan={{ base: 12, md: 4, lg: 2 }} sx={alignEndSx}>
              {controlStatus?.get('implementation_status') == 'nop' && (
                <Button
                  onClick={handleNotImplemented('View Improvement Action Plan')}
                  variant="primary"
                  size="sm"
                >
                  <FormattedMessage
                    id="control_framework.control.tabs.implementation.view-improvement-action-plan"
                    defaultMessage="Action Plan"
                  />
                </Button>
              )}
            </GridItem>
          </Fragment>
        ))}

        {!siteControlStatuses?.size && (
          <GridItem colSpan={12}>
            <Text lineHeight={9}>
              <FormattedMessage
                id="control_framework.control.tabs.implementation.empty"
                defaultMessage="No Implementations available"
              />
            </Text>
          </GridItem>
        )}
      </Fieldset>
    </VStack>
  );
}

export const ControlFrameworkControlImplementation = withQuery(() => {
  const { siteFrameworkUuid, controlUuid } = useParams();
  const params = useMemo(() => {
    return fromJS({
      control_framework: siteFrameworkUuid,
      site_framework_control__site_control: controlUuid,
    });
  }, [siteFrameworkUuid, controlUuid]);
  return {
    action: SiteControlStatusEntity.duck.actions.get({
      params: params,
    }),
  };
})(ControlImplementation);
