import {
  Box,
  Button,
  CloseButton,
  GridItem,
  HStack,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { List, type Map, fromJS } from 'immutable';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQuery } from '@burnsred/entity-duck-query';
import { RouterLink } from '@burnsred/ui-chakra';
import { VStack } from 'components/atoms';
import { RiskIcon } from 'components/RiskIcon';
import { Risk } from 'entities';
import ApplicableRuleEntity, {
  type ApplicableRuleEntityRecord,
} from 'entities/api/ApplicableRule';
import type { DamageEnergyMechanismEntityRecord } from 'entities/api/DamageEnergyMechanism';
import DamageEnergyMechanismEntity from 'entities/api/DamageEnergyMechanism';
import type { RulePredicateEntityRecord } from 'entities/api/RulePredicate';
import { useLocale } from 'locales/useLocale';
import type {
  CommonControlEntityRecord,
  CommonFrameworkEntityRecord,
} from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation';
import type { BasicEntityRecord } from 'types';
import { createLogger } from 'util/createLogger';

import {
  ContentBlock,
  buttonSx,
  closeButtonSx,
  gridItemSx,
  haveAssessLabelSx,
  headingSx,
} from './ControlPreview.styles';

const log = createLogger('ControlPreview');

// see bhp-broker/cube/models/controls/abstract.py#BaseRulePredicate
type PredicateKey =
  | 'operatingcontext'
  | 'equipment'
  | 'scenario'
  | 'scenariomodifier'
  | 'worklocation';

type ControlPreviewProps = {
  framework: CommonFrameworkEntityRecord;
  control: CommonControlEntityRecord;
  setExpandedControl: React.Dispatch<React.SetStateAction<string | null>>;
  controlHref: string;
  isPublicUser: boolean;
};

export const ControlPreview = ({
  framework,
  control,
  setExpandedControl,
  controlHref,
  isPublicUser,
}: ControlPreviewProps) => {
  const { toString } = useLocale();

  const params = useMemo(
    () =>
      fromJS({
        control: control?.get('uuid'),
      }),
    [control],
  );

  // FIXME BC-42 error handling
  const { value: applicableRules, errors: _ } = useQuery<
    List<ApplicableRuleEntityRecord>
  >({ action: ApplicableRuleEntity.duck.actions.get({ params }) });

  type HaveAssessMap = { have: number; assess: number };
  const haveAssessDefault = { have: 0, assess: 0 };

  const haveAssessMap =
    applicableRules?.reduce((acc: HaveAssessMap, rule) => {
      return {
        have: acc.have + (rule.get('is_mandatory') ? 1 : 0),
        assess: acc.assess + (!rule.get('is_mandatory') ? 1 : 0),
      };
    }, haveAssessDefault) ?? haveAssessDefault;

  const iconPath = Risk.getRiskIconPath(framework?.get('risk'));

  const inScope = toString(control, 'in_scope');
  const outOfScope = toString(control, 'out_of_scope');

  const groupedRules = useMemo(
    () =>
      applicableRules?.map((rule) =>
        rule
          .get('predicates')
          .groupBy((predicate) => predicate.get('model_name')),
      ) ?? fromJS([[]]),
    [applicableRules],
  );

  const aggregatePredicateStrings = (
    groupedRules: List<Map<string, List<RulePredicateEntityRecord>>>,
    key: PredicateKey,
  ) => {
    const list = groupedRules
      .flatMap((group) => group?.get(key) ?? List())
      .map((el) => toString(el as BasicEntityRecord))
      .toSet() // de-duplicate
      .toList();

    return list.size ? list : List(['-']);
  };

  const equipments = aggregatePredicateStrings(groupedRules, 'equipment');
  const operatingContexts = aggregatePredicateStrings(
    groupedRules,
    'operatingcontext',
  );

  const scenarioUuids = groupedRules
    .flatMap((group) => group?.get('scenario') ?? List())
    .map((el) => (el as RulePredicateEntityRecord).get('object_id'))
    .toSet() // de-duplicate
    .toList();

  const demParams = useMemo(
    () => fromJS({ damage_energy__risk: framework.get('risk').get('uuid') }),
    [framework],
  );
  const { value: _damageEnergyMechanisms } = useQuery<
    List<DamageEnergyMechanismEntityRecord>
  >({
    action: DamageEnergyMechanismEntity.duck.actions.get({
      params: demParams,
    }),
  });

  const damagingEnergyMechanisms = useMemo(() => {
    const demTitleStrings = _damageEnergyMechanisms
      ?.filter((dem, _i, _demList) =>
        dem
          ?.get('scenarios')
          ?.map((s) => s.get('uuid'))
          ?.some((uuid, _j, _uuidList) => scenarioUuids.includes(uuid)),
      )
      ?.map((el) => toString(el));
    return demTitleStrings?.size ? demTitleStrings : List(['-']);
  }, [_damageEnergyMechanisms, scenarioUuids, toString]);

  const maybeSupportFactors: List<string> = control
    .get('support_factors')
    .map((el) => toString(el));
  const supportFactors: List<string> = maybeSupportFactors.size
    ? maybeSupportFactors
    : List(['-']);

  log('%o', {
    framework,
    control,
    applicableRules,
    groupedRules,
    equipments,
    operatingContexts,
    _damageEnergyMechanisms,
    damagingEnergyMechanisms,
    supportFactors,
    scenarioUuids,
  });

  return (
    <GridItem as={VStack} colStart={1} colEnd={7} sx={gridItemSx}>
      <HStack gap={3}>
        <RiskIcon path={iconPath} filter="orange" />

        <Heading as="h4" variant="primary" sx={headingSx}>
          {toString(control)}
        </Heading>

        {!!haveAssessMap.have && (
          <Box sx={haveAssessLabelSx}>
            <FormattedMessage
              id="ControlPreview.have-count"
              defaultMessage="Have: {count}"
              values={{
                count: haveAssessMap.have,
              }}
            />
          </Box>
        )}
        {!!haveAssessMap.assess && (
          <Box sx={haveAssessLabelSx}>
            <FormattedMessage
              id="ControlPreview.assess-count"
              defaultMessage="Assess: {count}"
              values={{
                count: haveAssessMap.assess,
              }}
            />
          </Box>
        )}

        <CloseButton
          onClick={() => setExpandedControl(null)}
          sx={closeButtonSx}
        />
      </HStack>

      <SimpleGrid columns={4} gap={6}>
        <GridItem as={VStack} gap={6}>
          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.purpose"
                defaultMessage="Purpose"
              />
            }
          >
            <Text>{toString(control, 'purpose')}</Text>
          </ContentBlock>

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.control-type"
                defaultMessage="Control type"
              />
            }
          >
            <Text>{toString(control.get('control_designation'))}</Text>
          </ContentBlock>

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.support-factors"
                defaultMessage="Support factors"
              />
            }
          >
            <VStack>
              {supportFactors.map((el, i) => (
                <Text key={i}>{el}</Text>
              ))}
            </VStack>
          </ContentBlock>
        </GridItem>

        <GridItem as={VStack} gap={4}>
          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.equipment"
                defaultMessage="Equipment"
              />
            }
          >
            <VStack>
              {equipments.map((el, i) => (
                <Text key={i}>{el}</Text>
              ))}
            </VStack>
          </ContentBlock>
        </GridItem>

        <GridItem as={VStack} gap={4}>
          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.operating-context"
                defaultMessage="Operating context"
              />
            }
          >
            <VStack>
              {operatingContexts.map((el, i) => (
                <Text key={i}>{el}</Text>
              ))}
            </VStack>
          </ContentBlock>

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.damage-energy-mechanism"
                defaultMessage="Damaging energy mechanism"
              />
            }
          >
            <VStack>
              {damagingEnergyMechanisms.map((el, i) => (
                <Text key={i}>{el}</Text>
              ))}
            </VStack>
          </ContentBlock>
        </GridItem>

        <GridItem as={VStack} gap={6}>
          {inScope && (
            <ContentBlock
              heading={
                <FormattedMessage
                  id="ControlPreview.heading.in_scope"
                  defaultMessage="Scope - Included"
                />
              }
            >
              <Text>{inScope}</Text>
            </ContentBlock>
          )}

          {outOfScope && (
            <ContentBlock
              heading={
                <FormattedMessage
                  id="ControlPreview.heading.out-of-scope"
                  defaultMessage="Scope - Excluded"
                />
              }
            >
              <Text>{outOfScope}</Text>
            </ContentBlock>
          )}
        </GridItem>
      </SimpleGrid>

      {!isPublicUser && (
        <Button
          as={RouterLink}
          to={controlHref}
          variant="primary"
          sx={buttonSx}
        >
          <FormattedMessage
            id="ControlPreview.cta.go-to-control"
            defaultMessage="Go to the control"
          />
        </Button>
      )}
    </GridItem>
  );
};
