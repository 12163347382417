import {
  Container,
  Flex,
  type FlexProps,
  type StackProps,
} from '@chakra-ui/react';

import { VStack } from 'components';

export const ColumnsWrapper = ({ children }: StackProps) => (
  <Container
    className="ColumnsWrapper"
    maxWidth="container.xl"
    padding="0"
    display="flex"
  >
    {children}
  </Container>
);

export const MainColumn = ({ children, ...props }: FlexProps) => (
  <Flex
    as="main"
    direction="column"
    // facilitates sticky footer
    minHeight="calc(100vh - var(--chakra-sizes-desktopNavHeight))"
    {...props}
  >
    {children}
  </Flex>
);

export const Aside = ({ children, sx }: StackProps) => (
  <VStack as="aside" minWidth="navColumnWidth" order="-1" width="auto" sx={sx}>
    {children}
  </VStack>
);
