import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { fromJS } from 'immutable';
import React, { type ReactNode, useState } from 'react';

import { FilePicker, type UseFilePickerReturn } from 'components/FilePicker';
import FilerFileEntity, {
  type FilerFileEntityRecord,
} from 'entities/api/FilerFile.ts';
import I18nTextEntity from 'entities/api/i18n/I18nText.ts';
import I18nTranslationEntity, {
  ENGLISH,
  SPANISH,
} from 'entities/api/i18n/I18nTranslation.ts';
import QuestionAttachmentEntity, {
  type QuestionAttachmentEntityRecord,
} from 'entities/api/Question/QuestionAttachment.ts';

export type AttachmentModalProps = {
  heading?: string;
  children?: ReactNode;
  colorScheme?: string;
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  onConfirm: (new_attachments: QuestionAttachmentEntityRecord) => void;
  fileList: UseFilePickerReturn['fileList'];
  setFileList: UseFilePickerReturn['setFileList'];
  batchUploadFilerFileList: UseFilePickerReturn['batchUploadFilerFileList'];
};

/**
 * Modal component used to upload attachments
 *
 * You can pass the modal an icon in order to override the button
 * used to open the modal.
 */
export const QuestionAttachmentModal = ({
  isOpen,
  onClose,
  onConfirm,
  fileList,
  setFileList,
  batchUploadFilerFileList,
}: AttachmentModalProps) => {
  // todo
  // const newReference = URLReference.dataToRecord(
  //   {},
  // ) as URLReferenceEntityRecord;

  React.useEffect(() => {
    if (isOpen) {
      setFileList([]);
    }
  }, [isOpen, setFileList]);

  const toast = useToast();
  const [enText, setEnText] = useState('');
  const [esText, setEsText] = useState('');

  const onConfirmClick = async () => {
    const filerFiles = await batchUploadFilerFileList(fileList);
    if (!filerFiles?.length) {
      toast({
        title: 'Upload encountered an error',
        status: 'error',
      });
      return;
    }

    const file = FilerFileEntity.dataToRecord(
      filerFiles?.[0],
    ) as FilerFileEntityRecord;

    let newAttachment = QuestionAttachmentEntity.dataToRecord(
      {},
    ) as QuestionAttachmentEntityRecord;

    const text = I18nTextEntity.dataToRecord({});
    let en_translation = I18nTranslationEntity.dataToRecord({});
    let es_translation = I18nTranslationEntity.dataToRecord({});

    en_translation = en_translation.withMutations((rec) => {
      rec.set('language', ENGLISH);
      rec.set('translated_message', enText);
    });
    es_translation = es_translation.withMutations((rec) => {
      rec.set('language', SPANISH);
      rec.set('translated_message', esText);
    });

    newAttachment = newAttachment.withMutations((rec) => {
      rec.set(
        'title',
        text.set('translations', fromJS([en_translation, es_translation])),
      );
      rec.set('attachment', file);
    });

    onConfirm(newAttachment);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody sx={{ minHeight: '300px' }}>
          <Tabs variant="line">
            <TabList mb={2}>
              <Tab>FILE</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack
                  spacing={4}
                  alignItems={'flex-start'}
                  justifyContent={'center'}
                  width={'full'}
                >
                  <Input
                    name={'attachment_title_en'}
                    placeholder={'File name (EN)'}
                    onChange={(evt) => setEnText(evt.target.value)}
                  />
                  <Input
                    name={'attachment_title_es'}
                    placeholder={'File name (ES)'}
                    onChange={(evt) => setEsText(evt.target.value)}
                  />
                  <Box width="full">
                    <FilePicker
                      maxFiles={1}
                      fileList={fileList}
                      setFileList={setFileList}
                    />
                  </Box>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <Button w={160} h={12} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button w={160} h={12} variant="outline" onClick={onConfirmClick}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
