import {
  Button,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { Portal } from 'components';
import SiteFrameworkEntity, {
  type SiteFrameworkEntityRecord,
} from 'entities/api/SiteFramework';
import { useLocale } from 'locales/useLocale';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

import {
  type ActionsContext,
  useControlActions,
} from './ControlFramework.hooks';
import {
  actionButtonPrevSx,
  flexWrapperSx,
  stickyActionsContainerSx,
  stickyActionsWrapperSx,
} from './ControlFramework.styles';

const log = createLogger('ControlFramework');

const pathMap = {
  risks: 0,
  controls: 1,
  localisation: 2,
} as const;

export type TabPathSegment = keyof typeof pathMap;

export const controlHrefCb = (frameworkUuid: string, controlUuid: string) =>
  `/control-frameworks/${frameworkUuid}/controls/${controlUuid}`;

type ControlFrameworkProps = WithQueryReturnProps<SiteFrameworkEntityRecord>;

export type SiteFrameworkContext = {
  siteFramework: ControlFrameworkProps['value'];
};

export type FrameworkOutletContext = ActionsContext & SiteFrameworkContext;

function ControlFramework(props: ControlFrameworkProps) {
  const { toString } = useLocale();
  const pageRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { controlUuid, siteFrameworkUuid } = useParams();

  const siteFramework = props.value;
  const isNew = siteFrameworkUuid == 'new';

  log('%o', { siteFramework, isNew });

  const tabSegment =
    pathname
      // the path segment immediately after the siteFrameworkUuid...
      .split(`${siteFrameworkUuid}/` as string)
      .at(-1)
      // ...dropping trailing path segments, defaulting to 'risks'
      ?.split('/')[0] ?? 'risks';
  const currentTab = pathMap[tabSegment as keyof typeof pathMap];

  const { actions, setActions } = useControlActions();

  const outletContext = {
    actions,
    setActions,
    siteFramework,
  } satisfies FrameworkOutletContext;

  useEffect(() => {
    // prettier-ignore
    const nextPath =
      tabSegment == 'risks' ?  `/control-frameworks/${siteFrameworkUuid}/controls` :
      tabSegment == 'controls' ?  `/control-frameworks/${siteFrameworkUuid}/localisation` :
      // wrap around to risks again?
      `/control-frameworks/${siteFrameworkUuid}/risks`

    // prettier-ignore
    const previousPath =
      tabSegment == 'risks' ?  `/control-frameworks/` :
      tabSegment == 'controls' ?  `/control-frameworks/${siteFrameworkUuid}/risks` :
      `/control-frameworks/${siteFrameworkUuid}/controls`

    // don't update if we're in /controls/:controlUuid - the sub-tabs will update the actions
    if (!controlUuid) {
      setActions((prev) => ({
        ...prev,
        next: () => navigate(nextPath),
        previous: () => navigate(previousPath),
      }));
    }
  }, [
    navigate,
    tabSegment,
    setActions,
    pathname,
    siteFrameworkUuid,
    controlUuid,
  ]);

  /** type safe navigation factory */
  const createHandleNavigate = (slug: TabPathSegment) => () =>
    void navigate(slug);

  return (
    <Flex ref={pageRef} sx={flexWrapperSx}>
      <Heading size="md">{toString(siteFramework)}</Heading>

      <Tabs
        index={currentTab} // control via routing
        isLazy // only load the selected tab
        variant="enclosed"
      >
        <TabList>
          <Tab onClick={createHandleNavigate('risks')}>
            <FormattedMessage
              id="control-framework.tabs.risks"
              defaultMessage="Risks"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('controls')} isDisabled={isNew}>
            <FormattedMessage
              id="control-framework.tabs.controls"
              defaultMessage="Controls"
            />
          </Tab>
          <Tab
            onClick={createHandleNavigate('localisation')}
            isDisabled={isNew}
          >
            <FormattedMessage
              id="control-framework.tabs.localisation"
              defaultMessage="Localisation"
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Outlet context={outletContext} />
          </TabPanel>

          <TabPanel>
            <Outlet context={outletContext} />
          </TabPanel>

          <TabPanel>
            <Outlet context={outletContext} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Portal containerQuerySelector=".Scrollbars > div:first-of-type">
        <Flex as="footer" className="StickyActions" sx={stickyActionsWrapperSx}>
          <Flex sx={stickyActionsContainerSx}>
            <Button
              variant="outline"
              onClick={actions.previous}
              sx={actionButtonPrevSx}
            >
              <FormattedMessage id="ui.previous" defaultMessage="Previous" />
            </Button>

            <Button variant="outline" onClick={actions.cancel} isDisabled>
              <FormattedMessage id="ui.cancel" defaultMessage="Cancel" />
            </Button>

            <Button variant="outline" onClick={actions.saveAsDraft} isDisabled>
              <FormattedMessage
                id="ui.save-as-draft"
                defaultMessage="Save as draft"
              />
            </Button>

            <Button type="submit" variant="primary" onClick={actions.next}>
              <FormattedMessage id="ui.next" defaultMessage="Next" />
            </Button>
          </Flex>
        </Flex>
      </Portal>
    </Flex>
  );
}

export default withQuery(() => {
  const { siteFrameworkUuid: id } = useParams();
  return { action: SiteFrameworkEntity.duck.actions.get({ id }) };
})(ControlFramework);
