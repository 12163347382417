import { createContext, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { toString as i18nToString } from 'entities/api/i18n/I18nText';
import { useCurrentUserPreferences } from 'entities/api/Person/Person';
import UserPreferencesEntity from 'entities/api/Person/UserPreferences';
import { type LocaleKey, defaultLocale } from 'locales';
import type { BasicEntityRecord } from 'types';

export type LocaleContext = {
  locale: LocaleKey;
  setLocale: (locale: LocaleKey) => void;
  geti18nField?: <U extends BasicEntityRecord>(
    record: U,
    fieldName: string,
  ) => string;
  toString: <T extends BasicEntityRecord = BasicEntityRecord>(
    record: T | undefined,
    fieldName?: string,
  ) => string;
};

/**
 * Provides a way of setting locale without props drilling.
 *
 * Seems react-intl doesn't provide a way of setting the current locale, only
 * reading it?!
 *
 * ```tsx
 * const [locale, setLocale] = useState<LocaleKeys>('en');
 *
 * return (
 *   <BrIntlContext.Provider value={{ local, setLocale }}>
 *     <Component />
 *   </BrIntlContext>
 * )
 * ```
 */
export const BrIntlContext = createContext<LocaleContext>({
  locale: defaultLocale,
  setLocale: ((_locale: LocaleKey) => undefined) as LocaleContext['setLocale'],
  geti18nField: () => '',
  toString: () => '',
});

/**
 * Provide a way of setting locale without props drilling, and provides a
 * convenience method which wraps I18nText.toString, injecting the current
 * locale.
 *
 * Seems react-intl doesn't provide a way of setting the current locale, only
 * reading it?!
 *
 * https://formatjs.io/docs/react-intl/api#useintl-hook
 *
 * ```tsx
 * const { locale, setLocale, toString } = useLocale();
 * ```
 */
export const useLocale = () => {
  const context = useContext(BrIntlContext);
  const dispatch = useDispatch();
  const preferences = useCurrentUserPreferences();

  const toString = useCallback(
    <T extends BasicEntityRecord = BasicEntityRecord>(
      record: T | undefined,
      fieldName?: string,
      locale?: LocaleKey,
    ) => i18nToString(record, locale ?? context.locale, fieldName),
    [context.locale],
  );

  const setLocale = useCallback(
    (locale: LocaleKey) => {
      // Don't set the contexts state variable since we are duplicating state and the
      // Context component will remediate.
      if (preferences) {
        dispatch(
          UserPreferencesEntity.duck.actions.save(
            preferences.set('locale', locale),
          ),
        );
      } else {
        // Since we have public pages that won't have saved preferences
        context.setLocale(locale);
      }
    },
    [context, preferences, dispatch],
  );
  const geti18nField = useCallback(
    <U extends BasicEntityRecord>(record: U, fieldName: string) => {
      if (defaultLocale === context.locale) {
        // @ts-expect-error not key of issue @fixme
        return `${record.get(fieldName)}`;
      }
      // @ts-expect-error es field not found @fixme
      return `${record.get(`${fieldName}_${context.locale}`)}`;
    },
    [context.locale],
  );

  if (context === null)
    throw new Error('useLocale must be used within a BrIntlContext.Provider');

  return {
    ...context,
    /**
     * Returns a string for the current locale for the requested record
     *
     * ```ts
     * // gets 'title' by default
     * toString(record);
     *
     * // gets 'nonTitleI18nField' if second arg `fieldName` is passed
     * toString(record, 'nonTitleI18nField');
     *
     * // gets 'someOtherEntityFieldWithTitle'
     * toString(record.get('someOtherEntityFieldWithTitle'));
     *
     * // gets the 'title' field in ES (all three args required to specify locale)
     * toString(record, 'title', 'es');
     *
     * class MyEntity extends Entity {
     *   static fields: EntityFields<MyEntityFields> = {
     *     // ...
     *     title: new Fields.EntityField({
     *       entity: I18nTextEntity,
     *     }),
     *     nonTitleI18nField: new Fields.EntityField({
     *       entity: I18nTextEntity,
     *     }),
     *     someOtherEntityFieldWithTitle: new Fields.EntityField({
     *       entity: SomeOtherEntityFieldWithTitle,
     *     }),
     *   };
     *
     *   static toString = toString<MyEntityRecord>;
     * }
     * ```
     *
     * @see I18nText#toString
     */
    setLocale,
    geti18nField,
    toString,
  };
};

export type UseLocaleReturn = ReturnType<typeof useLocale>;
