import { useBreakpoint } from '@chakra-ui/react';

import { mobileBreakpoints } from 'theme/foundations/breakpoints';

/**
 * Returns true if the current breakpoint is in `mobileBreakpoints`.
 */
export const useIsMobile = () => {
  const breakpoint = useBreakpoint();
  return mobileBreakpoints.includes(
    breakpoint as (typeof mobileBreakpoints)[number],
  );
};
