import type { SystemStyleObject } from '@chakra-ui/react';

export const statsFlexSx: SystemStyleObject = {
  gap: 1,
  paddingBottom: 2,
};

export const statSx: SystemStyleObject = {
  flex: '0 1 auto',
  minWidth: 12,

  '.chakra-stat__number': {
    lineHeight: 1,
    paddingBottom: 2,
  },
  '.chakra-stat__label': {
    lineHeight: 1,
  },
};

export const statLabelSx: SystemStyleObject = {
  color: 'gray.400',
  fontSize: 'xs',
};

export const statDividerSx: SystemStyleObject = {
  marginBlock: 0,
};

export const getStatNumberSx = (
  countInterruptions: number,
): SystemStyleObject => ({
  color: countInterruptions > 0 ? 'errorFg' : 'inherit',
});

export const miniStatCard: SystemStyleObject = {
  padding: 1,
  bgColor: 'gray.100',
  boxShadow: 'none',

  dt: {
    fontSize: 'xs',
  },

  dd: {
    fontWeight: 'normal',
  },
};

export const labelSx: SystemStyleObject = {
  fontSize: 'xs',
  color: 'gray.500',
  marginBottom: 0,
};

export const headingSx: SystemStyleObject = {
  fontSize: 'xs',
  color: 'primary',
  fontWeight: 'bold',
};
