import type { SystemStyleObject } from '@chakra-ui/react';

export const listItemSx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: 'sm',
  color: 'muted',

  _hover: {
    cursor: 'help',
  },
};

export const iconSx: SystemStyleObject = {
  boxSize: 6,
  marginRight: 1,
};
