import {
  Input as ChakraInput,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import { useMemo } from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { Form, Input } from '@burnsred/entity-form';
import { Autocomplete as RawAutocomplete } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { EquipmentPicker, WidgetChecklistAccordion } from 'components/widgets';
import Autocomplete from 'components/widgets/Autocomplete/Autocomplete';
import { WidgetCheckbox } from 'components/widgets/WidgetCheckbox';
import type { ScenarioEntityRecord } from 'entities/api/Scenario';
import SiteFrameworkEntity, {
  type SiteFrameworkEntityRecord,
} from 'entities/api/SiteFramework';
import { I18MessageStack } from 'forms/components';
import { Fieldset, FieldsetItem } from 'forms/fields';
import { useLocale } from 'locales/useLocale';
import {
  type BasicEntityRecord,
  type EntityFormProps,
  type WithQueryProps,
} from 'types';
import { createLogger } from 'util/createLogger';

const log = createLogger('ControlFrameworkRiskForm');

type RiskFormProps = EntityFormProps & {
  value: SiteFrameworkEntityRecord;
  disabled: boolean;
  id: string;
  onSubmitSuccess: () => void;
};

function ControlFrameworkRiskForm(props: RiskFormProps) {
  const { toString } = useLocale();
  const {
    value,
    valueInitial,
    errors,
    field,
    onChange,
    onSubmit,
    name,
    disabled,
  } = props;
  log('%o', props);

  /** workaround: cache initial value, as we don't have an endpoint for options */
  const workLocationOptions = useMemo(() => {
    return value.get('work_locations');
    // don't update when value changes!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { formatMessage } = useIntl();
  const defaultMessage = defineMessage({
    id: 'ui.select',
    defaultMessage: 'Select...',
  });
  return (
    <Form
      name={name} // entity_duck_use_query
      errors={errors}
      field={field}
      onChange={onChange} // not calling useCleanOnChange
      onSubmit={onSubmit}
      value={value}
      valueInitial={valueInitial}
      // FIXME disabled prop not being honoured
      disabled={true || disabled}
    >
      <Flex layerStyle="cardLike">
        <VStack>
          <Fieldset
            heading={toString(value) || '(Blank)'}
            headingProps={{ variant: 'primary' }}
          >
            <GridItem colSpan={{ base: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="control-framework.tabs.risk.label.risk"
                    defaultMessage="Level 3 risk"
                  />
                </FormLabel>
                <Input
                  name="risk"
                  component={Autocomplete}
                  componentProps={{ disabled: true }}
                  loadOptionsFromAPI
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, sm: 6, md: 4, lg: 6 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="control-framework.tabs.risk.label.risk-title"
                    defaultMessage="Risk title"
                  />
                </FormLabel>
                <Input name="title" component={I18MessageStack} />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, sm: 6, md: 4, lg: 3 }} />

            <GridItem colSpan={{ base: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControl>
                <FormLabel>
                  <FormattedMessage
                    id="control-framework.tabs.risk.label.existing-risk-id"
                    defaultMessage="Existing Risk ID"
                  />
                </FormLabel>
                <ChakraInput
                  readOnly
                  value={value?.get('existing_identifier') || ''}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, sm: 6, md: 4, lg: 9 }}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="control-framework.tabs.risk.label.control-framework-administrators"
                    defaultMessage="Control framework administrators"
                  />
                </FormLabel>
                <RawAutocomplete
                  isMulti
                  options={[]}
                  placeholder={formatMessage(defaultMessage)}
                />
              </FormControl>
            </GridItem>
          </Fieldset>

          <Fieldset
            heading={
              <FormattedMessage
                id="control-framework.tabs.risk.fieldset.operating-contexts"
                defaultMessage="Operating contexts"
              />
            }
          >
            <GridItem colSpan={{ base: 12, sm: 6, md: 4, lg: 12 }}>
              <FormControl>
                <Input
                  name="operating_contexts"
                  component={WidgetCheckbox}
                  loadOptionsFromAPI
                />
              </FormControl>
            </GridItem>
          </Fieldset>

          <Fieldset
            heading={
              <FormattedMessage
                id="control-framework.work-locations"
                defaultMessage="Work locations"
              />
            }
          >
            <FieldsetItem>
              {workLocationOptions?.size ? (
                <Input
                  name="work_locations"
                  component={WidgetCheckbox}
                  componentProps={{
                    // TODO add an endpoint for work_locations;
                    // at present we're just showing the initial value
                    options: workLocationOptions,
                    getOptionLabel: (option: BasicEntityRecord) =>
                      toString(option),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="control-framework.work-locations.empty"
                  defaultMessage="No work locations"
                />
              )}
            </FieldsetItem>
          </Fieldset>

          <Fieldset
            heading={
              <FormattedMessage
                id="control-framework.tabs.risk.fieldset.equipment"
                defaultMessage="Equipment"
              />
            }
          >
            <FieldsetItem colSpan={{ base: 12 }}>
              <Input name="equipment" component={EquipmentPicker} />
            </FieldsetItem>
          </Fieldset>

          <Fieldset
            heading={
              <FormattedMessage
                id="control-framework.tabs.risk.fieldset.scenarios"
                defaultMessage="Scenarios"
              />
            }
          >
            <FieldsetItem colSpan={{ base: 12, sm: 6, md: 4, lg: 12 }}>
              <Input
                name="scenarios"
                component={WidgetChecklistAccordion}
                componentProps={{
                  checklistAccordionProps: {
                    defaultIndex: [0],
                  },
                  transformOptions: (options: List<ScenarioEntityRecord>) =>
                    options
                      ?.groupBy((rec) => rec.get('damage_energy_mechanism'))
                      ?.map((list, keyObj) => ({
                        id: keyObj.get('uuid'),
                        heading: toString(keyObj),
                        items: list.map((el) => ({
                          label: toString(el),
                          value: el.get('uuid'),
                        })),
                      }))
                      .toIndexedSeq()
                      .toList(),
                }}
                loadOptionsFromAPI
                filterParams={{
                  damage_energy_mechanism__damage_energy__risk: value
                    ?.get('risk')
                    ?.get('uuid'),
                  page_size: '100', // server seems to max out at 100?
                }}
              />
            </FieldsetItem>
          </Fieldset>
        </VStack>
      </Flex>
    </Form>
  );
}

type FormWithQueryProps = Partial<WithQueryProps> & RiskFormProps;

// @ts-expect-error The types of 'children' are incompatible between these types.
// Type 'ReactNode' is not assignable to type 'ReactNode[] | undefined'.
export default withQuery((props: FormWithQueryProps) => {
  const { siteFrameworkUuid: id } = useParams();
  const isNew = id == 'new';

  // This will either retrieve a backend existing risk to edit, or if blank
  // use a special placeholder in the redux store, onSubmit functionality will
  // change from POST to PUT depending if id has been set.
  const idSelector = isNew || id === undefined ? { id: null } : { id };
  log('withQuery', { id, props });

  return {
    ...props,
    action: SiteFrameworkEntity.duck.actions.get(idSelector),
    recordCountHidden: true,
    processingComponent: null,
  };
})(ControlFrameworkRiskForm);
