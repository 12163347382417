import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

// const baseStyle = {
//   popper: {},
//   content: {},
//   header: {},
//   body: {},
//   footer: {},
//   closeButton: {},
// }

/**
 * Allow popover to shrink to fit its content
 * https://github.com/chakra-ui/chakra-ui/issues/2609
 */
const responsiveVariant = defineStyle({
  content: {
    maxWidth: 'unset',
    width: 'unset',
  },
});

/**
 * https://chakra-ui.com/docs/components/popover/usage
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/popover.ts
 */
export const popoverTheme = defineStyleConfig({
  baseStyle: {
    popper: {
      zIndex: 'popover',
    },
  },
  // defaultProps: {},

  variants: {
    responsive: responsiveVariant,
  },

  // sizes are not defined for Popover
});
