import { List } from 'immutable';

import type { GrcOrganisationalUnitEntityRecord } from './GrcOrganisationalUnit';

/**
 * Return a string representing a GrcOrganisationUnit, with it's parents, eg:
 *
 * - 'Region > Asset > Operation'
 * - 'Region > Asset'
 * - 'Region'
 */
export function getNestedGrcOrgUnitName(
  record: GrcOrganisationalUnitEntityRecord,
) {
  return record
    .get('ancestors')
    .map((a) => a.get('name'))
    .concat(List([record?.get('name')]))
    .join(' > ');
}
