import { Query, useQuery } from '@burnsred/entity-duck-query';
import {
  WidgetBoolean,
  WidgetCheckbox,
  WidgetInput,
  WidgetList,
  WidgetOrdering,
} from '@burnsred/entity-widget';
import { Input } from 'components/widgets/Input';
import { DefaultErrorBoundary, Wrapper } from 'forms/components';
import { settings } from 'settings';
import store from 'store';

export default {
  settings,
  store,
  component_errorBoundary: DefaultErrorBoundary,
  component_label: Wrapper,
  component_query: Query,
  hook_useQuery: useQuery,

  widget_boolean: WidgetBoolean,
  widget_checkbox: WidgetCheckbox,
  widget_char: Input,
  widget_number: WidgetInput,
  widget_list: WidgetList,
  widget_ordering: WidgetOrdering,
};
