import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from './Person/GrcOrganisationalUnit';
import PersonEntity, { type PersonEntityRecord } from './Person/Person';

export type SiteControlStatus = 'act' | 'na' | 'nop';
export const siteControlStatusEnum = {
  act: 'Active',
  na: 'Not applicable',
  nop: 'Not implemented',
} satisfies Record<SiteControlStatus, string>;

class SiteControlStatusEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_control_status/',
  };

  static fields: EntityFields<SiteControlStatusEntityFields> = {
    uuid: new Fields.IdField(),

    site_framework: new Fields.CharField(),

    site: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
    }),

    control_owner: new Fields.EntityField({
      entity: PersonEntity,
    }),

    implementation_status: new Fields.CharField(),
  };
}

type SiteControlStatusEntityFields = {
  uuid: string;
  /** site_association.site_framework.uuid */
  site_framework: string;
  site: GrcOrganisationalUnitEntityRecord;
  control_owner: PersonEntityRecord;
  /** TODO enum */
  implementation_status: SiteControlStatus;
};

export type SiteControlStatusEntityRecord =
  EntityRecord<SiteControlStatusEntityFields>;

SiteControlStatusEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteControlStatusEntity,
  name: 'SiteControlStatus',
});

export default SiteControlStatusEntity;
