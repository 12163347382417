import type { SystemStyleObject } from '@chakra-ui/react';

export const questionsTabsSx: SystemStyleObject = {
  maxWidth: 'lg',

  '.chakra-tabs__tablist': {
    borderBottom: 0,
  },
};

export const deleteQuestionButtonSx: SystemStyleObject = {
  fontSize: 'md',
  color: 'icon',
  mt: '4',
};

export const previewQuestionTextSx: SystemStyleObject = {
  padding: 4,
  rounded: 'base',
  bgColor: 'gray.200',
};
