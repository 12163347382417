import type { FormEvent } from 'react';

import type { FormTarget } from 'types';

/**
 * Helper wraps name, value to resemble a ChangeEvent
 */
export const asEvent = ({ name, value, index }: FormTarget) =>
  ({
    target: {
      name,
      value,
      index,
    },
  }) as unknown as FormEvent<HTMLDivElement>;
