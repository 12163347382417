import * as Sentry from '@sentry/react';
import { Suspense, lazy } from 'react';
import { type RouteObject, createHashRouter, redirect } from 'react-router-dom';

import { RequireAuth, RequireInitialData } from 'components';
import {
  ErrorBoundaryFallback,
  MainLayout,
  MainLayoutOutlet,
  RouterErrorBoundary,
} from 'components/layout';
import { applicationName } from 'constants.ts';
import ControlEntity from 'entities/api/Control';
import {
  ControlCCC,
  ControlDashboard,
  ControlDocumentation,
  ControlFCC,
  ControlFramework,
  ControlFrameworkControlDetail,
  ControlFrameworkControlDrawer,
  ControlFrameworkControlImplementation,
  ControlFrameworkControlRules,
  ControlFrameworkControlsList,
  ControlFrameworkLocalisation,
  ControlFrameworkRiskForm,
  ControlFrameworkTabContext,
  ControlFrameworks,
  ControlSettings,
  ControlsVisualisation,
  GlobalDashboard,
  GlobalFrameworkControlCCC,
  GlobalFrameworkControlDetail,
  GlobalFrameworkControlDocumentation,
  GlobalFrameworkControlFCC,
  GlobalFrameworkControlRules,
  GlobalFrameworkControlSettings,
  GlobalFrameworkControlTabContext,
  GlobalFrameworkControlsList,
  GlobalFrameworkDetailsTab,
  GlobalFrameworkScreen,
  GlobalFrameworksScreen,
  RiskOwnerDashboard,
  Scenarios,
} from 'screens';
import { GLOBAL_FRAMEWORK_TABS } from 'screens/global-frameworks/GlobalFramework/GlobalFramework';
import { NotAllowed } from 'screens/NotAllowed';
import { NotFound } from 'screens/NotFound';
import { TrackNavigation } from 'util/useTracking';

export const routes: RouteObject[] = [
  {
    element: (
      <MainLayout>
        <TrackNavigation applicationName={applicationName} />
      </MainLayout>
    ),
    errorElement: (
      <MainLayout>
        <RouterErrorBoundary fallback={ErrorBoundaryFallback} />
      </MainLayout>
    ),
    children: [
      {
        path: '/not-allowed',
        element: <NotAllowed />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        element: <RequireAuth />,
        errorElement: <RouterErrorBoundary fallback={ErrorBoundaryFallback} />,
        children: [
          {
            path: '/',
            children: [
              {
                element: <RequireInitialData />,
                children: [
                  {
                    children: [
                      { index: true, loader: () => redirect('/dashboards') },

                      // dashboards
                      {
                        path: 'dashboards',
                        children: [
                          {
                            index: true,
                            path: '*',
                            // redirect index & fallback on malformed path segment
                            loader: () => redirect('global'),
                          },
                          {
                            path: 'global',
                            handle: {
                              title: 'title.global-performance',
                            },
                            element: <GlobalDashboard />,
                          },
                          {
                            path: 'risk',
                            handle: { title: 'title.my-control-frameworks' },
                            element: <RiskOwnerDashboard />,
                          },
                          {
                            path: 'control',
                            handle: { title: 'title.my-controls' },
                            element: <ControlDashboard />,
                          },
                        ],
                      },

                      // global-frameworks
                      {
                        path: GLOBAL_FRAMEWORK_TABS.base,
                        children: [
                          {
                            handle: {
                              title: 'title.global-frameworks.architectures',
                            },
                            element: <GlobalFrameworksScreen />,
                            children: [
                              {
                                // this page handles fetching frameworks, before it can redirect to the first framework
                                index: true,
                                element: <ControlsVisualisation />,
                              },
                              {
                                path: ':globalFrameworkUuid',
                                element: <ControlsVisualisation />,
                              },
                            ],
                          },

                          // global-framework tabs
                          {
                            path: ':globalFrameworkUuid',
                            handle: {
                              title: 'title.global-frameworks.architecture',
                            },
                            children: [
                              {
                                element: <GlobalFrameworkScreen />,
                                children: [
                                  {
                                    path: GLOBAL_FRAMEWORK_TABS.tabPaths.details
                                      .routeName,
                                    handle: {
                                      title: 'title.global-frameworks.details',
                                    },
                                    element: <GlobalFrameworkDetailsTab />,
                                  },
                                  {
                                    path: GLOBAL_FRAMEWORK_TABS.tabPaths
                                      .scenarios.routeName,
                                    handle: {
                                      title:
                                        'title.global-frameworks.scenarios',
                                    },
                                    element: <Scenarios />,
                                  },
                                  {
                                    path: GLOBAL_FRAMEWORK_TABS.tabPaths
                                      .controls.routeName,
                                    handle: {
                                      title: 'title.global-frameworks.controls',
                                    },
                                    children: [
                                      {
                                        index: true,
                                        element: (
                                          <GlobalFrameworkControlsList />
                                        ),
                                      },
                                      {
                                        path: ':controlUuid', // also handles 'new'
                                        // TODO RequireValidControl (bounce 404s)
                                        element: (
                                          <GlobalFrameworkControlDetail />
                                        ),
                                        children: [
                                          {
                                            index: true,
                                            element: null,
                                            loader: () => redirect('settings'),
                                          },
                                          {
                                            path: 'settings',
                                            handle: {
                                              title:
                                                'title.global-control.settings',
                                            },
                                            element: (
                                              <GlobalFrameworkControlTabContext
                                                Component={
                                                  GlobalFrameworkControlSettings
                                                }
                                              />
                                            ),
                                          },
                                          {
                                            path: 'rules',
                                            handle: {
                                              title:
                                                'title.global-control.rules',
                                            },
                                            // special case: needs to fetch applicable_rules
                                            element: (
                                              <GlobalFrameworkControlRules />
                                            ),
                                          },
                                          {
                                            path: 'ccc',
                                            handle: {
                                              title: 'title.global-control.ccc',
                                            },
                                            element: (
                                              <GlobalFrameworkControlTabContext
                                                Component={
                                                  GlobalFrameworkControlCCC
                                                }
                                              />
                                            ),
                                          },
                                          {
                                            path: 'fcc',
                                            handle: {
                                              title: 'title.global-control.fcc',
                                            },
                                            element: (
                                              <GlobalFrameworkControlTabContext
                                                Component={
                                                  GlobalFrameworkControlFCC
                                                }
                                              />
                                            ),
                                          },
                                          {
                                            path: 'cco',
                                            handle: {
                                              title: 'title.global-control.cco',
                                            },
                                            element: null,
                                          },
                                          {
                                            path: 'la',
                                            handle: {
                                              title: 'title.global-control.la',
                                            },
                                            element: null,
                                          },
                                          {
                                            path: 'documentation',
                                            handle: {
                                              title:
                                                'title.global-control.documentation',
                                            },
                                            element: (
                                              <GlobalFrameworkControlTabContext
                                                Component={
                                                  GlobalFrameworkControlDocumentation
                                                }
                                              />
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },

                      // control-frameworks
                      {
                        path: 'control-frameworks',
                        handle: { title: 'title.local-frameworks' },
                        children: [
                          { index: true, element: <ControlFrameworks /> },
                          {
                            path: ':siteFrameworkUuid',
                            handle: { title: 'title.local-framework' },
                            element: <ControlFramework />,
                            children: [
                              {
                                index: true,
                                path: '*',
                                // redirect index & fallback on malformed path segment
                                loader: () => redirect('risks'),
                              },
                              {
                                path: 'risks',
                                handle: {
                                  title: 'title.local-framework.risk',
                                },
                                element: <ControlFrameworkRiskForm />,
                              },
                              {
                                path: 'controls',
                                handle: {
                                  title: 'title.local-framework.controls',
                                },
                                children: [
                                  {
                                    index: true,
                                    element: <ControlFrameworkControlsList />,
                                  },
                                  {
                                    element: <ControlFrameworkControlsList />,
                                    children: [
                                      {
                                        // this is nested like so so that ControlFrameworkControlDrawer
                                        // displays SiteFrameworkControlsList in the BG
                                        path: ':controlUuid/preview',
                                        element: (
                                          <ControlFrameworkControlDrawer />
                                        ),
                                      },
                                    ],
                                  },
                                  {
                                    path: ':controlUuid', // also handles 'new'
                                    handle: {
                                      title:
                                        'title.local-framework.control.detail',
                                    },
                                    // TODO RequireValidControl (bounce 404s)
                                    element: (
                                      <ControlFrameworkControlDetail
                                        entity={ControlEntity}
                                      />
                                    ),
                                    children: [
                                      {
                                        index: true,
                                        element: null,
                                        loader: () => redirect('settings'),
                                      },
                                      {
                                        path: 'settings',
                                        handle: {
                                          title:
                                            'title.local-framework.control.settings',
                                        },
                                        element: (
                                          <ControlFrameworkTabContext
                                            Component={ControlSettings}
                                          />
                                        ),
                                      },
                                      {
                                        path: 'rules',
                                        handle: {
                                          title:
                                            'title.local-framework.control.rules',
                                        },
                                        element: (
                                          <ControlFrameworkTabContext
                                            Component={
                                              ControlFrameworkControlRules
                                            }
                                          />
                                        ),
                                      },
                                      {
                                        path: 'ccc',
                                        handle: {
                                          title:
                                            'title.local-framework.control.ccc',
                                        },
                                        element: (
                                          <ControlFrameworkTabContext
                                            Component={ControlCCC}
                                          />
                                        ),
                                      },
                                      {
                                        path: 'fcc',
                                        handle: {
                                          title:
                                            'title.local-framework.control.fcc',
                                        },
                                        element: (
                                          <ControlFrameworkTabContext
                                            Component={ControlFCC}
                                          />
                                        ),
                                      },
                                      {
                                        path: 'cco',
                                        handle: {
                                          title:
                                            'title.local-framework.control.cco',
                                        },
                                        element: null,
                                      },
                                      {
                                        path: 'la',
                                        handle: {
                                          title:
                                            'title.local-framework.control.la',
                                        },
                                        element: null,
                                      },
                                      {
                                        path: 'implementation',
                                        handle: {
                                          title:
                                            'title.local-framework.control.implementation',
                                        },
                                        element: (
                                          <ControlFrameworkTabContext
                                            Component={
                                              ControlFrameworkControlImplementation
                                            }
                                          />
                                        ),
                                      },
                                      {
                                        path: 'documentation',
                                        handle: {
                                          title:
                                            'title.local-framework.control.documentation',
                                        },
                                        element: (
                                          <ControlFrameworkTabContext
                                            Component={ControlDocumentation}
                                          />
                                        ),
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                path: 'localisation',
                                handle: {
                                  title: 'title.local-framework.localisation',
                                },
                                element: <ControlFrameworkLocalisation />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

// lazy import dev components so they're not bundled
// (these components require a default export)
const DevIndex = lazy(() => import('screens/dev/DevIndex'));
const DevScratchpad = lazy(() => import('screens/dev/DevScratchpad'));
const Translation = lazy(() => import('screens/dev/Translation'));
const UseLocale = lazy(() => import('screens/dev/UseLocale'));
const DevRoutedDrawer = lazy(
  () => import('screens/dev/RoutedDrawer/DevRoutedDrawer'),
);
const DevRoutedDrawerChild = lazy(
  () => import('screens/dev/RoutedDrawer/DevRoutedDrawerChild'),
);
const ScenariosDev = lazy(() => import('screens/dev/Scenarios'));
const SiteFrameworksDev = lazy(() => import('screens/dev/SiteFrameworks'));
const EntityFormScreen = lazy(() => import('screens/dev/EntityForms/screens'));
const EntitySelectorScreen = lazy(
  () => import('screens/dev/EntitySelectors/screens'),
);
const UsePreviousDemo = lazy(() => import('screens/dev/UsePreviousDemo'));
const UseDebounceDemo = lazy(() => import('screens/dev/UseDebounceDemo'));
const GlobalControl = lazy(() => import('screens/dev/GlobalControl/index'));
const DevChecklistAccordion = lazy(
  () => import('screens/dev/ChecklistAccordion'),
);
const DevValueToggle = lazy(() => import('screens/dev/ValueToggle'));
const DevControlGrid = lazy(() => import('screens/dev/DevControlGrid'));
const DevTreePicker = lazy(() => import('screens/dev/DevTreePicker'));
const DevAccordionPicker = lazy(() => import('screens/dev/DevAccordionPicker'));

if (import.meta.env.DEV) {
  routes.push({
    path: '/dev',
    element: (
      <Suspense fallback={'…'}>
        <MainLayoutOutlet />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <DevIndex />,
      },
      {
        path: 'scratch',
        element: <DevScratchpad />,
      },
      {
        path: 'translation',
        element: <Translation />,
      },
      {
        path: 'useLocale',
        element: <UseLocale />,
      },
      {
        path: 'routed-drawer',
        element: <DevRoutedDrawer />,
        children: [
          { index: true, element: null },
          {
            path: ':childId',
            element: <DevRoutedDrawerChild />,
          },
        ],
      },
      {
        path: 'scenarios',
        element: <ScenariosDev />,
        children: [{ index: true, element: null }],
      },
      {
        path: 'control-frameworks',
        element: <SiteFrameworksDev />,
      },
      {
        path: 'entity-form',
        element: <EntityFormScreen />,
      },
      {
        path: 'entity-selector',
        element: <EntitySelectorScreen />,
      },
      {
        path: 'use-previous',
        element: <UsePreviousDemo />,
      },
      {
        path: 'use-debounce',
        element: <UseDebounceDemo />,
      },
      {
        path: 'global-control',
        element: <GlobalControl />,
      },
      {
        path: 'checklist-accordion',
        element: <DevChecklistAccordion />,
      },
      {
        path: 'value-toggle',
        element: <DevValueToggle />,
      },
      {
        path: 'control-grid',
        element: <DevControlGrid />,
      },
      {
        path: 'tree-picker',
        element: <DevTreePicker />,
      },
      {
        path: 'accordion-picker',
        element: <DevAccordionPicker />,
      },
    ],
  });
}

/** https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/ */
const sentryCreateHashRouter = Sentry.wrapCreateBrowserRouter(createHashRouter);

/**
 * We're using HashRouter because we don't currently have devops
 * support for BrowserRouter: our reverse proxy doesn't rewrite
 * deep app URLs to index.html, so they 404 on direct access.
 *
 * Note that HashRouter has no need of the `basename` option.
 */
const router = sentryCreateHashRouter(routes);

export default router;
