import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    // the default is chakra-body-bg, which is beige in Cube
    backgroundColor: 'white',
  },
  // header: {},
  // body: {},
  // footer: {},
});

const elevated = definePartsStyle({
  container: {
    boxShadow: 'md',
  },
});

/** particularly for use nested under tabs */
const crisp = definePartsStyle({
  ...elevated,

  container: {
    borderWidth: '1px',
    borderColor: 'gray.300',
    rounded: '4px',
    roundedTopLeft: '0',
    boxShadow: 'sm',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    color: 'primary',

    // anticipating an icon before the header
    'svg:first-of-type': { marginRight: 2 },
  },
});

const formCard = definePartsStyle({
  container: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.300',
    borderRadius: 'base',
  },
});

/**
 * @see https://chakra-ui.com/docs/components/card/usage
 * @see https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/card.ts
 */
export const customCardTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    crisp,
    elevated,
    formCard,
  },
  defaultProps: {
    // Note: 'elevated' is the default
  },
});
