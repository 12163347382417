import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import type { GrcOrganisationalUnitEntityRecord } from './Person/GrcOrganisationalUnit';
import GrcOrganisationalUnitEntity from './Person/GrcOrganisationalUnit';
import type { PersonEntityRecord } from './Person/Person';
import PersonEntity from './Person/Person';

class SiteAssociationEntity extends Entity {
  static paginated = true;
  static paths = {
    apiBase: '/cube/v1/site_association/',
  };

  static fields: EntityFields<SiteAssociationEntityFields> = {
    uuid: new Fields.IdField(),
    site: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
    }),
    risk_owner: new Fields.EntityField({
      entity: PersonEntity,
    }),
  };
}

export type SiteAssociationEntityFields = {
  uuid: string;
  site: GrcOrganisationalUnitEntityRecord;
  risk_owner: PersonEntityRecord;
};

export type SiteAssociationEntityRecord =
  EntityRecord<SiteAssociationEntityFields>;

SiteAssociationEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteAssociationEntity,
  name: 'SiteAssociation',
});

export default SiteAssociationEntity;
