import { Flex, useDisclosure } from '@chakra-ui/react';
import { type ReactNode, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Outlet, useLocation } from 'react-router-dom';

import { StickyFooter } from '@burnsred/ui-chakra';
import { AppVersion } from 'components';
import { useIsMobile } from 'theme/util';

import { Aside, ColumnsWrapper, MainColumn } from './ColumnsLayout';
import {
  desktopAsideSx,
  mainColumnSx,
  stickyFooterSx,
} from './MainLayout.styles';
import { DesktopHeaderNav } from './NavBar';
import { NavLinks } from './NavLinks';

type MainLayoutProps = {
  children: ReactNode;
};

export function MainLayout({ children }: MainLayoutProps) {
  const isMobile = useIsMobile();
  const mobileDisclosure = useDisclosure();

  const location = useLocation();
  useEffect(() => {
    // close mobile navigation on route change
    if (mobileDisclosure.isOpen) mobileDisclosure.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Scrollbars
      className="Scrollbars"
      autoHide
      style={{ width: '100wh', height: '100vh' }}
    >
      <DesktopHeaderNav
        isMobile={isMobile}
        mobileDisclosure={mobileDisclosure}
      />

      <ColumnsWrapper>
        <MainColumn sx={mainColumnSx}>
          <Flex direction="column">{children}</Flex>
        </MainColumn>

        {!isMobile && (
          <Aside sx={desktopAsideSx}>
            <NavLinks />

            <StickyFooter sx={stickyFooterSx}>
              <AppVersion />
            </StickyFooter>
          </Aside>
        )}
      </ColumnsWrapper>
    </Scrollbars>
  );
}

export const MainLayoutOutlet = () => (
  <MainLayout>
    <Outlet />
  </MainLayout>
);
