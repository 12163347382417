import { Outlet } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import { TimeZoneEntity } from 'entities';

/**
 * Fetch resources which are common requirements for child routes
 */
export const RequireInitialData = () => {
  useQuery({
    action: TimeZoneEntity.duck.actions.get(),
  });

  return <Outlet />;
};
