import { type ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';

/**
 * Note: prefer importing from components/atoms/Tooltip to see further docs, and
 * use our preferred props.
 *
 * https://v2.chakra-ui.com/docs/components/tooltip/usage
 * https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/tooltip.ts
 * https://github.com/chakra-ui/chakra-ui/tree/v2/packages/components/src/tooltip
 */
export const tooltipTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    '--tooltip-bg': 'var(--chakra-colors-gray-700)',
    '--tooltip-fg': 'var(--chakra-colors-whiteAlpha-900)',
    '--popper-arrow-bg': 'var(--tooltip-bg)',

    rounded: 'base',
  },
});
