import type { ReactElement } from 'react';
import { useOutletContext } from 'react-router';

import type { ControlDetailContextType } from '../ControlFrameworkControlDetail';

type ControlFrameworkTabContextProps = {
  Component: (props: ControlDetailContextType) => ReactElement;
};

export function ControlFrameworkTabContext({
  Component,
}: ControlFrameworkTabContextProps) {
  const { controlFormControls, siteFramework } =
    useOutletContext<ControlDetailContextType>();

  return (
    <Component
      siteFramework={siteFramework}
      controlFormControls={controlFormControls}
    />
  );
}
