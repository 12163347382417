import {
  Input as ChakraInput,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { type Map, fromJS } from 'immutable';
import {
  type ChangeEvent,
  type ChangeEventHandler,
  useCallback,
  useEffect,
} from 'react';

import { Form, Input } from '@burnsred/entity-form';
import I18nTextEntity, {
  type I18nTextEntityRecord,
} from 'entities/api/i18n/I18nText';
import type { I18nTranslationEntityRecord } from 'entities/api/i18n/I18nTranslation';
import I18nTranslationEntity, {
  ENGLISH,
  SPANISH,
} from 'entities/api/i18n/I18nTranslation';
import { type EntityFormProps } from 'types';
import { createLogger } from 'util/createLogger';

const log = createLogger('widget:I18MessageStack');

const TranslatedMessageInput = (props: EntityFormProps) => {
  const { value, errors, name, onChange } = props;

  const resolvedErrors = errors
    .map((err) => (err as Map<string, string>)?.get('message'))
    .join('. ');

  const locale = (value.getIn(['language', 'value']) as string) ?? 'en';

  const stringValue = I18nTextEntity.toString(
    fromJS({ translations: [value] }),
    locale,
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      log('handleChange: %o', event);
      if (typeof event?.target?.value !== 'string') return;

      // HACK: we only have access to the single record here, so hard code this check
      const index = locale == 'en' ? 0 : 1;

      onChange({
        target: {
          // must send index, or:
          // > Field.validate (EntityField2-translations): "value" must be an "Immutable List" with field option "many"
          index,
          name,
          value: value.set('translated_message', event.target.value || ''),
        },
      });
    },
    [locale, name, onChange, value],
  );

  return (
    <Form {...props}>
      <FormControl isInvalid={!!resolvedErrors} isReadOnly={false}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Text as="b" fontSize="xs" color="muted">
              {locale.toUpperCase()}
            </Text>
          </InputLeftElement>

          {/* Need to manually render the ChakraInput, as it needs context to be intact for InputGroup to function */}
          <ChakraInput
            name={name}
            value={stringValue}
            onChange={handleChange}
          />
        </InputGroup>

        <FormErrorMessage>{resolvedErrors}</FormErrorMessage>
      </FormControl>
    </Form>
  );
};

/**
 * Renders an Input field for each Translation, stacked on top of each other.
 */
export const I18MessageStack = (
  props: EntityFormProps<I18nTextEntityRecord> & {
    disabled?: boolean;
  },
) => {
  const {
    disabled,
    errors,
    field,
    name,
    onChange,
    onSubmit,
    value,
    valueInitial,
  } = props;
  log('props: %o', props);

  // Ensure translation records exist for both English and Spanish
  useEffect(() => {
    const translations = value?.get('translations');
    for (const language of [SPANISH, ENGLISH]) {
      if (!translations?.find((t) => t.get('language')?.equals(language))) {
        let newRecord = I18nTranslationEntity.dataToRecord({
          // FIXME dataToRecord doesn't populate with passed data, eg 'language'
        }) as I18nTranslationEntityRecord;
        newRecord = newRecord.set('language', language);
        log('useEffect %o', { language, newRecord, translations });

        onChange({
          target: {
            name,
            value: value.set('translations', translations.push(newRecord)),
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, onChange]);

  return (
    <Form
      name={name}
      errors={errors}
      field={field}
      onChange={onChange}
      onSubmit={onSubmit}
      value={value}
      valueInitial={valueInitial}
      disabled={disabled}
    >
      <VStack>
        <Input name="translations" component={TranslatedMessageInput} many />
      </VStack>
    </Form>
  );
};
