import { Entity, Fields } from '@burnsred/entity';
import { type EntityFields, type EntityRecord } from 'types.ts';

import I18nTextEntity, { type I18nTextEntityRecord } from '../i18n/I18nText.ts';

class URLReferenceEntity extends Entity {
  static fields: EntityFields<URLReferenceEntityField> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),
    url: new Fields.CharField(),
  };

  static toString(record: URLReferenceEntityRecord, locale = '') {
    return I18nTextEntity.toString(record.get('title'), locale);
  }
}

type URLReferenceEntityField = {
  uuid: string;
  title: I18nTextEntityRecord;
  url: string;
};

export type URLReferenceEntityRecord = EntityRecord<URLReferenceEntityField>;

export default URLReferenceEntity;
