import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import ControlEntity, { type ControlEntityRecord } from './Control';

class SiteFrameworkControlsEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_framework_controls/',
  };

  static fields: EntityFields<SiteFrameworkControlsEntityFields> = {
    uuid: new Fields.IdField(),

    control: new Fields.EntityField({
      entity: ControlEntity,
    }),
    // @Fixme: missing fields
  };
}

type SiteFrameworkControlsEntityFields = {
  uuid: string;
  control: ControlEntityRecord;
};

export type SiteControlEntityRecord =
  EntityRecord<SiteFrameworkControlsEntityFields>;

SiteFrameworkControlsEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteFrameworkControlsEntity,
  name: 'SiteFrameworkControls',
});

export default SiteFrameworkControlsEntity;
