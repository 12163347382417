import debug from 'debug';

type CreateLoggerOptions = {
  /** defaults to 'log' */
  level?: 'log' | 'info' | 'warn' | 'error';
};

/**
 * Instantiate a debug log.
 *
 * ```tsx
 * const log = createLogger('widget:Input');
 *
 * log('some message');
 *
 * const log = createLogger('my-component', { level: 'warn' });
 * ```
 *
 * ### Configure which logs are shown in devtools:
 *
 * ```js
 * // all
 * localStorage.debug = '*'
 *
 * // logs registered under the 'widgets' namespace
 * localStorage.debug = 'widgets:*'
 * ```
 *
 * https://github.com/debug-js/debug
 */
export const createLogger = (
  namespace: string,
  options: CreateLoggerOptions = {},
) => {
  const log = debug(namespace);
  // prefer console.log to console.error by default
  // > By default debug will log to stderr
  log.log = console[options?.level ?? 'log'].bind(console);
  return log;
};
