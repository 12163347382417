import type { SystemStyleObject } from '@chakra-ui/react';

import { greyFilter, orangeFilter } from 'components';

export const tabsSx: SystemStyleObject = {
  '.chakra-tabs__tablist': {},
  '.chakra-tabs__tab': {
    // render the padding as margin around the icon instead
    padding: 0,
  },
};

export const riskIconSx: SystemStyleObject = {
  // these margins match the default padding for .chakra-tabs__tab
  marginBottom: 2,
  marginLeft: 5,
  marginRight: 5,
  marginTop: 2,

  filter: greyFilter,

  '.active &': {
    filter: orangeFilter,
  },
};
