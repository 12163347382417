import { Box, type BoxProps, type SystemStyleObject } from '@chakra-ui/react';

const resizerSx: SystemStyleObject = {
  '--gridArea': '1 / 2',

  display: 'inline-grid',
  gridTemplateColumns: 'min-content',
  width: 'full',

  _after: {
    gridArea: 'var(--gridArea)',
    content: `attr(data-value) ' '`,
    visibility: 'hidden',
    whiteSpace: 'pre-wrap',

    // pass styles should match the textarea inside
  },

  textarea: {
    gridArea: 'var(--gridArea)',
    height: 'auto',
    resize: 'none',
    overflow: 'hidden',
  },
};

const defaultAfterSx = {
  // these properties should match the textarea inside
  paddingX: 4,
  paddingY: 2,
  border: '2px solid transparent',
  lineHeight: '22px',
};

type InputResizerProps = BoxProps & {
  /** Should be the same value as provided to the child Textarea */
  value: string;
  /**
   * These styles should make the invisible text in rendered
   * `InputResizer::after` match the text rendered inside the actual textarea.
   *
   * Defaults to values suitable for a chakra Textarea.
   */
  afterSx?: SystemStyleObject;
};

/**
 * Causes the container Textarea to expand to fit its content without rendering
 * scrollbars.
 *
 * ```tsx
 * <InputResizer value={value}>
 *   <Textarea value={value} />
 * </InputResizer>
 * ```
 *
 * > The JavaScript sets a data-* attribute on the element equal to the value of
 * > the input. The input is set within a CSS grid, where that grid is a
 * > pseudo-element that uses that data-* attribute as its content. That content
 * > is what stretches the grid to the appropriate size based on the input
 * > value.
 *
 * @see https://css-tricks.com/auto-growing-inputs-textareas/#aa-other-ideas
 */
export const InputResizer = ({
  value,
  sx,
  afterSx,
  ...props
}: InputResizerProps) => (
  <Box
    data-value={value}
    sx={{
      ...resizerSx,
      _after: {
        // @ts-expect-error Spread types may only be created from object types.
        ...resizerSx?._after,
        ...(afterSx ?? defaultAfterSx),
      },
      ...sx,
    }}
    {...props}
  />
);
