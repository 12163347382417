import {
  Fragment,
  type ReactElement,
  type RefObject,
  useEffect,
  useReducer,
} from 'react';
import { createPortal } from 'react-dom';

type PortalProps<T> = (
  | {
      /** React.ref assigned to the target element */
      containerRef?: RefObject<T>;
      containerQuerySelector?: never;
    }
  | {
      /** string to be passed to `document.querySelector()` */
      containerQuerySelector?: string;
      containerRef?: never;
    }
) & {
  children?: ReactElement;
};

/**
 * Displays `children` in a React portal (without a wrapper element).
 *
 * By default renders as a child of `document.body`, unless either
 * `containerRef` or `containerQuerySelector` is passed.
 *
 * ```tsx
 * function App() {
 *   const appRef = useRef<HTMLDivElement>(null);
 *
 *   return (
 *     <div className="App" ref={appRef}>
 *       <Portal>
 *         <p>document.body</p>
 *       </Portal>
 *
 *       <Portal containerQuerySelector=".App">
 *         <p>.App</p>
 *       </Portal>
 *
 *       <Portal containerRef={appRef}>
 *         <p>appRef</p>
 *       </Portal>
 *     </div>
 *   );
 * }
 * ```
 *
 * Note: uses React.createPortal because Chakra renders a wrapper element, and
 * doesn't support styling the wrapper :(
 *
 * @see https://react.dev/reference/react-dom/createPortal
 */
export const Portal = <T extends Element>({
  children,
  containerRef,
  containerQuerySelector,
}: PortalProps<T>) => {
  const [refreshKey, incrementRefreshKey] = useReducer((x: number) => x + 1, 0);

  // HACK: re-render when containerRef.current or document.QuerySelector resolves
  useEffect(incrementRefreshKey, [incrementRefreshKey]);

  const maybeQuerySelectorElement = containerQuerySelector
    ? document.querySelector(containerQuerySelector)
    : null;

  // if either containerQuerySelector or containerRef are passed, wait until they resolve
  if (
    (containerQuerySelector && !maybeQuerySelectorElement) ||
    (containerRef && !containerRef?.current)
  )
    return null;

  return createPortal(
    <Fragment key={refreshKey}>{children ?? null}</Fragment>,
    maybeQuerySelectorElement ?? containerRef?.current ?? document.body,
  );
};
