import { chakra } from '@chakra-ui/react';
import { type ReactNode, useCallback } from 'react';
import {
  type Accept,
  type DropzoneOptions,
  type FileError,
  type FileRejection,
  useDropzone,
} from 'react-dropzone';
import { MdClose } from 'react-icons/md';

import { type FilerFileFields as FilerFile } from 'entities';
import { settings } from 'settings';
import { createLogger } from 'util/createLogger';

import {
  FileContent,
  FileDeleteAction,
  FileListContainer,
  FileName,
  FilePickerContainer,
  FileRejectReason,
  FileRowContainer,
} from './FilePicker.styles';

import type { useFilePicker } from '.';

const log = createLogger('FilePicker');

const MAX_FILE_SIZE = settings.MAX_FILE_SIZE;

/* spell-checker: disable */
const defaultFileTypeRestrictions = settings.FILE_TYPE_RESTRICTIONS;
/* spell-checker: enable */

const defaultFilePickerMessage = (
  <>
    Drag and drop files here or{' '}
    <span
      style={{
        color: 'var(--chakra-colors-brightBlue)',
        textDecoration: 'underline',
      }}
    >
      click to select files.
    </span>
  </>
);

/**
 * Contains a File, and the current state of that file in the context of
 * FilePicker
 */
export type FileListItem = {
  file: File;
  accepted: boolean;
  message: string | null;
};

export type FilePickerProps = {
  /** Disables the FilePicker - defaults to false */
  isDisabled?: boolean;
  /** A ReactNode containing the message to be rendered
   * in the middle of the FilePicker. Defaults to
   * 'Drag and drop files here or click to select files.' with
   * blue font for 'click to select files.'
   */
  message?: ReactNode;
  /**
   * Sets the number of files allowed to be processed in one batch.
   *
   * // FIXME doesn't actually impose limits on the length of files in fileList,
   * only the number of files to be processed _on each drop_.
   *
   * This maps to the react-dropzone behaviour, but we default to 1 and derive
   * `multiple`:
   *
   * > Note that this prop is enabled when the `multiple` prop is enabled. The
   * > default value for this prop is 0, which means there's no limitation to
   * > how many files are accepted.
   * @see
   * https://react-dropzone.js.org/#section-accepting-specific-number-of-files
   *
   * @default 1
   */
  maxFiles?: DropzoneOptions['maxFiles'];
  /** Sets the accepted file types for the component. Defaults to:
   * @example
   * 'image/*': ['.png', '.gif', '.jpg', '.jpeg'],
   * 'application/*': ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf'],
   * 'text/*': ['.txt'],
   * 'audio/*': ['.mp3', '.mp4', '.wav'],
   * 'video/*': ['.mpg', '.mov', '.wmv'],
   */
  fileTypeRestrictions?: Accept;
  /** Sets the maxFile for validating the selected file. Defaults to 20971520 bytes (20MB) */
  fileSizeRestriction?: number;
  /** Sets whether to render the FileList below the dropzone. Defaults to true. */
  showFileList?: boolean;

  fileList: FileListItem[];
  setFileList: ReturnType<typeof useFilePicker>['setFileList'];

  /**
   * If provided, this is called after all files _from a given drop event_ have
   * passed scanning. This receives scannedFiles, which are only the files which
   * passed _on this drop_.
   *
   * The primary use case for onFileAddSuccess is to immediately upload using
   * @see batchUploadFilerFileList, which requires the `fileFile.accepted`
   * metadata,
   *
   * ### GOTCHA!
   *
   * It's up to you to handle the fact that a user may drag one batch in then
   * another, and each time, there may be a mix of accepted and rejected files!
   * See the note then the example below.
   *
   * ```tsx
   * const { fileList, setFileList, batchUploadFilerFileList } = useFilePicker([]);
   *
   * <FilePicker
   *   fileList={fileList}
   *   setFileList={setFileList}
   *   onDropSuccess={async (scannedFiles) => {
   *     // scannedFiles may be a mix of accepted and rejected files
   *
   *     // Batch uploads only *accepted* files
   *     const newFiles = await batchUploadFilerFileList(fileList);
   *
   *     // Do something with newFiles, which have been uploaded to the server;
   *
   *     // Usually:
   *     // - update your record with references to the uploaded files,
   *     // - refresh the record,
   *     // - clear the file list: `setFileList([])`
   *     //   !!! when you clear state here, rejected files disappear,
   *     //   and the user won't know what succeeded or failed, and why
   *     //   unless you tell them!
   *   }}
   * />
   * ```
   */
  onDropSuccess?: (scannedFiles: FileListItem[]) => void | Promise<void>;
};

/**
 * FilePicker renders a 'drag and drop' zone file selector. The component acts
 * as a zone while also allowing users to select the message to open the OS File
 * Browser.
 *
 * The drop zone renders a single standard HTML file input field, which has a
 * property `files` When files are dropped on the drop zone, synchronous
 * validation occurs, including file size, file type, duplication check, then
 * async virus scanning.
 *
 * Each file is displayed under the widget with it's validation status: failing
 * FileListItems having `accepted: false` and a `message`.
 *
 * On each drop (or file selection), when all files _in that batch_ are
 * accepted, the optional `onDropSuccess` callback is called with those
 * files.
 *
 * ### Basic use case
 *
 * ```tsx
 * const { fileList, setFileList } = useFilePicker([]);
 *
 * // accumulate files in fileList; upload them at your leisure (fileList may contain rejected files)
 * <FilePicker fileList={fileList} setFileList={setFileList} />
 * ```
 *
 * To upload immediately on drop, @see onDropSuccess.
 *
 * ### Distinct file-related types referred to in the app
 *
 * - `File`: local files are of the primitive type File (see MDN link below)
 * - `FileListItem`: contains a File, and its acceptance state
 * - `FilerFile`: are references to files which have been uploaded to
 *   django-filer (they have an id, name, url, but no actual File)
 *
 * This component only deals with local Files; use FileItemRow to display
 * previously uploaded files.
 *
 * @see https://react-dropzone.js.org/
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 */
export const FilePicker = ({
  isDisabled = false,
  maxFiles = 1,
  message = defaultFilePickerMessage,
  fileTypeRestrictions = defaultFileTypeRestrictions,
  showFileList = true,
  fileSizeRestriction = MAX_FILE_SIZE,
  fileList,
  setFileList,
  onDropSuccess,
}: FilePickerProps) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      log('onDrop: %o', { acceptedFiles, fileRejections });
      if (fileRejections.length > 0) {
        fileRejections.forEach((file) => {
          // assuming there might be multiple errors,
          // only display the first error due to UI constraints
          const firstError = file.errors?.[0];
          const message =
            firstError.code === 'file-invalid-type'
              ? // suppress a very lengthy list of allowed filetypes
                'The file type you selected is not allowed.'
              : firstError.message;

          setFileList((currentFileList) => [
            ...(currentFileList ?? []),
            { accepted: false, file: file.file, message },
          ]);
        });
      }

      // acceptedFiles is `File[]`, but we need more context in
      // onDropSuccess, so construct FileListItems when each Promise settles
      if (acceptedFiles.length > 0) {
        // scan accepted files for viruses, file size match and file type match
        const promises = acceptedFiles.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);

          const newFile: FileListItem = {
            file,
            accepted: true,
            message: null,
          };
          setFileList((currentFileList) => [
            ...(currentFileList ?? []),
            newFile,
          ]);
          return Promise.resolve(newFile);
        });

        const scannedFiles = await Promise.all(promises);
        log('onDrop: %o', { scannedFiles });
        onDropSuccess?.(scannedFiles);
      }
    },
    [onDropSuccess, setFileList],
  );

  const customFileValidator = (file: File) => {
    if (file.size > fileSizeRestriction) {
      return {
        code: 'file-too-large',
        message: 'The file you selected is too large.',
      } satisfies FileError;
    }
    // fileList.length is the value before we've added the candidate
    if (fileList.length + 1 > maxFiles) {
      return {
        code: 'too-many-files',
        message: 'Too many files have been selected.',
      } satisfies FileError;
    }
    if (fileList.some((localFile) => localFile.file.name === file.name))
      return {
        code: 'file-already-exists',
        message: 'The file you selected already exists.',
      } satisfies FileError;

    return null;
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      disabled: isDisabled,
      // disabled: isDisabled || fileList.length >= maxFiles,
      // multiple is coupled to maxFiles; see doc-strings for maxFiles
      multiple: maxFiles === 0 || maxFiles > 1,
      maxFiles,
      accept: fileTypeRestrictions,
      validator: customFileValidator,
      onDrop,
    });
  const rootProps = getRootProps({ className: 'dropzone' });
  const inputProps = getInputProps();

  const handleLocalFileDelete = (index: number) => {
    const newArray = fileList.slice();
    newArray.splice(index, 1);
    setFileList(newArray);
  };

  // If EntityLibrary work requires the onChange for the hidden file input, we
  // will need to manually trigger the onChange as setting the file of files in Ref
  // doesn't trigger it.
  return (
    <>
      <FilePickerContainer
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
      >
        <chakra.div
          data-testid="dropzone-container"
          {...rootProps}
          data-disabled={isDisabled}
        >
          <input
            data-testid="dropzone-input"
            {...inputProps}
            disabled={isDisabled}
          />
          {isDisabled ? '' : message}
        </chakra.div>

        {showFileList && fileList.length > 0 && (
          <FileListContainer>
            {fileList.map((file, index) => (
              <FileRow
                key={index}
                onDelete={() => handleLocalFileDelete(index)}
              >
                {file}
              </FileRow>
            ))}
          </FileListContainer>
        )}
      </FilePickerContainer>
    </>
  );
};

const FileRow = ({
  children,
  onDelete,
}: {
  children: FileListItem;
  onDelete: () => void;
}) => {
  return (
    <FileRowContainer status={children.accepted}>
      <FileContent>
        <>
          <FileName>{children.file.name}</FileName>
          {!children.accepted && (
            <FileRejectReason>{children.message}</FileRejectReason>
          )}
        </>
      </FileContent>

      <FileDeleteAction>
        <MdClose onClick={onDelete} />
      </FileDeleteAction>
    </FileRowContainer>
  );
};

/**
 * Displays files previously uploaded to django-filer
 */
export const FileItemRow = ({
  name,
  url,
  onDelete,
  canDelete,
}: Pick<FilerFile, 'name' | 'url'> & {
  canDelete: boolean;
  onDelete?: () => void;
}) => {
  return (
    <FileRowContainer status>
      <FileContent>
        <a href={url} target="_blank" rel="noreferrer">
          <FileName sx={{ color: 'secondary' }}>{name}</FileName>
        </a>
      </FileContent>
      {canDelete && (
        <FileDeleteAction>
          <MdClose onClick={onDelete} />
        </FileDeleteAction>
      )}
    </FileRowContainer>
  );
};
