import { type Map, fromJS } from 'immutable';

import { Entity, EntityEnum, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import {
  type EntityEnumType,
  type EntityFields,
  type EntityRecord,
} from 'types';

// Find a way to i18n the label, not sure if it can be lazy loaded here using intl.FormatMessage
// Or whether component might use label as a key to intl.FormatMessage
export const ENGLISH = EntityEnum.dataToRecord({
  value: 'en',
  label: 'English',
});
export const SPANISH = EntityEnum.dataToRecord({
  value: 'es',
  label: 'Spanish',
});

class I18nTranslationEntity extends Entity {
  static fields: EntityFields<I18nTranslationRecordFields> = {
    uuid: new Fields.IdField({ blank: true }),
    language: new Fields.EnumField({
      options: fromJS([ENGLISH, SPANISH]),
      default: ENGLISH,
      toData: (record: Map<string, string>) => record?.get('value') || '',
    }),
    translated_message: new Fields.CharField(),
  };

  static toString(record: I18nTranslationEntityRecord) {
    return record?.get('translated_message') || '';
  }

  static dataToRecord(data?: Partial<I18nTranslationRecordFields>) {
    return super.dataToRecord(data) as I18nTranslationEntityRecord;
  }
}

type I18nTranslationRecordFields = {
  uuid: string;
  translated_message: string;
  // FIXME this might be an EntityEnumType or string! (eg control.timezones)
  language: EntityEnumType;
};

export type I18nTranslationEntityRecord =
  EntityRecord<I18nTranslationRecordFields>;

I18nTranslationEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: I18nTranslationEntity,
  name: 'I18nTranslation',
});

export default I18nTranslationEntity;
