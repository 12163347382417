import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity from './i18n/I18nText';
import ScenarioModifierEntity, {
  type ScenarioModifierEntityRecord,
} from './ScenarioModifier';
import { type TitleEntityFields } from './types';

class ScenarioModifierCategoryEntity extends Entity {
  static fields: EntityFields<ScenarioModifierCategoryEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    scenario_modifiers: new Fields.EntityField({
      entity: ScenarioModifierEntity,
      many: true,
    }),
  };
}

type ScenarioModifierCategoryEntityFields = TitleEntityFields & {
  scenario_modifiers: List<ScenarioModifierEntityRecord>;
};
export type ScenarioModifierCategoryEntityRecord =
  EntityRecord<ScenarioModifierCategoryEntityFields>;

ScenarioModifierCategoryEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ScenarioModifierCategoryEntity,
  name: 'ScenarioModifierCategory',
});

export default ScenarioModifierCategoryEntity;
