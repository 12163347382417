import { Accordion, Flex, Heading, UnorderedList } from '@chakra-ui/react';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router';

import { SimpleEntityList, VStack } from 'components';
import { DamageEnergyMechanism } from 'entities';

import { DamageEnergyCard } from './components';
import type { GlobalFrameworkContext } from '../GlobalFramework/GlobalFramework';

type ScenariosDisplayProps = {
  riskUUID: string;
};
export function ScenariosDisplay(props: ScenariosDisplayProps) {
  const { riskUUID } = props;
  return (
    <Flex layerStyle="cardLike" gap={8}>
      <VStack as="section">
        <Heading>
          <FormattedMessage
            id="global-framework.scenarios.title"
            defaultMessage="Scenarios"
          />
        </Heading>
      </VStack>

      <VStack as="section">
        <Accordion
          as={UnorderedList}
          defaultIndex={[0, 1, 2]}
          allowMultiple
          layerStyle="nakedList"
        >
          <SimpleEntityList
            Entity={DamageEnergyMechanism}
            RecordComponent={DamageEnergyCard}
            params={Map({ damage_energy__risk: riskUUID })}
          />
        </Accordion>
      </VStack>
    </Flex>
  );
}

export function Scenarios() {
  const { formControls } = useOutletContext<GlobalFrameworkContext>();
  const riskUUID = formControls.value.get('risk').get('uuid');

  return <ScenariosDisplay riskUUID={riskUUID} />;
}
