import { type ThemeComponents } from '@chakra-ui/react';

import { accordionTheme as Accordion } from './Accordion';
import { alertTheme as Alert } from './Alert';
import { avatarTheme as Avatar } from './Avatar';
import { badgeTheme as Badge } from './Badge';
import { buttonTheme as Button } from './Button';
import { customCardTheme as Card } from './Card';
import { checkboxTheme as Checkbox } from './Checkbox';
import { containerTheme as Container } from './Container';
import { dividerTheme as Divider } from './Divider';
import { drawerTheme as Drawer } from './Drawer';
import { formLabelTheme as FormLabel } from './FormLabel';
import { fullHeightTheme as FullHeight } from './FullHeight';
import { headingTheme as Heading } from './Heading';
import { inputTheme as Input } from './Input';
import { linkTheme as Link } from './Link';
import { menuTheme as Menu } from './Menu';
import { modalTheme as Modal } from './Modal';
import { popoverTheme as Popover } from './Popover';
import { selectTheme as Select } from './Select';
import { tabsTheme as Tabs } from './Tabs';
import { tagTheme as Tag } from './Tag';
import { textTheme as Text } from './Text';
import { textareaTheme as Textarea } from './Textarea';
import { tooltipTheme as Tooltip } from './Tooltip';

/**
 * These styles extend the default Chakra theme values, defined in:
 * ...
 *
 * In the Chakra docs, refer to the "Theming" tab, and the Source links.
 */
export const components: ThemeComponents = {
  Accordion,
  Alert,
  Avatar,
  Badge,
  // Box does not accept theme
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Drawer,
  FormLabel,
  FullHeight,
  Heading,
  Input,
  Link,
  Menu,
  Modal,
  Popover,
  Select,
  // Stack does not accept theme, consider layerStyle="stackDefault"
  Tabs,
  Tag,
  Text,
  Textarea,
  Tooltip,
};
