import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  // default opacity is 0.6; set to `1` and prefer 'colors.border' rather than inherit for more predictable theming
  opacity: 1,
  borderColor: 'border',
});

const sizes = defineStyle({
  sm: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  md: {
    marginBlockStart: 2,
    marginBlockEnd: 2,
  },
  lg: {
    marginBlockStart: 4,
    marginBlockEnd: 4,
  },
  xl: {
    marginBlockStart: 6,
    marginBlockEnd: 6,
  },
});

/**
 * Layout component used to visually separate content in a list or group.
 *
 * It displays a thin horizontal or vertical line, and renders a `hr` tag.
 *
 * - not that `colorScheme` is suggested, but does not apply
 * - `orientation` defaults to 'horizontal', can be 'vertical'.
 *
 * @see https://chakra-ui.com/docs/components/divider
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/divider.ts
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/layout/src/divider.tsx
 */
export const dividerTheme = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    // colorScheme: 'gray', // Not available on Divider
  },
});
