import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityRecord } from 'types.ts';

import { type BaseQuestionEntityFields } from './BaseQuestion.ts';
import { BaseQuestion } from './index.ts';

class CCFVQuestionEntity extends BaseQuestion {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/ccfv_question/',
  };

  static fields = BaseQuestion.fields;
}

type CCFVQuestionEntityFields = BaseQuestionEntityFields;

export type CCFVQuestionEntityRecord = EntityRecord<CCFVQuestionEntityFields>;

CCFVQuestionEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: CCFVQuestionEntity,
  name: 'CCFVQuestion',
});

export default CCFVQuestionEntity;
