import { List, type Map, fromJS } from 'immutable';

import I18nTextEntity from 'entities/api/i18n/I18nText';
import type { CommonControlEntityRecord } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation';
import { createLogger } from 'util/createLogger';

const log = createLogger('ControlGrid.util');

export const toEnString = (s: Map<string, unknown>) =>
  I18nTextEntity.toString(s, 'en');

/** an array corresponding to TimeZones */
type TimeZoneAppliesArray = [
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
];
export type ColSpan = [colStart: number, colEnd: number];
export type CellData = {
  control: CommonControlEntityRecord;
  colStart: ColSpan[0];
  colEnd: ColSpan[1];
};
export type EmptyCell = Pick<CellData, 'colStart' | 'colEnd'> & {
  control: null;
};

/**
 * Returns an array representing which timezones a control is active in, where
 * `undefined` indicates it's not active, and each integer represents the index
 * of a timezone in which the control is active.
 *
 * Eg:
 * - `[true, false, false, false, false, false, false]`
 * - `[true, true, false, true, false, false, false]`
 *
 * Relies on the length and order of TIME_ZONES being stable!
 */
export function parseControlTimeZones(
  control: CommonControlEntityRecord,
  timezoneKeys: string[],
) {
  const controlTimezones =
    control?.get('timezones')?.flatMap((el) => {
      const timezoneKey = toEnString(el?.get('title'));
      return el.get('is_active') ? [timezoneKey] : [];
    }) ?? List();

  return timezoneKeys.map((tz) =>
    controlTimezones.includes(tz) ? true : false,
  ) as TimeZoneAppliesArray;
}

const getTypeString = (control: CommonControlEntityRecord) =>
  toEnString(control?.get('control_designation')?.get('title'));

export const sortControlByDesignation = (
  _a: CommonControlEntityRecord,
  _b: CommonControlEntityRecord,
) => {
  const a = _a.get('control_designation')?.get('order');
  const b = _b.get('control_designation')?.get('order');
  return a == b ? 0 : a - b;
};

// type RowGroup = Map<string, List<List<CellData>>>;
/** Returns the total length of spans in a row */
export const countCombinedSpanLength = (row: List<CellData>) =>
  row.reduce((acc: number, span) => acc + span.colEnd - span.colStart, 0);

/** Sort by the combined length of all spans in the row, shortest to longest */
export const sortByCombinedSpanLength = (
  _a: List<CellData>,
  _b: List<CellData>,
) => {
  const a = countCombinedSpanLength(_a);
  const b = countCombinedSpanLength(_b);
  return a == b ? 0 : a - b;
};

export const prettyPrint = ({ control, colStart, colEnd }: CellData) => {
  return `${toEnString(control?.get('title'))} ${colStart}-${colEnd}`;
};

const _prettyPrintGroupedRows = (
  groupedRows: Map<string, List<List<CellData>>>,
) => {
  return groupedRows
    .toList()
    .flatten(true)
    .map((spans) => {
      return (spans as List<CellData>).map(
        ({ control, colStart, colEnd }: CellData) => {
          const title = toEnString(control?.get('title'));
          const type = toEnString(
            control?.get('control_designation').get('title'),
          );

          return `${type}: ${title} ${colStart}-${colEnd}`;
        },
      );
    })
    .toJS();
};

/** sorts first by the type, in the proscribed order, then by combined length of spans */
export const sortByTypeThenSize = (rows: List<List<CellData>>) => {
  const rowsByType = rows
    .groupBy((row) => getTypeString(row.first()!.control))
    .sort(
      // note: comparator compares two lists; the group keys are not in scope!
      // https://stackoverflow.com/questions/39721348/sort-and-group-an-immutable-js-list
      (groupA, groupB) =>
        sortControlByDesignation(
          groupA.first()!.first()!.control,
          groupB.first()!.first()!.control,
        ),
    )
    .map((list) => list.sort(sortByCombinedSpanLength).reverse());

  // console.log('🟠 rowsByType\n', prettyPrintGroupedRows(rowsByType));

  // TS can't infer the type here…
  return rowsByType.toList().flatten(true) as List<List<CellData>>;
};

/**
 * Returns a data structure with an array of rows, where each row consists of
 * one or more cells, and colStart & colEnd are defined.
 */
export function parseRows(
  controls: List<CommonControlEntityRecord>,
  timezoneKeys: string[],
) {
  performance.mark('parseRows-start');
  const rows = controls?.map((control) => {
    const timezoneMap = parseControlTimeZones(control, timezoneKeys);
    const spans = calculateSpans(timezoneMap);
    const spansWithControls: List<CellData> = List(
      spans.map(
        ([colStart, colEnd]) =>
          ({ control, colStart, colEnd }) satisfies CellData,
      ),
    );
    return spansWithControls;
  });

  const spans = condenseSpans(rows, timezoneKeys);
  performance.mark('parseRows-end');
  const parseRowsMeasure = performance.measure(
    'parseRows-duration',
    'parseRows-start',
    'parseRows-end',
  );
  log('parseRows duration %o', parseRowsMeasure);
  return spans;
}

/** Note that we derive timezoneKeys from server data */
export const createTransformSpanToBools =
  (timezoneKeys: string[] = []) =>
  ({ colStart, colEnd }: Pick<CellData, 'colStart' | 'colEnd'>) =>
    // note the i is zero-based, spans are one-based
    timezoneKeys.map(
      (_, i) => i + 1 >= colStart && i + 1 < colEnd,
    ) as TimeZoneAppliesArray;

/** Rule: split spans never share a row */
export function isFitsInRow(
  _spans: List<CellData>,
  _destRow: List<CellData>,
  transformSpanToBools: ReturnType<typeof createTransformSpanToBools>,
) {
  const spans = _spans.map(transformSpanToBools).toJS();
  const destRow = _destRow.map(transformSpanToBools).toJS();

  const isSplitSpan = spans.length > 1;
  const isDestRowContainsSplit = !_destRow
    .groupBy((span) => span?.control?.get('uuid'))
    .every((group) => group.size < 2);

  if (isSplitSpan || isDestRowContainsSplit) return false;

  const result = spans[0].every((isRequested, reqIndex) =>
    isRequested ? destRow.every((targetSpan) => !targetSpan[reqIndex]) : true,
  );

  // const pretty = (obj: unknown) =>
  //   JSON.stringify(obj).replace(/false/g, ' ').replace(/true/g, '◻️');
  // console.log('isFitsInRow: %o', result, {
  //   spans: pretty(spans),
  //   destRow: pretty(destRow),
  // });
  return result;
}

export const sortByColStart = (
  { colStart: a }: Pick<CellData, 'colStart'>,
  { colStart: b }: Pick<CellData, 'colStart'>,
) => (a == b ? 0 : a - b);

const createEmptyCell = (colStart: number, colEnd: number): EmptyCell => ({
  colStart,
  colEnd,
  control: null,
});

/** insert EmptyCells into each empty column */
export function fillEmptyCells(
  targetRows: List<List<CellData>>,
  transformSpanToBools: ReturnType<typeof createTransformSpanToBools>,
) {
  return targetRows.map((row) => {
    let filledRow: List<CellData | EmptyCell> = row;

    const emptyCells = row
      .map(transformSpanToBools)
      .reduce(
        (acc: boolean[], spanBools) =>
          acc.map((accBool, i) => accBool || spanBools[i]),
        [...Array(6).fill(false)],
      )
      .map((bool) => !bool);

    emptyCells.forEach((isEmpty, i) => {
      if (isEmpty)
        filledRow = filledRow.insert(i, createEmptyCell(i + 1, i + 2));
    });

    return filledRow.sort(sortByColStart);
  });
}

/**
 * Returns an array of cells such that:
 *
 * - if a control spans non-contiguous regions:
 *   - display them all in a single row
 *   - don't intersperse other cells between
 * - squash as many cells as possible up
 * - render interrupts first, then other types in order
 */
export function condenseSpans(
  rows: List<List<CellData>>,
  timezoneKeys: string[],
) {
  const transformSpanToBools = createTransformSpanToBools(timezoneKeys);

  // keep in rows so we can show preview without splitting cells within a row
  let targetRows: List<List<CellData>> = fromJS([[]]);

  // at this point, each sortedRow contains a single Control, which constitute several spans
  const sortedRows = sortByTypeThenSize(rows);
  // console.group('condenseSpans', { sortedRows });

  sortedRows.forEach((row, _i) => {
    let wasPlaced = false;
    let targetRowIndex = null;
    // console.group('🔵 %o', _i);
    // row.forEach((cell) => console.log(prettyPrint(cell), { cell }));

    // find the first available space
    targetRows.forEach((targetRow, j) => {
      if (!wasPlaced && isFitsInRow(row, targetRow, transformSpanToBools)) {
        targetRowIndex = j;
        wasPlaced = true;
      }
    });

    if (targetRowIndex != null) {
      // console.log('✅ fits', { targetRows, row });
      targetRows = targetRows.updateIn([targetRowIndex], (list) =>
        (list as List<CellData>)
          .push(...row)
          // cells must be inserted ordered by colStart
          .sort(sortByColStart),
      );
    }

    // if last row, add another row
    if (!wasPlaced) targetRows = targetRows.push(List([...row]));
    // console.groupEnd();
  });
  // console.groupEnd();

  return fillEmptyCells(targetRows, transformSpanToBools);
}

/**
 * Returns an array of contiguous cells, where each cell is a tuple of
 * coordinates representing `[colStart, colEnd]`.
 *
 * Note that in here we are converting zero-based indices to one-based
 * colStart,colEnd
 */
export function calculateSpans(timezoneMap: TimeZoneAppliesArray) {
  // no timezones are active
  if (timezoneMap.every((el) => !el)) return [];

  // all timezones are active, resulting in a single span
  if (timezoneMap.every((el) => el)) return [[1, 7]];

  /*
  Anything below is like:
  - [_,=,=,_,_,_]
  - [_,=,_,=,_,_]
  - [_,=,_,=,=,_]
  (_ false; = true)
  */

  const cells: ColSpan[] = [];

  // console.log(timezoneMap);
  let currentSpanStart: number | null = null;

  timezoneMap.forEach((isActive, i) => {
    // console.log({ i, isActive, currentSpanStart });

    if (isActive) {
      if (currentSpanStart == null) {
        // start of a new span
        currentSpanStart = i + 1;
      }
    } else {
      if (currentSpanStart != null) {
        // end of the current span
        const span = [currentSpanStart, i + 1] satisfies ColSpan;
        // console.log({ span });
        cells.push(span);
        currentSpanStart = null;
      }
    }
  });

  if (currentSpanStart) {
    // if there's a span still open when we've checked all timezones, close and add it
    const span = [currentSpanStart, 7] satisfies ColSpan;
    // console.log({ span });
    cells.push(span);
  }

  return cells;
}

/** narrows type to CellData */
export const hasControl = (cell: CellData | EmptyCell): cell is CellData =>
  !!cell.control;
