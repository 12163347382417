import { Select } from '@chakra-ui/react';
import { type Moment } from 'moment';
import { useIntl } from 'react-intl';

import { type PeriodOption } from 'entities/filter/periodOptions';
import { type EntityDuckAction, type FormChangeEvent } from 'types';

import { usePeriodSelectControls } from './PeriodSelect.hooks';

type PeriodSelectProps = {
  name: string;
  onChange: (event: FormChangeEvent) => EntityDuckAction;
  options: PeriodOption[];
  value: Moment;
};

export const PeriodSelect = ({
  name,
  onChange,
  options,
  value,
}: PeriodSelectProps) => {
  const { formatMessage } = useIntl();
  const { selectOnChange } = usePeriodSelectControls(name, onChange);

  return (
    <Select value={value.toISOString()} onChange={selectOnChange}>
      {options &&
        options.map(({ value, label }, idx) => (
          <option key={idx} value={value.toISOString()}>
            {formatMessage(label)}
          </option>
        ))}
    </Select>
  );
};
