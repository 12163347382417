import type { ReactElement } from 'react';
import { useOutletContext } from 'react-router';

import { createLogger } from 'util/createLogger';

import type { GlobalControlDetailContextType } from '../GlobalFrameworkControlDetail';

const log = createLogger('GlobalFrameworkControlTabContext');

type GlobalFrameworkTabContextProps = {
  Component: (props: GlobalControlDetailContextType) => ReactElement;
};

/** Provides common context to routed tabs */
export function GlobalFrameworkControlTabContext({
  Component,
}: GlobalFrameworkTabContextProps) {
  const { controlFormControls } =
    useOutletContext<GlobalControlDetailContextType>();

  log('%o', controlFormControls);

  return <Component controlFormControls={controlFormControls} />;
}
