import {
  Tooltip as ChakraTooltip,
  type SystemStyleObject,
  type TooltipProps,
} from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';

/**
 * Renders Tooltip with our preferred defaults
 *
 * @default hasArrow true
 *
 * ## If the element is not clickable, render with `cursor: help`
 *
 * ```ts
 * export const someSx: SystemStyleObject = {
 *   _hover: { cursor: 'help' },
 * };
 * ```
 *
 * ## Prevent tooltip overflowing, causing horizontal scrolling
 *
 * ```tsx
 * <Tooltip
 *   label="Some label"
 *   modifiers={[
 *     // prevent tooltip overflowing, causing horizontal scrolling
 *     { name: 'preventOverflow', options: { boundary: document.body } },
 *   ]}
 * >
 *   Content
 * </Tooltip>
 * ```
 *
 * @see https://popper.js.org/docs/v2/modifiers/prevent-overflow/
 */
export const Tooltip = (props: TooltipProps) => (
  <ChakraTooltip hasArrow {...props} />
);

type TooltipTemplateProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
};

export const titleSx: SystemStyleObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};

export const descriptionSx: SystemStyleObject = {
  fontSize: 'xs',
};

/**
 * TooltipTemplate Component
 *
 * Renders the content of the tooltip with a title and optional description.
 */
export const TooltipTitleDescriptionLayout = ({
  title,
  description,
}: TooltipTemplateProps) => (
  <Flex direction="column">
    <Text role="term" sx={titleSx}>
      {title}
    </Text>
    {description && (
      <Text role="definition" sx={descriptionSx}>
        {description}
      </Text>
    )}
  </Flex>
);
