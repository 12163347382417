import React from 'react';
import ReactDOM from 'react-dom/client';

import { settings } from 'settings';
import setup from 'setup.ts';

import { App } from './App.tsx';

setup(settings);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
