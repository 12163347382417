import type { List, Map } from 'immutable';
import { useSelector } from 'react-redux';

import type { Entity as EntityType } from '@burnsred/entity';
import type { BasicEntityRecord, EntityRecord } from 'types';

// note: don't use results from here: in practice it's impractical to pass the proper generic in
type PaginationEntityFields<T = BasicEntityRecord> = {
  count: number;
  next: string;
  num_pages: number;
  previous: string;
  results: List<T>;
};

export type PaginationEntityRecord<T = BasicEntityRecord> = EntityRecord<
  PaginationEntityFields<T>
>;

export function usePagination(
  Entity: typeof EntityType,
  params: Map<string, string>,
) {
  return useSelector<unknown, PaginationEntityRecord>((state) =>
    Entity.duck.selectors.pagination(state, { params }),
  );
}
