import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import ControlDesignationEntity from './ControlDesignation';
import ControlDomainEntity from './ControlDomain';
import ExistingControlEntity from './ExistingControl';
import HierarchyOfControlEntity from './HierarchyOfControl';
import I18nTextEntity, { toString } from './i18n/I18nText';
import C3QuestionEntity, {
  type C3QuestionEntityRecord,
} from './Question/C3Question.ts';
import CCFVQuestionEntity, {
  type CCFVQuestionEntityRecord,
} from './Question/CCFVQuestion.ts';
import SupportFactorEntity, {
  type SupportFactorEntityRecord,
} from './SupportFactor.ts';
import TimeZoneEntity from './TimeZone';
import { type BaseControlEntityFields, type TitleEntityFields } from './types';

class ControlEntity extends Entity {
  static paths = {
    apiBase: '/cube/v1/control/',
  };

  static paginated = true;

  static fields: EntityFields<ControlEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    c3_questions: new Fields.EntityField({
      entity: C3QuestionEntity,
      many: true,
    }),

    ccfv_questions: new Fields.EntityField({
      entity: CCFVQuestionEntity,
      many: true,
    }),

    control_designation: new Fields.EntityField({
      entity: ControlDesignationEntity,
      blank: true,
    }),

    existing_controls: new Fields.EntityField({
      entity: ExistingControlEntity,
      many: true,
    }),

    timezones: new Fields.EntityField({
      entity: TimeZoneEntity,
      many: true,
    }),

    control_domain: new Fields.EntityField({
      entity: ControlDomainEntity,
    }),

    hierarchy_of_control: new Fields.EntityField({
      entity: HierarchyOfControlEntity,
    }),

    is_critical: new Fields.BooleanField(),

    fel_link_number: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    justification: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    features: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    purpose: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    in_scope: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    out_of_scope: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    design_standard: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    operating_standard: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    erosion: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    cause: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    support_factors: new Fields.EntityField({
      entity: SupportFactorEntity,
      many: true,
    }),
    applicable_rules: new Fields.CharField({
      many: true,
    }),
  };

  static toString = toString<ControlEntityRecord>;
  // TODO some other one to one fields not yet on serializer
}

type ControlEntityFields = TitleEntityFields &
  BaseControlEntityFields & {
    c3_questions: List<C3QuestionEntityRecord>;
    ccfv_questions: List<CCFVQuestionEntityRecord>;
    applicable_rules: List<string>;
    support_factors: List<SupportFactorEntityRecord>;
  };

export type ControlEntityRecord = EntityRecord<ControlEntityFields>;

ControlEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ControlEntity,
  name: 'Control',
});

export default ControlEntity;
