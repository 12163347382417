import type { SystemStyleObject } from '@chakra-ui/react';

export const legendHstackSx: SystemStyleObject = {
  gap: '5',
  flexDirection: 'row',
  marginLeft: 'auto',
  fontSize: 'sm',
};

export const checkboxSx: SystemStyleObject = {
  '.chakra-checkbox__label': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};

const baseIconSx: SystemStyleObject = {
  w: '24px',
  h: '12px',
  borderRadius: '2px',
  border: '2px solid',
  marginRight: 2,
};

export const iconSx: Record<string, SystemStyleObject> = {
  critical: {
    ...baseIconSx,
    borderColor: 'red.500',
    bg: 'blackAlpha.50',
  },
  nonCritical: {
    ...baseIconSx,
    borderColor: 'gray.400',
    bg: 'gray.200',
  },
};
