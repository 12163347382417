import {
  HStack,
  Icon,
  List,
  ListItem,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { icons } from 'assets/icons';
import { Tooltip } from 'components/atoms';
import ControlDesignationEntity, {
  type ControlDesignationEntityRecord,
} from 'entities/api/ControlDesignation';
import { toString as i18nToString } from 'entities/api/i18n/I18nText';
import { useLocale } from 'locales/useLocale';

import { iconSx, listItemSx } from './ControlGridLegend.styles';

export const ControlGridLegend = () => {
  const { toString } = useLocale();

  const controlTypes = useSelector<
    unknown,
    Immutable.List<ControlDesignationEntityRecord>
  >((state) => ControlDesignationEntity.duck.selectors.record(state));

  // Ensure that `controlTypes` is defined
  if (!controlTypes?.size) {
    return (
      <Text>
        <FormattedMessage
          id="ControlGridLegend.noControlTypes"
          defaultMessage="No control types available"
        />
      </Text>
    );
  }

  /**
   * In the context of GlobalFrameworks, the ControlDesignations 'Support..' and
   * 'Other' should be hidden.
   */
  const filterOutLocalDesignations = (rec: ControlDesignationEntityRecord) => {
    const enTitle = i18nToString(rec, 'en', 'title').toLowerCase();
    return !(enTitle.startsWith('support') || enTitle.startsWith('other'));
  };

  return (
    <div>
      <VisuallyHidden id="legend-description">
        <FormattedMessage
          id="ControlGridLegend.label"
          defaultMessage="Control type legend"
        />
      </VisuallyHidden>

      <HStack as={List} gap={6} aria-labelledby="legend-description">
        {controlTypes
          .filter(filterOutLocalDesignations)
          .map((controlType, i) => (
            <Tooltip key={i} label={toString(controlType, 'description')}>
              <ListItem layerStyle="nakedList" sx={listItemSx}>
                <Icon
                  as={icons.MdOutlinePanoramaWideAngleSelect}
                  sx={{
                    ...iconSx,
                    fill: `controlType_${controlType.get('order')}`,
                  }}
                />
                <Text>{toString(controlType)}</Text>

                {toString(controlType, 'description') && (
                  <VisuallyHidden>
                    {toString(controlType, 'description')}
                  </VisuallyHidden>
                )}
              </ListItem>
            </Tooltip>
          ))}
      </HStack>
    </div>
  );
};
