import { useBoolean } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import type C3QuestionEntity from 'entities/api/Question/C3Question';
import type CCFVQuestionEntity from 'entities/api/Question/CCFVQuestion';
import type { LocaleKey } from 'locales';
import type { GlobalControlDetailContextType } from 'screens/global-frameworks/ControlTab/GlobalFrameworkControlDetail';

export type UseControlQuestionsProps = {
  locale: LocaleKey;
  fieldName: 'c3_questions' | 'ccfv_questions';
  controlFormControls: GlobalControlDetailContextType['controlFormControls'];
  QuestionEntity: typeof C3QuestionEntity | typeof CCFVQuestionEntity;
};

export const useControlQuestions = ({
  fieldName,
  controlFormControls,
}: UseControlQuestionsProps) => {
  const questions = controlFormControls?.value?.get(fieldName);

  const [currentTab, setCurrentTab] = useState(0);

  const currentInstruction = questions?.get(currentTab)?.get('instruction');

  const [showInstructions, setShowInstructions] = useBoolean(false);

  useEffect(() => {
    if (showInstructions) setShowInstructions.off();
    // don't update on showInstructions change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, setShowInstructions]);

  return {
    currentTab,
    setCurrentTab,
    showInstructions,
    setShowInstructions,
    questions,
    currentInstruction,
    controlFormControls,
  };
};
