import { Entity, Fields } from '@burnsred/entity';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity, { type I18nTextEntityRecord } from './i18n/I18nText';

class RulePredicateEntity extends Entity {
  static fields: EntityFields<RulePredicateEntityFields> = {
    uuid: new Fields.IdField(),
    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    object_id: new Fields.CharField(),
    model_name: new Fields.CharField(),
  };
}

type RulePredicateEntityFields = {
  uuid: string;
  title: I18nTextEntityRecord;
  object_id: string;
  model_name: string;
};

export type RulePredicateEntityRecord = EntityRecord<RulePredicateEntityFields>;

export default RulePredicateEntity;
