import { defineStyleConfig } from '@chakra-ui/styled-system';

export const badgeTheme = defineStyleConfig({
  baseStyle: {},
  defaultProps: {},

  variants: {
    notificationBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'error',
      color: 'white',
    },
  },

  sizes: {
    xs: {
      minWidth: '4',
      padding: '0.5',
      borderRadius: '4px',
    },
  },
});
