import { isAxiosError } from 'axios';
import React, { useCallback } from 'react';

import { axiosInstance } from 'api';
import { type FilerFileFields as FilerFile } from 'entities';
import { settings } from 'settings';

import { type FileListItem } from './FilePicker';

export type UseFilePickerReturn = ReturnType<typeof useFilePicker>;

export const useFilePicker = (initialFileList: FileListItem[] = []) => {
  const [fileList, setFileList] =
    React.useState<FileListItem[]>(initialFileList);

  const setFileUploadError = useCallback(
    (fileObj: FileListItem, message: string) => {
      setFileList((prev) =>
        prev.map((file) =>
          file === fileObj
            ? { ...file, accepted: false, message: 'Upload failed: ' + message }
            : file,
        ),
      );
    },
    [setFileList],
  );

  const removeFileFromList = useCallback(
    (fileObj: FileListItem) => {
      setFileList((prev) => prev.filter((file) => file !== fileObj));
    },
    [setFileList],
  );

  /** Batch upload only *accepted* files */
  const batchUploadFilerFileList = async (fileList: FileListItem[]) => {
    const newFiles: FilerFile[] = [];
    for (const fileObj of fileList) {
      if (fileObj.accepted) {
        const formData = new FormData();
        formData.append('name', fileObj.file.name);
        formData.append('file', fileObj.file);
        const uploadRes = await uploadFile<FilerFile>({
          fileObj,
          formData,
          baseUrl: settings.FILE_UPLOAD_URL,
          url: '/',
        });
        if (uploadRes !== null) {
          newFiles.push(uploadRes);
        }
      }
    }
    return newFiles;
  };

  const uploadFile = useCallback(
    async <T>({
      fileObj,
      formData,
      url,
      baseUrl,
    }: {
      fileObj: FileListItem;
      formData: FormData;
      url: string;
      baseUrl?: string;
    }) => {
      try {
        const res = await axiosInstance.post<T>(url, formData, {
          baseURL: baseUrl,
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        return res.data;
      } catch (err) {
        if (isAxiosError(err)) {
          const responseErr: Record<string, string[]> =
            err.response?.data || {};
          let errMsg = 'Unknown error';
          if (responseErr.file) {
            errMsg = responseErr.file.join('\n');
          } else {
            formData.forEach((_value, key) => {
              errMsg = responseErr[key]?.join('\n') || errMsg;
            });
          }
          setFileUploadError(fileObj, errMsg);
        }
        return null;
      }
    },
    [setFileUploadError],
  );

  return {
    fileList,
    setFileList,
    removeFileFromList,
    setFileUploadError,
    uploadFile,
    batchUploadFilerFileList,
  };
};
