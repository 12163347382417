import {
  Box,
  Card,
  Flex,
  Grid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { type SiteControlEntityRecord } from 'entities/api/SiteControl';
import { createLogger } from 'util/createLogger';

import { ControlCard, type ControlCardProps } from './ControlCard';
import {
  headingSx,
  labelSx,
  miniStatCard,
  statsFlexSx,
} from './ControlCardWithStats.styles';

const log = createLogger('ControlCardWithStats');

export type ControlCardWithStatsProps = Exclude<ControlCardProps, 'record'> & {
  record: SiteControlEntityRecord;
};

export const ControlCardWithStats = (props: ControlCardWithStatsProps) => {
  const { record, ...controlCardProps } = props;
  log('props %o', props);

  const countCCC = record?.get('count_ccc') ?? 0;
  const countCC = record?.get('count_cc') ?? 0;
  const countInterruptions = record?.get('count_interruptions') ?? 0;
  const countFixes = record?.get('count_fix') ?? 0;
  const countActions = record?.get('cc_with_actions') ?? 0;

  return (
    <ControlCard
      {...controlCardProps}
      record={record}
      stats={
        <Box>
          <Flex sx={statsFlexSx} justifyContent="space-between">
            {/* CCC Stats */}
            <Card as={Stat} variant="unstyled" flex="6" sx={miniStatCard}>
              <StatLabel sx={headingSx}>
                <FormattedMessage
                  id="ControlCardWithStats.ccc"
                  defaultMessage="CCC"
                />
              </StatLabel>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <StatNumber>
                  <FormattedNumber value={countCCC} />
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.ccc.total"
                      defaultMessage="Total"
                    />
                  </StatHelpText>
                </StatNumber>
                <StatNumber>
                  <FormattedNumber value={countInterruptions} />
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.ccc.interrupts"
                      defaultMessage="Interrupts"
                    />
                  </StatHelpText>
                </StatNumber>
                <StatNumber>
                  <FormattedNumber value={countFixes} />
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.ccc.fix"
                      defaultMessage="Fix"
                    />
                  </StatHelpText>
                </StatNumber>
              </Grid>
            </Card>

            {/* FCC Stats */}
            <Card as={Stat} variant="unstyled" flex="4" sx={miniStatCard}>
              <StatLabel sx={headingSx}>
                <FormattedMessage
                  id="ControlCardWithStats.fcc"
                  defaultMessage="FCC"
                />
              </StatLabel>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <StatNumber>
                  <FormattedNumber value={countCC} />
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.fcc.total"
                      defaultMessage="Total"
                    />
                  </StatHelpText>
                </StatNumber>
                <StatNumber>
                  <FormattedNumber value={countActions} />
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.fcc.actions"
                      defaultMessage="Actions"
                    />
                  </StatHelpText>
                </StatNumber>
              </Grid>
            </Card>
          </Flex>
        </Box>
      }
    />
  );
};
