import type { SystemStyleObject } from '@chakra-ui/react';

export const listSx: SystemStyleObject = {
  paddingTop: '10',
  paddingLeft: '0',
  fontSize: 'sm',
};

export const linkSx: SystemStyleObject = {
  '--link-color': 'colors.gray.500',
  '--active-color': 'colors.primary',
  '--disabled-color': 'colors.gray.400',

  display: 'block',
  width: 'full',
  paddingY: '2.5',
  color: 'var(--link-color)',

  paddingLeft: '3',
  borderLeft: '4px',
  borderColor: 'transparent',

  _hover: {
    borderColor: 'var(--link-color)',
  },

  '&.active': {
    color: 'var(--active-color)',
    borderColor: 'var(--active-color)',
  },

  '&.disabled': {
    // disable clicking
    pointerEvents: 'none',
    color: 'var(--disabled-color)',
    borderColor: 'transparent',
    _hover: { borderColor: 'transparent' },
  },
};
