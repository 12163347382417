import { Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import MPTTAncestorEntity, {
  type MPTTAncestorEntityFields,
} from '../MPTTAncestor';

class GrcOrganisationalUnitAncestorEntity extends MPTTAncestorEntity {
  static fields: EntityFields<GrcOrganisationalUnitAncestorEntityFields> = {
    ...MPTTAncestorEntity.fields,

    name: new Fields.CharField(),
    is_active: new Fields.BooleanField(),
    is_location: new Fields.BooleanField(),
  };

  static toString(record: GrcOrganisationalUnitAncestorEntityRecord): string {
    return record.get('name');
  }
}

type GrcOrganisationalUnitAncestorEntityFields = MPTTAncestorEntityFields & {
  name: string;
  is_active: boolean;
  is_location: boolean;
};

export type GrcOrganisationalUnitAncestorEntityRecord =
  EntityRecord<GrcOrganisationalUnitAncestorEntityFields>;

GrcOrganisationalUnitAncestorEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: GrcOrganisationalUnitAncestorEntity,
  name: 'GrcOrganisationalUnit',
});

export default GrcOrganisationalUnitAncestorEntity;
