import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityRecord } from 'types.ts';

import BaseQuestion, { type BaseQuestionEntityFields } from './BaseQuestion.ts';

class C3QuestionEntity extends BaseQuestion {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/c3_question/',
  };

  static fields = BaseQuestion.fields;
}

type C3QuestionEntityFields = BaseQuestionEntityFields;

export type C3QuestionEntityRecord = EntityRecord<C3QuestionEntityFields>;

C3QuestionEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: C3QuestionEntity,
  name: 'C3Question',
});

export default C3QuestionEntity;
